import { storeType } from '../easy-store';
import { generateUUID } from './generate-uuid';

export const getDeviceId = () => {
  let deviceId = easyStore.easyGet('ZM-CID');
  if (!deviceId) {
    deviceId = generateUUID();
    easyStore.easySet('ZM-CID', deviceId, storeType.localStorage);
  }
  return deviceId;
};
