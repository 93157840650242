import { laplace } from '../laplace';
import { canAutoLog, isLaplaceEnabled } from '../is-laplace-enabled';

export const directReport = (object, option = {}) => {
  const {
    sampleRate = 1,
    logLevel = 'log',
    useRawData = false,
    tags = [],
    isManualReport = false,
    isAVSLog = false,
  } = option;
  if ((isAVSLog && !canAutoLog() && !isManualReport) || !isLaplaceEnabled()) {
    return;
  }
  if (CLIENT_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn('[directReport]: ', object);
  }
  laplace.directReport(object, {
    tags,
    logLevel,
    sampleRate,
    useRawData,
  });
};
