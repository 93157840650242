import { LoadJs } from '../global/task-utils/load-js';
import meetingConfig from 'meetingConfig';
import { errorLog } from '../../global/web-client-logger';

function loadWebIM() {
  return LoadJs.start(meetingConfig)
    .then((v) => {
      return v.add('js/lib/webim.min.js');
    })
    .catch((e) => {
      errorLog(e);
      throw e;
    });
}

export const taskLoadWebIM = [loadWebIM];
