import { LoadJs } from '../global/task-utils/load-js';
import meetingConfig from 'meetingConfig';
import { errorLog } from '../../global/web-client-logger';
import { getWhiteBoardBaseUrl } from '../global/task-utils/common-utils';

function loadWhiteboard() {
  return LoadJs.start(meetingConfig)
    .then((v) => {
      const collection = [];
      const whiteBoardBaseUrl = getWhiteBoardBaseUrl();
      collection.push(v.add(whiteBoardBaseUrl, 'js/whiteboard-sdk.js'));
      collection.push(v.add(whiteBoardBaseUrl, 'css/whiteboard-sdk.css'));
      return Promise.all(collection);
    })
    .catch(errorLog);
}

export const taskLoadWhiteboard = [loadWhiteboard];
