import { directReport } from './direct-report';

export const getLaplaceReportOnceFunc = function () {
  let executed = false;
  return function (msg, options) {
    if (!executed) {
      executed = true;
      directReport(msg, options);
    }
  };
};
