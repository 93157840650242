import { preloadWebClient } from '../../preloadWebClient';
import { loadWebclient } from '../../loadWebclient';
import { initLogManager } from '../../initLogManager';
import { handleRouter } from '../../handleRouter';
import { initDeviceManager } from './initDeviceManager';

export const taskBeforeLoadWebClient = [
  initDeviceManager,
  handleRouter,
  preloadWebClient,
  initLogManager,
  loadWebclient,
];
