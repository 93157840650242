function feedbackJob({ ctx }) {
  import(
    /* webpackMode: "lazy" */
    /* webpackChunkName: "main-client" */
    '../../features/feedback/run-feedback'
  ).then(({ runFeedback }) => {
    runFeedback({ ctx });
  });
}

export const taskToLeaveWebClient = [feedbackJob];
