import { Laplace } from '@zoom/laplace';
import AutoCleanLogPlugin from '@zoom/laplace/build/plugins/auto-clean-log-plugin';
import AutoLogWebVitalsPlugin from '@zoom/laplace/build/plugins/auto-log-web-vitals-plugin';
import AutoLogErrorPlugin from '@zoom/laplace/build/plugins/auto-log-error-plugin';
import AutoLogStaticResourcePlugin from '@zoom/laplace/build/plugins/auto-log-static-resource-plugin';
import { escapeSensitiveContent } from './escapeSensitiveContent';
import meetingConfig from 'meetingConfig';
import { LOCALSTORAGE_KEYS } from '../constant';
import { decodeBase64, encodeBase64 } from '../util';
import { getMediasdkPackageVersion } from '../../tasks/global/task-utils/common-utils';
import { generateUUID } from '../service/generate-uuid';
import { isWebinar } from '../service/meeting-types';

export const trackingId = generateUUID();
export const getTrackingId = () => trackingId;
export const region = `region_${meetingConfig.userRegion}`;
const MAXIMUM_MAX_SIZE = 1024 * 1024 * 47;
const DATABASE_NAME = 'laplace-web-client-database';

const defaultResourceLogConfig = {
  monitorRequest: true,
  includeResourceList: [],
  excludeResourceTimingKeys: [],
};

let logConfigDefault = {
  enable: false,
  gatewayEndPoint: '',
  logLevel: {
    debug: false,
    log: false,
    info: false,
    print: false,
    warn: false,
    error: false,
  },
  performanceReportRatio: 0.01,
  errorReportRatio: 0.01,
  trackingCount: 7,
  resourceLogConfig: defaultResourceLogConfig,
};

try {
  if (meetingConfig?.logConfig) {
    logConfigDefault = JSON.parse(meetingConfig?.logConfig);
  }
} catch (error) {
  // eslint-disable-next-line no-console
  console.error('parse log config error');
}

export const logConfig = logConfigDefault;
export const shouldReportPerformance =
  Math.random() < (logConfig?.performanceReportRatio ?? 0);

const { includeResourceList, excludeResourceTimingKeys, monitorRequest } =
  logConfig?.resourceLogConfig ?? defaultResourceLogConfig;

const ignoredErrorMsgKeywords = [
  'table index is out of bounds',
  'ResizeObserver loop completed with undelivered notifications',
];

const makeReportUrl = () => {
  const { gatewayEndPoint } = logConfig;
  const LOG_GATEWAY_API_ROUTE = '/pwa/webclient';
  if (gatewayEndPoint) {
    return gatewayEndPoint + LOG_GATEWAY_API_ROUTE;
  }
  return '';
};

export const reportUrl = makeReportUrl();

const weekTimestamp = 7 * 24 * 60 * 60 * 1000;

const expireBefore = new Date().getTime() - weekTimestamp;
const noop = async () => {};
export let laplace = {
  log: noop,
  deleteLog: noop,
  directReport: noop,
  reportByFilter: noop,
};

// const publicKey = meetingConfig?.logEncryptionPubKey;

export const getCodeVersion = () => {
  const webClientVersionMatches = meetingConfig.defaultAvatar.match(
    /web_client\/([\w]+)\//,
  );
  const mediaSDKVersion = getMediasdkPackageVersion();

  return `${webClientVersionMatches?.[1] ?? 'unknown'}/${mediaSDKVersion}`;
};

const plugins = [
  new AutoCleanLogPlugin({
    debug: false,
    cleanAfterReport: true,
    expireBefore: expireBefore,
  }),
  new AutoLogWebVitalsPlugin({
    enable: shouldReportPerformance,
    publicTags: ['PERFORMANCE'],
  }),
  new AutoLogErrorPlugin({
    ignoredErrorMsgKeywords,
    maxErrorCount: 223,
    onError: (event) => {
      if (event?.target && (event?.target?.src || event?.target?.href)) {
        const message = `Load Resource Error in ${
          event?.target?.tagName
        } element: ${event?.target?.src || event?.target?.href}`;
        return { message, tags: ['RESOURCE_ERROR'], logLevel: 'error' };
      } else if (event?.type === 'unhandledrejection') {
        let message;
        const reason = event?.reason;
        if (typeof reason === 'string') {
          message = `Promise Error: ${reason}`;
        } else if (reason instanceof Error) {
          message = `Promise Error: ${reason.message} ${reason.name} ${reason.stack}`;
        } else if (typeof reason === 'object') {
          message = `Promise Error with reason: ${JSON.stringify(reason)}`;
        } else {
          message = `Promise Error with unrecognized reason: ${reason}`;
        }
        message += `\nStack: ${event?.error?.stack ?? event?.stack}`;
        return { message, tags: [], logLevel: 'error' };
      } else {
        const message = `Javascript Runtime Error in ${event?.filename} (${
          event?.lineno
        }:${event?.colno}): ${event?.message}\nStack: ${
          event?.error?.stack ?? event?.stack
        }`;
        return { message, tags: ['JS_ERROR'], logLevel: 'error' };
      }
    },
  }),
];

if (monitorRequest) {
  plugins.push(
    new AutoLogStaticResourcePlugin({
      enable: shouldReportPerformance,
      debug: false,
      includeResourceList,
      excludeResourceTimingKeys,
      userRegion: region,
    }),
  );
}

const laplaceConfig = {
  databaseName: DATABASE_NAME,
  maxSize: MAXIMUM_MAX_SIZE,
  reportUrl: reportUrl,
  plugins,
  publicTags: [
    'ZOOM WEB CLIENT',
    region,
    trackingId,
    isWebinar() ? 'Webinar' : 'Meeting',
  ],
  publicAttributes: {
    userAgent: navigator.userAgent,
    codeVersion: getCodeVersion(),
    accountId: escapeSensitiveContent(meetingConfig.accountId),
  },
};

let historyTrackingId =
  localStorage.getItem(encodeBase64(LOCALSTORAGE_KEYS.webClient_trackingIds)) ??
  [];
if (typeof historyTrackingId === 'string') {
  try {
    historyTrackingId = JSON.parse(decodeBase64(historyTrackingId));
  } catch (error) {
    historyTrackingId = [];
  }
}

const handleInitLaplaceSuccess = () => {
  if (historyTrackingId.length >= logConfig?.trackingCount ?? 7) {
    historyTrackingId.shift();
  }
  const trackingDict = {};
  historyTrackingId.forEach((trackingId) => {
    trackingDict[trackingId] = true;
  });

  const filterNoTrackingLog = (logItem) => {
    return !logItem.tags.some((tag) => trackingDict[tag]);
  };
  laplace.deleteLog(filterNoTrackingLog);

  historyTrackingId.push(trackingId);

  localStorage.setItem(
    encodeBase64(LOCALSTORAGE_KEYS.webClient_trackingIds),
    encodeBase64(JSON.stringify(historyTrackingId)),
  );

  laplace.log('Laplace init success! New Laplace session', [
    trackingId,
    'LAPLACE_NEW_SESSION',
  ]);
};

export let logLimitationPool = {};

export const initLaplace = () => {
  setInterval(() => {
    logLimitationPool = {};
  }, (logConfig?.cleanLimitationInterval ?? 600) * 1000);
  return Laplace.init(laplaceConfig)
    .then((laplaceInstance) => {
      if (laplaceInstance) {
        laplace = laplaceInstance;
        handleInitLaplaceSuccess();
      }
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(`initLaplace ERROR:`, e);
    });
};
