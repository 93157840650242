import { getBaseUrl } from '../../global/task-utils/common-utils';
import meetingConfig from '../../../global/meetingConfig';
import { cacheMeetingInfo } from '../../../global/service/meetingInfo';
import { watchReduxInMeeting } from './watch-redux-in-meeting';
import { PERFORMANCE_MARK, performanceMark } from '../../../performance';
import { LoadJs } from '../../global/task-utils/load-js';
import { isEnableOneChatUI } from '../../../global/service';
import { checkMeetingConfigReady } from '../../../features/preview/service';
import { logJoinFlowTelemetry } from '../../../global/logger/log-service/join-flow-telemetry';

function webClientJob({ ctx, taskProps }) {
  const runWebClient = () => {
    return checkMeetingConfigReady().then(() => {
      logJoinFlowTelemetry('wait_meetingJS_parse');
      /* eslint-disable-next-line no-undef */
      __webpack_public_path__ = `${getBaseUrl()}/`;
      return import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "main-client" */
        '../../../webclient'
      ).then(({ runWebClient, configureStore }) => {
        cacheMeetingInfo();
        logJoinFlowTelemetry('entry_meetingJS_now');
        performanceMark(
          taskProps?.fromPreview
            ? PERFORMANCE_MARK.start_InMeetingJS_fromPreview
            : PERFORMANCE_MARK.start_InMeetingJS,
        );
        const store = configureStore();
        ctx({
          getState: store.getState,
          dispatch: store.dispatch,
          reduxStore: store,
          fromPreview: taskProps?.fromPreview,
        });
        runWebClient(store);
        if (isEnableOneChatUI()) {
          LoadJs.start(meetingConfig)
            .then((v) => {
              return v.add('js/dll/chat/vendor_oneChat.js');
            })
            .then(() => {
              store.dispatch({
                type: '@one-chat/reloadChatApp',
              });
            });
        }
      });
    });
  };

  return LoadJs.start(meetingConfig)
    .then((v) => {
      return v.add('js/main-client.webclient.min.js');
    })
    .then(() => {
      return runWebClient();
    });
}

export const taskRunWebClient = [webClientJob, watchReduxInMeeting];
