export const WS_CONN_KEEPALIVE = 0;

// event type
export const CONF_EVT_TYPE_BASE = 0x1000;
export const AUDIO_EVT_TYPE_BASE = 0x2000;
export const VIDEO_EVT_TYPE_BASE = 0x3000;
export const SHARING_EVT_TYPE_BASE = 0x4000;
export const XMPP_EVT_TYPE_BASE = 0x6000;
export const WB_EVT_TYPE_BASE = 0x7000;

// conference level event request/response
export const WS_CONF_JOIN_REQ = 1 | CONF_EVT_TYPE_BASE;
export const WS_CONF_JOIN_RES = 2 | CONF_EVT_TYPE_BASE; //4098
export const WS_CONF_LOCK_REQ = 3 | CONF_EVT_TYPE_BASE;
export const WS_CONF_LOCK_RES = 4 | CONF_EVT_TYPE_BASE;
export const WS_CONF_END_REQ = 5 | CONF_EVT_TYPE_BASE; //4101
export const WS_CONF_END_RES = 6 | CONF_EVT_TYPE_BASE;
export const WS_CONF_LEAVE_REQ = 7 | CONF_EVT_TYPE_BASE; //4103
export const WS_CONF_LEAVE_AND_PUT_ALL_INTO_WR_REQ = 4364;
export const WS_CONF_LEAVE_RES = 8 | CONF_EVT_TYPE_BASE;
export const WS_CONF_RECORD_REQ = 9 | CONF_EVT_TYPE_BASE;
export const WS_CONF_RECORD_RES = 10 | CONF_EVT_TYPE_BASE; // deprecated
export const WS_CONF_EXPEL_REQ = 11 | CONF_EVT_TYPE_BASE;
export const WS_CONF_EXPEL_RES = 12 | CONF_EVT_TYPE_BASE;
export const WS_CONF_RENAME_REQ = 13 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ASSIGN_HOST_REQ = 15 | CONF_EVT_TYPE_BASE;
export const WS_CONF_PUT_ON_HOLD_REQ = 17 | CONF_EVT_TYPE_BASE;
export const WS_CONF_SET_MUTE_UPON_ENTRY_REQ = 19 | CONF_EVT_TYPE_BASE;
export const WS_CONF_SET_HOLD_UPON_ENTRY_REQ = 21 | CONF_EVT_TYPE_BASE;
export const WS_CONF_INVITE_CRC_DEVICE_REQ = 23 | CONF_EVT_TYPE_BASE;
export const WS_CONF_INVITE_CRC_DEVICE_RES = 24 | CONF_EVT_TYPE_BASE;
export const WS_CONF_CANCEL_INVITE_CRC_DEVICE_REQ = 25 | CONF_EVT_TYPE_BASE;
export const WS_CONF_CANCEL_INVITE_CRC_DEVICE_RES = 26 | CONF_EVT_TYPE_BASE;
export const WS_CONF_SET_BROADCAST_REQ = 27 | CONF_EVT_TYPE_BASE;
export const WS_CONF_SET_BROADCAST_RES = 28 | CONF_EVT_TYPE_BASE;
export const WS_CONF_CLOSED_CAPTION_REQ = 29 | CONF_EVT_TYPE_BASE;
export const WS_CONF_CLOSED_CAPTION_RES = 30 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_VIEW_PARTICIPANT_REQ = 31 | CONF_EVT_TYPE_BASE;
export const WS_CONF_SEND_MEETING_TOKEN_RES = 32 | CONF_EVT_TYPE_BASE; //4128
export const WS_CONF_LOWER_ALL_HAND_REQ = 33 | CONF_EVT_TYPE_BASE;
export const WS_CONF_RAISE_LOWER_HAND_REQ = 35 | CONF_EVT_TYPE_BASE;
export const WS_CONF_RECLAIM_HOST_REQ = 37 | CONF_EVT_TYPE_BASE;
export const WS_CONF_CHAT_REQ = 39 | CONF_EVT_TYPE_BASE; // 4135
export const WS_CONF_CHAT_RES = 40 | CONF_EVT_TYPE_BASE; // 4136
export const WS_CONF_ASSIGN_CC_REQ = 41 | CONF_EVT_TYPE_BASE;
export const WS_CONF_CHAT_PRIVILEDGE_REQ = 45 | CONF_EVT_TYPE_BASE; // 4141
export const WS_CONF_FEEDBACK_REQ = 47 | CONF_EVT_TYPE_BASE;
export const WS_CONF_FEEDBACK_CLEAR_REQ = 49 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_UNMUTE_VIDEO_REQ = 51 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_UNMUTE_AUDIO_REQ = 53 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_RAISE_HAND_REQ = 55 | CONF_EVT_TYPE_BASE;
export const WS_CONF_MONITOR_LOG_REQ = 71 | CONF_EVT_TYPE_BASE; // 4167
export const WS_CONF_PANELIST_VOTE_REQ = 57 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_TOKEN_REQ = 77 | CONF_EVT_TYPE_BASE; // 4173
export const WS_CONF_BO_TOKEN_RES = 78 | CONF_EVT_TYPE_BASE; // 4174
export const WS_CONF_BO_START_REQ = 79 | CONF_EVT_TYPE_BASE; // 4175
export const WS_CONF_BO_STOP_REQ = 81 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_ASSIGN_REQ = 83 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_SWITCH_REQ = 85 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_WANT_JOIN_REQ = 87 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_LEAVE_REQ = 89 | CONF_EVT_TYPE_BASE; // 4185
export const WS_CONF_BO_BROADCAST_REQ = 91 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_HELP_REQ = 93 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_HELP_RESULT_REQ = 95 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_JOIN_REQ = 97 | CONF_EVT_TYPE_BASE; // 4193
export const WS_CONF_BO_JOIN_RES = 98 | CONF_EVT_TYPE_BASE; // 4194
export const WS_CONF_ALLOW_PARTICIPANT_RENAME_REQ = 67 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_MESSAGE_FEEDBACK_NOTIFY_REQ =
  75 | CONF_EVT_TYPE_BASE;
export const WS_CONF_REVOKE_COHOST_REQ = 99 | CONF_EVT_TYPE_BASE;
export const WS_CONF_PLAY_CHIME_OPEN_CLOSE_REQ = 101 | CONF_EVT_TYPE_BASE; // 4197
export const WS_CONF_ADMIT_ALL_SILENT_USERS_REQ = 103 | CONF_EVT_TYPE_BASE; // 4199
export const WS_CONF_BIND_UNBIND_TELE_USR_REQ = 105 | CONF_EVT_TYPE_BASE; // 4201
export const WS_CONF_ALLOW_QA_AUTO_REPLY_REQ = 107 | CONF_EVT_TYPE_BASE; // 4203
export const WS_CONF_EXPEL_ATTENDEE_REQ = 109 | CONF_EVT_TYPE_BASE; // 4205
export const WS_CONF_EXPEL_ATTENDEE_RES = 110 | CONF_EVT_TYPE_BASE; // 4206
export const WS_CONF_PRACTICE_SESSION_REQ = 111 | CONF_EVT_TYPE_BASE; // 4207
export const WS_CONF_PRACTICE_SESSION_RES = 112 | CONF_EVT_TYPE_BASE; // 4208
export const WS_CONF_ROLE_CHANGE_REQ = 113 | CONF_EVT_TYPE_BASE; // 4209
export const WS_CONF_ROLE_CHANGE_RES = 114 | CONF_EVT_TYPE_BASE; // 4210
export const WS_CONF_BO_TOKEN_BATCH_REQ = 115 | CONF_EVT_TYPE_BASE; // 4211
export const WS_CONF_BO_PRE_ASSIGN_REQ = 117 | CONF_EVT_TYPE_BASE; // 4213
export const WS_CONF_BO_PRE_ASSIGN_RES = 118 | CONF_EVT_TYPE_BASE; // 4214
export const WS_CONF_CHANGE_MULTI_PIN_PRIVILGE_REQ = 121 | CONF_EVT_TYPE_BASE; // 4217
export const WS_CONF_SET_GROUP_LAYOUT = 123 | CONF_EVT_TYPE_BASE; // 4219
export const WS_CONF_HOST_KEY_REQ = 119 | CONF_EVT_TYPE_BASE; // 4215
export const WS_CONF_HOST_KEY_RES = 120 | CONF_EVT_TYPE_BASE; // 4216
export const WS_CONF_AVATAR_PERMISSION_CHANGED = 126 | CONF_EVT_TYPE_BASE; // 4222
export const WS_CONF_SUSPEND_MEETING = 133 | CONF_EVT_TYPE_BASE; // 4229
export const WS_CONF_SUSPEND_MEETING_REQ_RESULT = 134 | CONF_EVT_TYPE_BASE; // 4230
export const WS_CONF_BO_ASSIGN_BATCH_REQ = 135 | CONF_EVT_TYPE_BASE; // 4231
export const WS_CONF_CHAT_CMD_REQ = 141 | CONF_EVT_TYPE_BASE; // 4237
export const WS_CONF_CHAT_CMD_RES = 142 | CONF_EVT_TYPE_BASE; // 4238

export const WS_CONF_CHAT_CMD_INDICATION = 24 | CONF_EVT_TYPE_BASE | 0x0f00; // 7960
// TODO: REPLACE the enum value after we learn what they need to be.
export const WS_CONF_CHAT_DISABLED_BY_SERVER_INDICATION =
  7992 | CONF_EVT_TYPE_BASE; // 7992
export const WS_CONF_MEETING_TOPIC_REQ = 136 | CONF_EVT_TYPE_BASE; // 4231
export const WS_CONF_MEETING_TOPIC_CHANGE_FAILED = 137 | CONF_EVT_TYPE_BASE; // 4232
export const WS_CONF_MEETING_TOPIC_CHANGE = 138 | CONF_EVT_TYPE_BASE; // 4233

export const WS_CONF_BO_COHOST_START_REQ = 145 | CONF_EVT_TYPE_BASE; // 4241
export const WS_CONF_BO_COHOST_STOP_REQ = 147 | CONF_EVT_TYPE_BASE; // 4243
export const WS_CONF_BO_COHOST_ASSIGN_REQ = 149 | CONF_EVT_TYPE_BASE; // 4245
export const WS_CONF_BO_MOVE_TO_MAIN_SESSION_REQ = 151 | CONF_EVT_TYPE_BASE; // 4247
export const WS_CONF_BO_COHOST_MOVE_TO_MAIN_SESSION_REQ =
  153 | CONF_EVT_TYPE_BASE; // 4249

// audio interpretation
export const WS_CONF_INTERPRETER_TYPE_CHANGE = 154 | CONF_EVT_TYPE_BASE; // 4250
export const WS_CONF_PARTICIPANT_LIST_CHANGE = 155 | CONF_EVT_TYPE_BASE; // 4251
export const WS_CONF_INTERPRETER_START = 156 | CONF_EVT_TYPE_BASE; // 4252
export const WS_CONF_PARTICIPANT_LANGUAGE_INVALID = 157 | CONF_EVT_TYPE_BASE; // 4253

// sign language interpretation
export const WS_CONF_SIGN_INTERPRETER_LIST_CHANGE_INDICATION = 7989;
export const WS_CONF_ALLOW_SIGN_INTERPRETER_TO_TALK_INDICATION = 7990;
export const WS_CONF_SIGN_INTERPRETER_STATUS_INDICATION = 7991;

export const WS_CONF_BO_RENAME_REQ = 177 | CONF_EVT_TYPE_BASE; // 4273
export const WS_CONF_BO_DELETE_REQ = 179 | CONF_EVT_TYPE_BASE; // 4275
export const WS_CONF_BO_CREATE_REQ = 181 | CONF_EVT_TYPE_BASE; // 4277

export const WS_CONF_BO_SAVE_GROUP_REQ = 197 | CONF_EVT_TYPE_BASE; // 4293
export const WS_CONF_BO_SAVE_GROUP_RES = 198 | CONF_EVT_TYPE_BASE; // 4294
export const WS_CONF_BO_BROADCAST_VOICE_REQ = 218 | CONF_EVT_TYPE_BASE; // 4314

export const WS_CONF_AB_TOKEN_RES = 203 | CONF_EVT_TYPE_BASE; // 4299
export const WS_CONF_AB_TOKEN_REQ = 204 | CONF_EVT_TYPE_BASE; // 4300

// audio event request/response
export const WS_AUDIO_MUTE_REQ = 1 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_MUTE_RES = 2 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_DROP_REQ = 3 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_DROP_RES = 4 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_DIALOUT_REQ = 5 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_DIALOUT_RES = 6 | AUDIO_EVT_TYPE_BASE; // 8198
export const WS_AUDIO_CANCEL_DIALOUT_REQ = 7 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_CANCEL_DIALOUT_RES = 8 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_MUTEALL_REQ = 9 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_MUTEALL_RES = 10 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_ALLOW_TALK_REQ = 12 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_ALLOW_TALK_RES = 13 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_SET_PARTICIPANT_ACTIVE_LANGUAGE =
  14 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_PARTICIPANT_LANGUAGE_CHANGE_RES =
  15 | AUDIO_EVT_TYPE_BASE;
export const WS_CONF_HOST_START_STOP_UPDATE_INTERPERTATION_REQ =
  206 | CONF_EVT_TYPE_BASE;
export const WS_AUDIO_STATUS_CHANGE_REQ = 16 | AUDIO_EVT_TYPE_BASE;
// conf event notification
export const WS_CONF_ROSTER_INDICATION = 1 | CONF_EVT_TYPE_BASE | 0x0f00; // 7937
export const WS_CONF_ATTRIBUTE_INDICATION = 2 | CONF_EVT_TYPE_BASE | 0x0f00; // 7938
export const WS_CONF_NET_LTT_CAP_STATUS_INDICATION = 8014;
export const WS_CONF_NET_LTT_CC_URL = 8026;
export const WS_CONF_END_INDICATION = 3 | CONF_EVT_TYPE_BASE | 0x0f00; //7939
export const WS_CONF_HOST_CHANGE_INDICATION = 4 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_CONF_COHOST_CHANGE_INDICATION = 5 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_CONF_HOLD_CHANGE_INDICATION = 6 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_CONF_CLOSED_CAPTION_INDICATION =
  7 | CONF_EVT_TYPE_BASE | 0x0f00; // 7943
export const WS_CONF_CHAT_INDICATION = 8 | CONF_EVT_TYPE_BASE | 0x0f00; // 7944
export const WS_CONF_OPTION_INDICATION = 9 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_CONF_KV_UPDATE_INDICATION = 10 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_CONF_LOCAL_RECORD_INDICATION = 11 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_AUDIO_VOIP_JOIN_CHANNEL_REQ = 11 | AUDIO_EVT_TYPE_BASE; // voip
export const WS_CONF_BO_COMMAND_INDICATION = 13 | CONF_EVT_TYPE_BASE | 0x0f00; // 7949
export const WS_CONF_BO_ATTRIBUTE_INDICATION = 14 | CONF_EVT_TYPE_BASE | 0x0f00; // 7950
export const WS_CONF_ADMIT_ALL_SILENT_USERS_INDICATION =
  15 | CONF_EVT_TYPE_BASE | 0x0f00; // 7951
export const WS_CONF_BIND_UNBIND_INDICATION = 16 | CONF_EVT_TYPE_BASE | 0x0f00; // 7952
export const WS_CONF_UPDATE_MEETING_TOPIC_INDICATION =
  17 | CONF_EVT_TYPE_BASE | 0x0f00; // 7953
export const WS_CONF_DC_REGION_INDICATION = 18 | CONF_EVT_TYPE_BASE | 0x0f00; // 7954
export const WS_CONF_CAN_ADMIT_WHEN_NOHOST_PRESENT_INDICATION =
  19 | CONF_EVT_TYPE_BASE | 0x0f00; // 7955

export const WS_CONF_GROUP_LAYOUT_INDICATION = 22 | CONF_EVT_TYPE_BASE | 0x0f00; // 7958
export const WS_CONF_START_BO_BROADCAST_VOICE_INDICATION =
  63 | CONF_EVT_TYPE_BASE | 0x0f00; // 7999
export const WS_CONF_SIMULIVEPLAYER_INDEX_INDICATION =
  77 | CONF_EVT_TYPE_BASE | 0x0f00; //8013

// audio event notification
export const WS_AUDIO_ASN_INDICATION = 1 | AUDIO_EVT_TYPE_BASE | 0x0f00;
export const WS_AUDIO_MUTE_INDICATION = 2 | AUDIO_EVT_TYPE_BASE | 0x0f00;
export const WS_AUDIO_SSRC_INDICATION = 3 | AUDIO_EVT_TYPE_BASE | 0x0f00;
export const WS_AUDIO_ALLOW_TALK_INDICATION = 4 | AUDIO_EVT_TYPE_BASE | 0x0f00;
export const WS_AUDIO_SSRC_ASK_UNMUTE_INDICATION =
  5 | AUDIO_EVT_TYPE_BASE | 0x0f00;
export const WS_WEBINAR_VIEW_ONLY_TELEPHONY_INDICATION =
  6 | AUDIO_EVT_TYPE_BASE | 0x0f00;
export const WS_AUDIO_ENCRYPT_KEY_INDICATION = 7 | AUDIO_EVT_TYPE_BASE | 0x0f00; // 12039

export const WS_AUDIO_FEATURE_INDICATION = 8 | AUDIO_EVT_TYPE_BASE | 0x0f00; //12040 silk
export const WS_MULTI_SPEAKER_INDICATION = 9 | AUDIO_EVT_TYPE_BASE | 0x0f00; //12041

// video notificaiton;
export const WS_VIDEO_ACTIVE_INDICATION = 1 | VIDEO_EVT_TYPE_BASE | 0x0f00; // 16129
export const WS_VIDEO_SSRC_INDICATION = 3 | VIDEO_EVT_TYPE_BASE | 0x0f00;
export const WS_VIDEO_MUTE_INDICATION = 5 | VIDEO_EVT_TYPE_BASE | 0x0f00;
export const WS_VIDEO_LEADERSHIP_INDICATION = 7 | VIDEO_EVT_TYPE_BASE | 0x0f00;
export const WS_VIDEO_HOST_MUTE_ALL_VIDEO_INDICATION =
  9 | VIDEO_EVT_TYPE_BASE | 0x0f00;
export const WS_VIDEO_ENCRYPT_KEY_INDICATION =
  10 | VIDEO_EVT_TYPE_BASE | 0x0f00; // 16138

// video;
export const WS_VIDEO_SUBSCRIBE_REQ = 1 | VIDEO_EVT_TYPE_BASE;
export const WS_VIDEO_UNSUBSCRIBE_REQ = 3 | VIDEO_EVT_TYPE_BASE;
export const WS_VIDEO_KEY_FRAME_REQ = 5 | VIDEO_EVT_TYPE_BASE; // WCL KEY FRAME REQUEST
export const WS_VIDEO_NETWORK_FEEDBACK = 7 | VIDEO_EVT_TYPE_BASE; // WCL KEY FRAME REQUEST
export const WS_VIDEO_MUTE_VIDEO_REQ = 9 | VIDEO_EVT_TYPE_BASE;
export const WS_VIDEO_SPOTLIGHT_VIDEO_REQ = 11 | VIDEO_EVT_TYPE_BASE;
export const WS_VIDEO_CAMERA_REQ = 19 | VIDEO_EVT_TYPE_BASE; //12307 update camera status

// sharing notification
export const WS_SHARING_PAUSE_REQ = 1 | SHARING_EVT_TYPE_BASE;
export const WS_SHARING_RESUME_REQ = 3 | SHARING_EVT_TYPE_BASE;
export const WS_SHARING_STATUS_INDICATION = 1 | SHARING_EVT_TYPE_BASE | 0x0f00;
export const WS_SHARING_SIZE_CHANGE_INDICATION =
  2 | SHARING_EVT_TYPE_BASE | 0x0f00;

export const WS_SHARING_ENCRYPT_KEY_INDICATION =
  10 | SHARING_EVT_TYPE_BASE | 0x0f00; // 20234
export const WS_SHARING_RECEIVING_CHL_READY_INDICATION =
  11 | SHARING_EVT_TYPE_BASE | 0x0f00; // 20235
export const WS_SHARING_RECEIVING_CHL_CLOSE_INDICATION =
  12 | SHARING_EVT_TYPE_BASE | 0x0f00; // 20236
export const WS_SHARING_WEBINAR_OUT_LIMIT_INDICATION =
  13 | SHARING_EVT_TYPE_BASE | 0x0f00; // 20237
// Webinar QA RWG Request Type
export const WS_CONF_ALLOW_ANONYMOUS_QUESTION_REQ = 59 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_VIEW_ALL_QUESTION_REQ = 61 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_UPVOTE_QUESTION_REQ = 63 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_COMMENT_QUESTION_REQ = 65 | CONF_EVT_TYPE_BASE;
// request urlschema for remote control
export const WS_CONF_GET_RC_URL_SCHEME_REQ = 245 | CONF_EVT_TYPE_BASE; //4341
export const WS_CONF_GET_RC_URL_SCHEME_RES = 246 | CONF_EVT_TYPE_BASE; //4342
export const WS_SHARING_REMOTE_CONTROL_REQ = 5 | SHARING_EVT_TYPE_BASE;
export const WS_SHARING_REMOTE_CONTROL_INDICATION = 7 | SHARING_EVT_TYPE_BASE; // 16391
export const WS_SHARING_REMOTE_CONTROLLER_GRAB = 9 | SHARING_EVT_TYPE_BASE; // 16393
export const WS_SHARING_REMOTE_CONTROLLER_GRAB_INDICATION =
  11 | SHARING_EVT_TYPE_BASE; // 16395
export const WS_SHARING_SUBSCRIBE_REQ = 31 | SHARING_EVT_TYPE_BASE;
export const WS_SHARING_UNSUBSCRIBE_REQ = 33 | SHARING_EVT_TYPE_BASE;
export const WS_SHARING_REMOTE_CONTROLED_CONSENT = 43 | SHARING_EVT_TYPE_BASE; //16427
export const WS_SHARING_REMOTE_CONTROLED_CLOSE_INDICATION =
  44 | SHARING_EVT_TYPE_BASE; //16428
export const WS_SHARING_REMOTE_SYNC_SCREEN_INFO_TO_RCAPP_REQ =
  45 | SHARING_EVT_TYPE_BASE; //16429
export const WS_SHARING_REMOTE_CONTROLED_CONNECTED_INDICATION =
  46 | SHARING_EVT_TYPE_BASE; //16430, rc app connected indication to pwa
export const WS_SHARING_REMOTE_CONTROLED_TAKE_RIGHT_BACK_REQ =
  47 | SHARING_EVT_TYPE_BASE; //16431
export const WS_SHARING_REMOTE_CONTROLED_AUTH_REQ = 49 | SHARING_EVT_TYPE_BASE; //16433
export const WS_SHARING_REMOTE_CONTROLED_AUTH_RES = 50 | SHARING_EVT_TYPE_BASE; //16434
export const WS_SHARING_REMOTE_CONTROL_BLOCK_INDICATION =
  51 | SHARING_EVT_TYPE_BASE; //16435, remote control request block

// for collect time spend between RWC and RWG
export const WS_MEETING_RWG_CONNECT_TIME = 4167;
export const EVT_TYPE_WS_VIDEO_DATACHANNEL_ANSWER = 24322;
export const DATA_CHANNEL_SEND_OFFER_TO_RWG = 24321;

// dataChannel downgrade to websocket
export const ZOOM_CONNECTION_REMOVE_UDP_EVT = 3 | 0x5000 | 0x0f00; // 24323
//
export const WS_CONF_FOLLOW_HOST_REQ = 4223;
export const WS_CONF_DRAG_LAYOUT_INDICATION = 7957;
export const WS_CONF_SET_DRAG_LAYOUT = 4218;

// #region live transcription
export const WS_CONF_LIVE_TRANSCRIPTION_ON_OFF_REQ = 131 | CONF_EVT_TYPE_BASE; // 4227
export const WS_CONF_LIVE_TRANSCRIPTION_ON_OFF_RES = 132 | CONF_EVT_TYPE_BASE; // 4228
export const WS_CONF_LIVE_TRANSCRIPTION_STATUS_INDICATION =
  23 | CONF_EVT_TYPE_BASE | 0x0f00; // 7959

export const WS_ASK_HOST_START_LIVE_TRANSCRIPT_REQ = 165 | CONF_EVT_TYPE_BASE; // 4261
export const WS_HOST_CONSENT_START_LIVE_TRANSCRIPT_REQ =
  166 | CONF_EVT_TYPE_BASE; // 4262
export const WS_CONF_ALLOW_ASK_HOST_START_LIVE_TRANSCRIPT_REQ =
  167 | CONF_EVT_TYPE_BASE; // 4263
export const WS_CONF_HOST_RECEIVED_ENABLE_LIVE_TRANSCRIPT_REQ_INDICATION =
  32 | CONF_EVT_TYPE_BASE | 0x0f00; // 7968
export const WS_CONF_ALLOW_ASK_HOST_START_LIVE_TRANSCRIPT_INDICATION =
  33 | CONF_EVT_TYPE_BASE | 0x0f00; // 7969
// #endregion

// Polling

export const WS_CONF_POLLING_REQ = 4165;
export const WS_CONF_POLLING_USER_ACTION_REQ = 128 | CONF_EVT_TYPE_BASE; // 4224
export const WS_CONF_POLLING_USER_ACTION_ERROR = 129 | CONF_EVT_TYPE_BASE; // 4225
export const WS_CONF_POLLING_SET_POLLING_TOKEN = 130 | CONF_EVT_TYPE_BASE; // 4226

export const WS_CONF_ATTENDEE_VIDEO_CONTROL_MODE_CHANGE_REQ =
  139 | CONF_EVT_TYPE_BASE; // 4235
export const WS_CONF_ATTENDEE_VIDEO_LAYOUT_MODE_CHANGE_REQ =
  140 | CONF_EVT_TYPE_BASE; // 4236
//
export const WS_CONF_UPDATE_ZAK_REQ = 143 | CONF_EVT_TYPE_BASE; // 4239

export const WS_CONF_ARCHIVING_STATUS = 7961;
export const WS_CONF_ARCHIVING_OPTIONS = 7962;

export const WC_CONF_APP_SIGNAL_INDICATION = 7964;
export const WC_CONF_APP_SIGNAL_DETAIL_REQ = 4255;
export const WC_CONF_APP_SIGNAL_DETAIL_RES = 4256;
export const WC_CONF_APP_SIGNAL_APP_LINK_REQ = 4257;
export const WC_CONF_APP_SIGNAL_APP_LINK_RES = 4258;

export const WS_CONF_PROMOTE_CONSENT_RES = 7963;
export const WS_CONF_PROMOTE__CONSENT_REQ = 4254;

export const WS_CONF_NDI_PRIVACY_LINK_INDICATION = 7965;

// Reactions
export const WS_CONF_SEND_REACTION_REQ = 163 | CONF_EVT_TYPE_BASE; // 4259
export const WS_CONF_RECEIVE_REACTION_RESULT = 164 | CONF_EVT_TYPE_BASE; // 4260

export const WS_CONF_USER_PRONOUN_CHANGE_REQ = 168 | CONF_EVT_TYPE_BASE; // 4264
export const WS_CONF_SET_SKIN_TONE_REQ = 174 | CONF_EVT_TYPE_BASE; // 4270
export const WS_CONF_USER_SHARE_PRONOUN_TYPE_INDICATION =
  169 | CONF_EVT_TYPE_BASE; // 4265
export const WS_CONF_POLLING_OPTION_CHANGE_REQ = 170 | CONF_EVT_TYPE_BASE; // 4266

// #region attention mode
export const WS_CONF_VIDEO_ATTENTION_MODE_LIST =
  30 | CONF_EVT_TYPE_BASE | 0x0f00; // 7966 host,coHost get whitelist
export const WS_CONF_VIDEO_ATTENTION_MODE_SWITCH =
  31 | CONF_EVT_TYPE_BASE | 0x0f00; // 7967 host,cohost enable attention mode
export const WS_CONF_SHARE_ATTENTION_MODE_SET_TYPE_REQ =
  38 | CONF_EVT_TYPE_BASE | 0x0f00; // 7974 change share permission
export const WS_CONF_SHARE_ATTENTION_MODE_LIST =
  37 | CONF_EVT_TYPE_BASE | 0x0f00; // 7973 receive share white list
export const WS_CONF_MMR_SUPPORT_MEETING_ATTENTION_MODE_INDICATION =
  36 | CONF_EVT_TYPE_BASE | 0x0f00; // 7972
// #endregion
export const WS_CONF_POLLING_IN_MEETING_OPTIONS_INDICATION =
  35 | CONF_EVT_TYPE_BASE | 0x0f00; // 7971

export const WS_CONF_LIVE_STREAM_STATUS = 7970;

export const WS_CONF_FETCH_MEETING_INVITEES_INDICATION =
  39 | CONF_EVT_TYPE_BASE | 0x0f00; // 7975

export const WS_VIDEO_MULTI_SUBSCRIBE_REQ = 12303;
export const WS_VIDEO_MULTI_UNSUBSCRIBE_REQ = 12305;
export const WS_CONF_LIVE_STREAM_BROADCAST_INFO =
  40 | CONF_EVT_TYPE_BASE | 0x0f00; // 7976
export const WS_CONF_LIVE_STREAM_ACTION = 41 | CONF_EVT_TYPE_BASE | 0x0f00; // 7977

export const WS_CONF_REQ_LOCAL_LIVE_STREAM_PERMISSION_REC =
  262 | CONF_EVT_TYPE_BASE; //4358
export const WS_CONF_CONSENT_LOCAL_LIVE_STREAM_PERMISSION_REQ =
  263 | CONF_EVT_TYPE_BASE; //4359
export const WS_CONF_CHANGE_LOCAL_LIVE_STREAM_INFO_REQ =
  265 | CONF_EVT_TYPE_BASE; //4361
export const WS_CONF_HOST_REMOVE_LOCAL_LIVE_STREAM_PERMISSION_REQ =
  267 | CONF_EVT_TYPE_BASE; //4363

export const WS_CONF_MEETING_VIDEO_ATTENTION_MODE_ENDING_INDICATION =
  42 | CONF_EVT_TYPE_BASE | 0x0f00; // 7978
export const WS_CONF_MEETING_SHARE_ATTENTION_MODE_ENDING_INDICATION =
  43 | CONF_EVT_TYPE_BASE | 0x0f00; // 7979
export const WS_VIDEO_ACTIVE_SOURCE_INDICATION =
  11 | VIDEO_EVT_TYPE_BASE | 0x0f00; // 16139 video talking

export const WS_CONF_TURN_ON_OFF_WATERMARK_REQ = 175 | CONF_EVT_TYPE_BASE; // 4271

export const WS_CONF_UPLOAD_GALLERY_VIDEO_ORDER_REQ = 183 | CONF_EVT_TYPE_BASE; // 4279
export const WS_CONF_UPLOAD_GALLERY_VIDEO_ORDER_RES = 184 | CONF_EVT_TYPE_BASE; // 4280
export const WS_CONF_DOWNLOAD_GALLERY_VIDEO_ORDER_REQ =
  185 | CONF_EVT_TYPE_BASE; // 4281
export const WS_CONF_DOWNLOAD_GALLERY_VIDEO_ORDER_RES =
  186 | CONF_EVT_TYPE_BASE; // 4282
export const WS_CONF_SET_ALLOW_WEBINAR_EMOJI_REACTION_REQ =
  187 | CONF_EVT_TYPE_BASE; // 4283
export const WS_CONF_SEND_WEBINAR_EMOJI_REACTION_REQ = 188 | CONF_EVT_TYPE_BASE; // 4284
export const WS_CONF_ALLOW_WEBINAR_EMOJI_REACTION_INDICATION =
  44 | CONF_EVT_TYPE_BASE | 0x0f00; // 7980
export const WS_CONF_RECEIVER_WEBINAR_EMOJI_REACTION_INDICATION =
  45 | CONF_EVT_TYPE_BASE | 0x0f00; // 7981

export const WS_CONF_CHAT_FILE_TRANSFER_REQ = 211 | CONF_EVT_TYPE_BASE; // 4307;

export const WS_CONF_CHAT_FILE_INDICATION = 212 | CONF_EVT_TYPE_BASE; // 4308;

export const WS_CONF_NEW_LTT_UI_ENABLE_REQ = 4285;
export const WS_CONF_NEW_LTT_STATUS = 7982;
export const WS_CONF_NEW_LTT_LAN_SUPPORT_INDICATION = 7984;
export const WS_CONF_NEW_LTT_SUB_TRANSCRIPTION_INDICATION = 7985;
export const WS_CONF_NEW_LTT_SET_MEETING_LANG_REQ = 4287;
export const WS_CONF_NEW_LTT_MEETING_SPEAK_LANGUAGE_CHANGE_INDICATION = 7986;
export const WS_CONF_NEW_LTT_OP_REQ = 4305;
export const WS_CONF_NEW_LTT_TEXT_DATA_INDICATION = 7983;
export const WS_CONF_NEW_LTT_SEND_MCAP_REQ = 4289;
export const WS_CONF_NEW_LTT_OP_MCAP_REQ = 4291;
export const WS_CONF_NEW_LTT_CAPTION_DISABLED = 8011;
export const WS_CONF_NEW_LTT_SIMULIVE_LANGUAGE_CHANGE = 8027;

export const WS_CONF_WEBINAR_SESSION_BRANDING_TEMPLATES_AND_VBS = 7987;
export const WS_CONF_WEBINAR_SESSION_BRANDING_APPLY_CHANGES = 4295;
export const WS_CONF_WEBINAR_SESSION_BRANDING_APPLY_CHANGES_RESULT = 4296;
export const WS_CONF_WEBINAR_SESSION_BRANDING_RETRY = 4297;
export const WS_CONF_LAUNCH_PARAMS_REQ = 4301;
export const WS_CONF_INVITEE_IAK_REQ = 219 | CONF_EVT_TYPE_BASE; //4315
export const WS_CONF_INVITEE_IAK_RES = 220 | CONF_EVT_TYPE_BASE; //4316
export const WS_CONF_REQUEST_TOKEN = 4309;
export const WS_CONF_REQUEST_TOKEN_RES = 4310;
export const WS_CONF_CHECK_CMR_PRIVILEGE_RES = 217 | CONF_EVT_TYPE_BASE; // 4313
export const WS_CONF_REJOIN_REQ = 274 | CONF_EVT_TYPE_BASE; // 4370

export const WS_CONF_SIDECAR_PROTO = 7993;
export const WC_CONF_SIDECAR_STATUS_UPDATED = 4311;
export const WS_CONF_SIDECAR_ACTIVATED_INDICATION = 7994;
export const WS_CONF_SIDECAR_DOCUMENT_CHANGED = 4323;
export const WS_CONF_SIDECAR_DOCUMENT_STATUS_CHANGED = 8002;
export const WS_CONF_SIDECAR_UPDATE_ACTIVE_SPEAKERS = 4339;
export const WS_CONF_SIDECAR_SPEAKERS_INDICATION = 8012;
export const WS_CONF_ALLOW_QA_AUTO_REPLY_RES = 4317;

export const WS_CONF_QA_REQ = 4321;
export const WS_CONF_QA_INDICATION = 8000;
export const WS_CONF_RECORDING_ANALYTICS_REQ = 222 | CONF_EVT_TYPE_BASE; //4318
export const WS_CONF_RECORDING_ANALYTICS_RES = 223 | CONF_EVT_TYPE_BASE; //4319

// local recording permission
export const WS_CONF_LOCALRECORDING_OP_REQ = 247 | CONF_EVT_TYPE_BASE; //4343
export const WS_CONF_LOCALRECORDING_OP_RES = 248 | CONF_EVT_TYPE_BASE; //4344

// whiteboard
export const WS_WB_START_SHARE = 1 | WB_EVT_TYPE_BASE;
export const WS_WB_STOP_SHARE = 2 | WB_EVT_TYPE_BASE;
export const WS_WB_CHANGE_SHARE_TO_ALL_REQ = 3 | WB_EVT_TYPE_BASE;
export const WS_WB_CHANGE_SHARE_ROLE_REQ = 4 | WB_EVT_TYPE_BASE;
export const WS_CONF_WB_LOCK_SHARE_REQ = 5 | WB_EVT_TYPE_BASE;

export const WS_WB_AS_STREAM_ON_OFF = 6 | WB_EVT_TYPE_BASE;
export const WS_WB_WHITEBOARD_INDICATION = 7 | WB_EVT_TYPE_BASE;
export const WS_CONF_WB_LOCK_SHARE_INDICATION = 8 | WB_EVT_TYPE_BASE;
export const WS_WB_WHITEBOARD_ERROR = 9 | WB_EVT_TYPE_BASE;

export const WS_CONF_SMS_INVITE_REQ = 231 | CONF_EVT_TYPE_BASE; //4327
export const WS_CONF_SMS_INVITE_RES = 232 | CONF_EVT_TYPE_BASE; //4328

export const WS_CONF_PREVIEW_INFO_RES = 4130;

// network quality
export const WS_CONF_BROADCAST_USER_NETWORK_QUALITY_REQ =
  235 | CONF_EVT_TYPE_BASE; //4331
export const WS_CONF_NOTIFY_USER_NETWORK_QUALITY_INDICATION =
  69 | CONF_EVT_TYPE_BASE | 0x0f00; //8005

export const WS_CONF_ZOOM_APP_CMD_REQ = 4335;
export const WS_CONF_ZOOM_APP_CMD_RES = 8006;

export const WS_CONF_MEDIA_SESSION_READY_INDICATION =
  60 | CONF_EVT_TYPE_BASE | 0x0f00; //7996

export const WS_CONF_SET_GALLERY_REVIEW_SORT_TYPE = 241 | CONF_EVT_TYPE_BASE; //4337
export const WS_CONF_START_MEETING_SUMMARY = 8009;
export const WS_CONF_QUERY_OP_REQ = 8017;
export const WS_CONF_QUERY_OP_RES = 82 | CONF_EVT_TYPE_BASE | 0xf00; // 8018
export const WS_CONF_MEETING_SUMMARY_INDICATION = 8008;
export const WS_CONF_MEETING_SUMMARY_SUPPORTED_INDICATION = 8007;
export const WS_CONF_QUERY_SUPPORTED_INDICATION = 8015;
export const WS_CONF_QUERY_STATUS_INDICATION = 8016;
export const WS_CONF_QUERY_SETTING_INDICATION = 87 | CONF_EVT_TYPE_BASE | 0xf00; // 8023
// transfer meeting
export const WS_CONF_GET_LCP_REQ = 4332;
export const WS_CONF_GET_LCP_RES = 4333;

// export const WS_CONF_TOGGLE_LIVE_STREAM = 28678;

export const WS_CONF_SEND_DTRESULT_REQ = 249 | CONF_EVT_TYPE_BASE; //4345

// far end camera control
export const WS_CONF_FAR_END_CAMERA_CONTROL_REQ = 233 | CONF_EVT_TYPE_BASE; //4329
export const WS_CONF_FAR_END_CAMERA_CONTROL_CAP_REQ = 234 | CONF_EVT_TYPE_BASE; //4330
export const WS_CONF_FAR_END_CAMERA_CONTROL_INDICATION =
  68 | CONF_EVT_TYPE_BASE | 0x0f00; //8004
export const WS_CONF_FAR_END_CAMERA_CONTROL_GROUP_REQ = 4352;
export const WS_CONF_FAR_END_CAMERA_CONTROL_GROUP_RES = 4353;

export const WS_CONF_SMART_RECORDING_SETTING_INDICATION = 8024;

export const WS_CONF_MEETING_CONFIG_INDICATION = 8025;

export const WS_CONF_SMART_RECORDING_OP_REQ = 4350;

export const WS_CONF_SMART_RECORDING_OP_RES = 4351;

export const WS_CONF_PRACTICE_USER_COUNT = 4365;

export const WS_CONF_MSDK_BYPASS_MSG = 4366;

export const WS_CONF_WRITE_RWG_MONITOR_LOG_REQ = 272 | CONF_EVT_TYPE_BASE; //4368

// annotation
// export const ANNO_EVT_TYPE_BASE = 0x4800;
// export const WS_ANNO_OVER_AS = 1 | ANNO_EVT_TYPE_BASE; // 18433
export const WS_SHARING_STATUS_ANNOTATION_FLAG_INDICATION =
  17 | SHARING_EVT_TYPE_BASE | 0x0f00; // 20241
export const WS_SHARING_STATUS_SHARED_OBJ_INDICATION =
  18 | SHARING_EVT_TYPE_BASE | 0x0f00; // 20242
