import _ from 'lodash';
/**
 * @typedef {import('../typings/meetingConfig.js').meetingConfig} meetingConfig
 */

/**
 * @type {meetingConfig}
 */
const meetingConfig = _.assign(
  {},
  {
    isSupportAV: true,
    isNewJoinFlow: true,
  },
  window.MeetingConfig,
  {
    debug: CLIENT_ENV === 'development',
  },
);

export const setField = (key, value) => {
  if (!key) {
    return;
  }
  meetingConfig[key] = value;
};

export const updateMeetingConfig = (config) => {
  _.extend(meetingConfig, config);
  _.extend(window.MeetingConfig, config);
};
/**
 * @type {meetingConfig}
 */
export default meetingConfig;
