import {
  REACTION_UNKNOWN,
  REACTION_CLAP,
  REACTION_THUMBS_UP,
  REACTION_JOY,
  REACTION_OPEN_MOUTH,
  REACTION_HEART,
  REACTION_TADA,
  NON_VERBAL_YES,
  NON_VERBAL_NO,
  NON_VERBAL_SPEED_UP,
  NON_VERBAL_SLOW_DOWN,
  REACTION_CATEGORY_LABEL_PEOPLE,
  REACTION_CATEGORY_LABEL_NATURE,
  REACTION_CATEGORY_LABEL_FOOD,
  REACTION_CATEGORY_LABEL_ACTIVITY,
  REACTION_CATEGORY_LABEL_TRAVEL,
  REACTION_CATEGORY_LABEL_OBJECTS,
  REACTION_CATEGORY_LABEL_SYMBOLS,
  REACTION_CATEGORY_LABEL_FLAGS,
  REACTION_FREQUENTLY_USED,
  NON_VERBAL_I_AM_AWAY,
  NON_VERBAL_I_AM_BACK,
  REACTION_ANIMATED_REACTIONS,
} from './resource';
import { RAISE_HAND_TITLE } from '../../global/resource';

export const REACTION_EXPIRE_TIME = 10000; // 10 seconds
export const ALL_EMOJI_SET_VERSION = 20211026;
export const ALL_EMOJI_SET_FILENAME = `emojione_webclient__${ALL_EMOJI_SET_VERSION}.zip`;
export const ALL_EMOJI_SET_PATH = `emoji/${ALL_EMOJI_SET_VERSION}/${ALL_EMOJI_SET_FILENAME}`;
export const NON_VERBAL_FEEDBACK_VIEW_BOX = '0 0 16 16';
export const RAISE_HAND_VIEW_BOX = '0 0 64 64';
export const REACTION_VIEW_BOX = '0 0 64 64';
export const EMOJI_LINE_HEIGHT = 36;
export const CATEGORY_LABEL_HEIGHT = 24;

export const GUEST_USER_KEY = 'GUEST';

export const REACTION_SET_TYPE = {
  NONVERBAL: 0,
  REACTION: 1,
  RAISE_HAND: 2,
};

export const REACTION_PICKER_TYPE = {
  SIMPLE: 0,
  ALL: 1,
};

export const NONVERBAL_TYPE = {
  NONVERBAL_NONE: 0,
  NONVERBAL_HAND: 1,
  NONVERBAL_YES: 2,
  NONVERBAL_NO: 3,
  NONVERBAL_FAST: 4,
  NONVERBAL_SLOW: 5,
  NONVERBAL_BAD: 6,
  NONVERBAL_GOOD: 7,
  NONVERBAL_CLAP: 8,
  NONVERBAL_COFFEE: 9,
  NONVERBAL_CLOCK: 10,
  NONVERBAL_EMOJI: 11,
};

export const ALL_EMOJI_SET_RESOURCE_STATUS = {
  UNKNOWN: 0,
  INITIATING: 1,
  DOWNLOADING: 2,
  PROCESSING: 3,
  DONE: 4,
  FAILED: 5,
};

export const SELECTED_EMOJI_REACTION_SET = {
  '1f44f': 'EmojiReactionType_Clap',
  '1f44f-1f3fb': 'EmojiReactionType_Clap',
  '1f44f-1f3fc': 'EmojiReactionType_Clap',
  '1f44f-1f3fd': 'EmojiReactionType_Clap',
  '1f44f-1f3fe': 'EmojiReactionType_Clap',
  '1f44f-1f3ff': 'EmojiReactionType_Clap',
  '1f44d': 'EmojiReactionType_Thumbsup',
  '1f44d-1f3fb': 'EmojiReactionType_Thumbsup',
  '1f44d-1f3fc': 'EmojiReactionType_Thumbsup',
  '1f44d-1f3fd': 'EmojiReactionType_Thumbsup',
  '1f44d-1f3fe': 'EmojiReactionType_Thumbsup',
  '1f44d-1f3ff': 'EmojiReactionType_Thumbsup',
  '1f602': 'EmojiReactionType_Joy',
  '1f62e': 'EmojiReactionType_Openmouth',
  2764: 'EmojiReactionType_Heart',
  '1f389': 'EmojiReactionType_Tada',
};
export const SELECTED_EMOJI_ANIMATION_NAME = {
  '1f44f': 'clap',
  '1f44d': 'thumbs_up',
  '1f602': 'Tear',
  '1f62e': 'hushed_face',
  2764: 'heart',
  '1f389': 'tada',
  // diversities
  '1f3fb': 'white',
  '1f3fc': 'mediumBrown',
  '1f3fd': 'Brown',
  '1f3fe': 'darkBrown',
  '1f3ff': 'black',
};

export const SELECTED_EMOJI_REACTION_TYPE = {
  EmojiReactionType_Unknown: {
    type: 0,
    name: REACTION_UNKNOWN,
    filename: '',
    content: '',
    code: '',
    hasDiversity: false,
    hasGender: false,
  },
  EmojiReactionType_Clap: {
    type: 1,
    name: REACTION_CLAP,
    filename: 'reactions/1f44f', // name in props for LazySVGIcon
    content: '👏',
    code: '1f44f',
    hasDiversity: true,
    hasGender: false,
    diversities: [
      '1f44f-1f3fb',
      '1f44f-1f3fc',
      '1f44f-1f3fd',
      '1f44f-1f3fe',
      '1f44f-1f3ff',
    ],
    code_points: {
      base: '1f44f',
      fully_qualified: '1f44f',
      non_fully_qualified: '1f44f',
      output: '1f44f',
      default_matches: ['1f44f'],
      greedy_matches: ['1f44f'],
      decimal: '',
    },
  },
  EmojiReactionType_Thumbsup: {
    type: 2,
    name: REACTION_THUMBS_UP,
    filename: 'reactions/1f44d',
    content: '👍',
    code: '1f44d',
    hasDiversity: true,
    hasGender: false,
    diversities: [
      '1f44d-1f3fb',
      '1f44d-1f3fc',
      '1f44d-1f3fd',
      '1f44d-1f3fe',
      '1f44d-1f3ff',
    ],
    code_points: {
      base: '1f44d',
      fully_qualified: '1f44d',
      non_fully_qualified: '1f44d',
      output: '1f44d',
      default_matches: ['1f44d'],
      greedy_matches: ['1f44d'],
      decimal: '',
    },
  },
  EmojiReactionType_Joy: {
    type: 3,
    name: REACTION_JOY,
    filename: 'reactions/1f602',
    content: '😂',
    code: '1f602',
    hasDiversity: false,
    hasGender: false,
    code_points: {
      base: '1f602',
      fully_qualified: '1f602',
      non_fully_qualified: '1f602',
      output: '1f602',
      default_matches: ['1f602'],
      greedy_matches: ['1f602'],
      decimal: '',
    },
  },
  EmojiReactionType_Openmouth: {
    type: 4,
    name: REACTION_OPEN_MOUTH,
    filename: 'reactions/1f62e',
    content: '😮',
    code: '1f62e',
    hasDiversity: false,
    hasGender: false,
    code_points: {
      base: '1f62e',
      fully_qualified: '1f62e',
      non_fully_qualified: '1f62e',
      output: '1f62e',
      default_matches: ['1f62e'],
      greedy_matches: ['1f62e'],
      decimal: '',
    },
  },
  EmojiReactionType_Heart: {
    type: 5,
    name: REACTION_HEART,
    filename: 'reactions/2764',
    content: '❤️',
    code: '2764',
    hasDiversity: false,
    hasGender: false,
    code_points: {
      base: '2764',
      fully_qualified: '2764-fe0f',
      non_fully_qualified: '2764',
      output: '2764-fe0f',
      default_matches: ['2764-fe0f', '2764'],
      greedy_matches: ['2764-fe0f', '2764'],
      decimal: '',
    },
  },
  EmojiReactionType_Tada: {
    type: 6,
    name: REACTION_TADA,
    filename: 'reactions/1f389',
    content: '🎉',
    code: '1f389',
    hasDiversity: false,
    hasGender: false,
    code_points: {
      base: '1f389',
      fully_qualified: '1f389',
      non_fully_qualified: '1f389',
      output: '1f389',
      default_matches: ['1f389'],
      greedy_matches: ['1f389'],
      decimal: '',
    },
  },
};

export const RAISE_HAND_META = {
  type: NONVERBAL_TYPE.NONVERBAL_HAND,
  name: RAISE_HAND_TITLE,
  filename: 'nvf/270b',
  hasDiversity: true,
  hasGender: false,
  diversities: [
    '270b-1f3fb',
    '270b-1f3fc',
    '270b-1f3fd',
    '270b-1f3fe',
    '270b-1f3ff',
  ],
  code_points: {
    base: '270b',
    fully_qualified: '270b',
    non_fully_qualified: '270b',
    output: '270b',
    default_matches: ['270b'],
    greedy_matches: ['270b'],
    decimal: '',
  },
};
export const LEGACY_NONVERBAL_FEEDBACK_MAP = {
  handshake: NONVERBAL_TYPE.NONVERBAL_HAND,
  yes: NONVERBAL_TYPE.NONVERBAL_YES,
  no: NONVERBAL_TYPE.NONVERBAL_NO,
  slower: NONVERBAL_TYPE.NONVERBAL_SLOW,
  faster: NONVERBAL_TYPE.NONVERBAL_FAST,
  coffee: NONVERBAL_TYPE.NONVERBAL_COFFEE,
};
export const NONVERBAL_DATA = {
  [NONVERBAL_TYPE.NONVERBAL_YES]: {
    filename: 'nvf/nvf_yes',
    ariaLabel: NON_VERBAL_YES,
  },
  [NONVERBAL_TYPE.NONVERBAL_NO]: {
    filename: 'nvf/nvf_no',
    ariaLabel: NON_VERBAL_NO,
  },
  [NONVERBAL_TYPE.NONVERBAL_FAST]: {
    filename: 'nvf/nvf_faster',
    ariaLabel: NON_VERBAL_SPEED_UP,
  },
  [NONVERBAL_TYPE.NONVERBAL_SLOW]: {
    filename: 'nvf/nvf_slower',
    ariaLabel: NON_VERBAL_SLOW_DOWN,
  },
  [NONVERBAL_TYPE.NONVERBAL_COFFEE]: {
    filename: 'nvf/nvf_coffee',
    ariaLabel: NON_VERBAL_I_AM_AWAY,
    alter: {
      circle: {
        filename: 'nvf/nvf_circle_coffee',
        ariaLabel: NON_VERBAL_I_AM_AWAY,
      },
      back: {
        ariaLabel: NON_VERBAL_I_AM_BACK,
      },
    },
  },
};

export const ANIMATION_RENDER_DATA = {
  width: 36,
  height: 36,
};

export const RESPONSIVE_REACTION_SIZE_DATA = {
  mini: {
    width: 16,
    height: 16,
  },
  noVideo: {
    width: 28,
    height: 28,
    classModifier: 'no-video',
  },
  thumbnail: {
    width: 32,
    height: 32,
    classModifier: 'thumbnail',
  },
  small: {
    width: 40,
    height: 40,
    classModifier: 'small',
  },
  middle: {
    width: 48,
    height: 48,
    classModifier: 'middle',
  },
  large: {
    width: 64,
    height: 64,
    classModifier: 'large',
  },
  extermelyLarge: {
    width: 72,
    height: 72,
    classModifier: 'extermely-large',
  },
};

export const ANIMATED_REACTIONS_CATEGORY = {
  order: -1,
  category: 'animated-reaction',
  category_label: REACTION_ANIMATED_REACTIONS,
};

export const ANIMATED_REACTION_EMOJI_PLACEHOLDER = [
  SELECTED_EMOJI_REACTION_TYPE.EmojiReactionType_Clap,
  SELECTED_EMOJI_REACTION_TYPE.EmojiReactionType_Thumbsup,
  SELECTED_EMOJI_REACTION_TYPE.EmojiReactionType_Joy,
  SELECTED_EMOJI_REACTION_TYPE.EmojiReactionType_Openmouth,
  SELECTED_EMOJI_REACTION_TYPE.EmojiReactionType_Heart,
  SELECTED_EMOJI_REACTION_TYPE.EmojiReactionType_Tada,
].map((meta) => {
  return {
    meta,
    src: '',
    category: ANIMATED_REACTIONS_CATEGORY,
  };
});

export const FREQUENTLY_USED_EMOJIS_CATEGORY = {
  order: 0,
  category: 'frequently',
  category_label: REACTION_FREQUENTLY_USED,
};

export const REACTION_CATEGORY_MAP = {
  people: {
    order: 1,
    category: 'people',
    category_label: REACTION_CATEGORY_LABEL_PEOPLE,
  },
  nature: {
    order: 2,
    category: 'nature',
    category_label: REACTION_CATEGORY_LABEL_NATURE,
  },
  food: {
    order: 3,
    category: 'food',
    category_label: REACTION_CATEGORY_LABEL_FOOD,
  },
  activity: {
    order: 4,
    category: 'activity',
    category_label: REACTION_CATEGORY_LABEL_ACTIVITY,
  },
  travel: {
    order: 5,
    category: 'travel',
    category_label: REACTION_CATEGORY_LABEL_TRAVEL,
  },
  objects: {
    order: 6,
    category: 'objects',
    category_label: REACTION_CATEGORY_LABEL_OBJECTS,
  },
  symbols: {
    order: 7,
    category: 'symbols',
    category_label: REACTION_CATEGORY_LABEL_SYMBOLS,
  },
  flags: {
    order: 8,
    category: 'flags',
    category_label: REACTION_CATEGORY_LABEL_FLAGS,
  },
};

export const WEBINAR_REACTION_DISPLAY_MODE = {
  FULL: 0,
  MEDIUM: 1,
  HIDDEN: 2,
};

export const REACTION_SEND_OPTION = {
  DEFAULT: 0,
  CAN_MERGE: 1,
  CAN_DROP: 2,
  CAN_MERGE_AND_DROP: 1 | 2,
};

// rwg will help to handle this.
// export const WEBINAR_REACTION_END_FLAG = '\u0000';

// No force restrict. may be a bit more than the exact limit number after ceil.
export const CLIP_MAX_SIZE = {
  FULL_MODE: 200,
  COMPACT_MODE: 40,
};

export const CLIP_MAX_TIME_TO_FINISH = 3000; // mile second
export const DEFAULT_EMOJI_SHOT_INTERVAL = 100;
export const DEFAULT_EMOJI_SHOT_INTERVAL_COMPACT_MODE = 500;
export const MIN_EMOJI_SHOT_INTERVAL = 40;
export const MIN_EMOJI_SHOT_INTERVAL_COMPACT_MODE = 150;
export const UPDATE_WEBINAR_REACTION_INTERVAL = 500;

export const TAIWAN_FLAG_CODE_POINT = '1f1f9-1f1fc';
export const defaultResourcePath = 'https://static-st1.zoomdev.us/fe-static/';
export const categoryDiversity = 'diversity';
export const categorySprite2xSizeMap = {
  people: '12174px 24px',
  diversity: '1074px 1049px',
  nature: '5124px 24px',
  food: '3174px 24px',
  activity: '2974px 24px',
  travel: '3299px 24px',
  objects: '5574px 24px',
  symbols: '7749px 24px',
  flags: '6749px 24px',
};
