import meetingConfig from 'meetingConfig';
import { isWebinar } from '../service/meeting-types';
import { globalVariable } from '../global-variable';

export const getReduxPromise = () => {
  return Promise.resolve().then(() => {
    const { getState, dispatch, reduxStore } = globalVariable.wcCTX();
    if (getState && dispatch && reduxStore) {
      return { getState, dispatch, reduxStore };
    } else {
      return Promise.reject();
    }
  });
};

export const getRedux = (cb) => {
  const { getState, dispatch } = globalVariable.wcCTX();
  if (getState && dispatch) {
    if (cb && typeof cb === 'function') {
      return cb({ getState, dispatch });
    }
    return { getState, dispatch };
  } else {
    return null;
  }
};
export const getMeetingId = () => {
  return meetingConfig.mid ?? 'unknown';
};
export const getProductType = () => {
  return isWebinar() ? 'webinar' : 'meeting';
};
