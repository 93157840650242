import { POLLING_APAC } from './resource';

export const PollingStatus = {
  ZMPollUIStatus_No_Poll: 0,

  ZMPollUIStatus_Not_Start: 1, // default status.

  ZMPollUIStatus_Starting_Poll: 2, // sent “start” to web server, wait for it response.

  ZMPollUIStatus_In_Progress: 3, // server respond, host change to this status.

  ZMPollUIStatus_Doing_Poll: 4, // attendee receive “start” from xmpp, change to this status.

  ZMPollUIStatus_Submitting_Poll: 5, // sent “submit” to web server, wait for it response.

  ZMPollUIStatus_Submitted_Poll: 6, // web server response submit ok.

  ZMPollUIStatus_Starting_Share: 7, // sent “share” to web server, wait for it response.

  ZMPollUIStatus_Sharing_Results: 8, // host share action ok.

  ZMPollUIStatus_Viewing_Results: 9, // attendee view share results.

  ZMPollUIStatus_Stopping_Poll: 10, // host sent ”close”, Next status is ZMPollUIStatus_Closed

  ZMPollUIStatus_Stopping_Share: 11, // host sent “stopshare”, Next status is ZMPollUIStatus_Closed.

  ZMPollUIStatus_Closed: 12,

  ZMPollUIStatus_Relaunching_Poll: 13, // host send “reopen”,the same as starting_poll.
};

export const PollItemState = {
  ZoomPollState_NotStart_unLaunch: 0, // unLaunch // preview

  ZoomPollState_Open_launching: 1, // launching

  ZoomPollState_Closed_launched: 2, // launched

  ZoomPollState_ShareResult_sharing: 3, // sharing
};

export const QuestionTypeString = {
  0: 'single',
  1: 'multiple',
};

export const QuestionTypeCode = {
  single: 0,
  multiple: 1,
  matching: 2,
  rankOrder: 3,
  shortAnswer: 4,
  longAnswer: 5,
  fillInTheBlank: 6,
  ratingScale: 7,
  singleDropdown: 8,
};

export const QuestionTypeName = {
  [QuestionTypeCode.single]: POLLING_APAC.SingleChoice,
  [QuestionTypeCode.multiple]: POLLING_APAC.MultipleChoice,
  [QuestionTypeCode.matching]: POLLING_APAC.Matching,
  [QuestionTypeCode.rankOrder]: POLLING_APAC.RankOrder,
  [QuestionTypeCode.shortAnswer]: POLLING_APAC.ShortAnswer,
  [QuestionTypeCode.longAnswer]: POLLING_APAC.LongAnswer,
  [QuestionTypeCode.fillInTheBlank]: POLLING_APAC.FillInTheBlank,
  [QuestionTypeCode.ratingScale]: POLLING_APAC.RatingScale,
  [QuestionTypeCode.singleDropdown]: POLLING_APAC.SingleChoice, // same with Single Choice
};

export const PollingActionTypes = {
  start: 'start',
  close: 'close',
  reopen: 'reopen',
  stopShareResult: 'stopshare',
  startShareResult: 'share',
  submit: 'submit',
};

export const PollingTypes = {
  ZoomPollType_Unknown: 0,
  ZoomPollType_Basic: 1,
  ZoomPollType_Advanced: 2,
  ZoomPollType_Quiz: 3,
};

export const columnsColors = [
  '#0E72EC',
  '#272AE4',
  '#DE793B',
  '#00A3B8',
  '#E8CF4F',
  '#FF3B38',
  '#B06CF8',
  '#6692F5',
  '#F59B5C',
  '#D75F80',
  '#C967BF',
  '#464081',
  '#F77E79',
  '#025057',
  '#0784D7',
  '#7C5305',
];

export const POLL_URL_TYPE = {
  LIST: 0,
  CREATE: 1,
  EDIT: 2,
  DETAIL: 3,
};

export const POLL_API = {
  DELETE: '/client/rest/poll/delete_poll',
  DUPLICATE: '/client/rest/poll/duplicate_poll',
  STAR: '/client/rest/poll/star_poll',
  UNSTAR: '/client/rest/poll/unstar_poll',
};

export const POLL_MESSAGE_TYPE = 'polling';

export const POLL_MESSAGE_FUNC_NAME = {
  GO_BACK_CLIENT: 'goBackClient',
  GET_CLIENT_BASIC_INFO: 'getClientBasicInfo',
};

export const BACK_TO_CLIENT_ACTION = {
  CANCEL: 'cancel',
  ADD: 'add',
  EDIT: 'edit',
};

export const MODULE_FROM = {
  LIST: 'list',
  DETAIL: 'detail',
};

export const FILTER_POLL_TYPES = {
  ALL: 'All Types',
  POLLS: 'Polls',
  QUIZZES: 'Quizzes',
  STARRED: 'Starred',
};

export const IFRAME_LOAD_STATUS = {
  LOADING: 0,
  ERROR: 1,
  LOADED: 2,
};

export const POLL_LIST_TABS = {
  ALL: 'All',
  STARRED: 'Starred',
};

export const POLL_LIST_MAX_LENGTH = 50;
