import { iText } from '../../global/util';

export const youText = iText('(You)', 'apac.wc_you');
export const hostText = iText('Host', 'apac.wc_host_1');
export const invitationURLText = iText('Invite Link', 'apac.wc_invite_link');
export const copyURLText = iText('Copy Link', 'apac.wc_copy_link');
export const participantIDText = iText(
  'Participant ID',
  'apac.wc_participant_id',
);
export const copiedText = iText(
  'Copied to Clipboard',
  'apac.wc_copied_to_clipboard',
);
export const dataCenterText = (network, dc) => {
  return iText(
    `You are connected to the ${network} via a data center in ${dc}.`,
    'apac.wc_data_center4',
    [network, dc],
  );
};
export const dataCenterText2 = iText(
  'You are connected to an on-premise data center.',
  'apac.wc_data_center2',
);
export const dataCenterText3 = iText(
  'You are connected to the Zoom global network via an on-premise data center.',
  'apac.wc_data_center3',
);
export const backText = iText('Back', 'apac.polling.Back');

export const encryptionText = iText('Encryption', 'apac.wc_encryption');
export const enableText = iText('Enabled', 'apac.wc_enable');
export const exceptionText1 = iText('Exception', 'apac.wc_exception');
export const exceptionText2 = iText('Exceptions', 'apac.wc_exceptions');
export const unencryptedConnectionsText = iText(
  'Unencrypted Connections',
  'apac.wc_unencrypted_connections',
);
export const liveStreamText = iText('Livestream', 'apac.wc_livestream');
const exceptionExpressionText1 = (userName) =>
  iText(`${userName}'s audio`, 'apac.wc_exceptions_expression1', [userName]);
const exceptionExpressionText2 = (userName) =>
  iText(`${userName}'s video`, 'apac.wc_exceptions_expression2', [userName]);
const exceptionExpressionText3 = (userName) =>
  iText(`${userName}'s screen share`, 'apac.wc_exceptions_expression3', [
    userName,
  ]);
const exceptionExpressionText12 = (userName) =>
  iText(`${userName}'s audio and video`, 'apac.wc_exceptions_expression12', [
    userName,
  ]);
const exceptionExpressionText13 = (userName) =>
  iText(
    `${userName}'s audio and screen share`,
    'apac.wc_exceptions_expression13',
    [userName],
  );
const exceptionExpressionText23 = (userName) =>
  iText(
    `${userName}'s video and screen share`,
    'apac.wc_exceptions_expression23',
    [userName],
  );
const exceptionExpressionText123 = (userName) =>
  iText(
    `${userName}'s audio,video and screen share`,
    'apac.wc_exceptions_expression123',
    [userName],
  );
export const userExceptionExpression = {
  exceptionExpressionText1,
  exceptionExpressionText2,
  exceptionExpressionText3,
  exceptionExpressionText12,
  exceptionExpressionText13,
  exceptionExpressionText23,
  exceptionExpressionText123,
};
export const meetingIDText = iText('Meeting ID', 'apac.wc_meetingid');
export const phoneUserCountExpression1 = (count) =>
  iText(`${count} phone participant`, 'apac.wc_phone_meeting_count1', [count]);
export const phoneUserCountExpression2 = (count) =>
  iText(`${count} phone participants`, 'apac.wc_phone_meeting_count2', [count]);
export const phoneUserCountExpression3 = (count) =>
  iText(`${count} phone panelist`, 'apac.wc_phone_webinar_count1', [count]);
export const phoneUserCountExpression4 = (count) =>
  iText(`${count} phone panelists`, 'apac.wc_phone_webinar_count2', [count]);
export const phoneUserCountExpression5 = (count) =>
  iText(`${count} phone attendee`, 'apac.wc_phone_webinar_count3', [count]);
export const phoneUserCountExpression6 = (count) =>
  iText(`${count} phone attendees`, 'apac.wc_phone_webinar_count4', [count]);
export const phoneUserCountExpression7 = (count1, count2) =>
  iText(
    `${count1} phone panelist and ${count2} phone attendee`,
    'apac.wc_phone_webinar_count5',
    [count1, count2],
  );
export const phoneUserCountExpression8 = (count1, count2) =>
  iText(
    `${count1} phone panelists and ${count2} phone attendee`,
    'apac.wc_phone_webinar_count6',
    [count1, count2],
  );
export const phoneUserCountExpression9 = (count1, count2) =>
  iText(
    `${count1} phone panelist and ${count2} phone attendees`,
    'apac.wc_phone_webinar_count7',
    [count1, count2],
  );
export const phoneUserCountExpression10 = (count1, count2) =>
  iText(
    `${count1} phone panelists and ${count2} phone attendees`,
    'apac.wc_phone_webinar_count8',
    [count1, count2],
  );
export const RECORDING = iText('Recording', 'apac.wc_recording');
export const RECORDING_PAUSED = iText(
  'Recording Paused',
  'apac.wc_recording_paused',
);
export const TIME_REMAINING_TEXT = iText(
  'Remaining',
  'apac.wc_free_meeting_remaining_time',
);
export const eventPageURLText = iText('Event Page', 'apac.wc_event_page_url');
export const NDI_TIP = iText(
  'This meeting is streaming live on NDI\u00ae',
  'apac.ndi.tip',
);
export const LOW_NETWORK_BANDWIDTH_NOTIFICATION = iText(
  'Low Network Bandwidth',
  'apac.low_network_bandwidth_notification',
);
export const UNSTABLE_NETWORK_CONNECTION_NOTIFICATION = iText(
  'Unstable Network Connection',
  'apac.unstable_network_connection_notification',
);
