/* eslint-disable @babel/new-cap */
import meetingConfig from 'meetingConfig';
import {
  OpFeatureUtility,
  FEATUTRE_FLAG as COMMON_FEATUTRE_FLAG,
} from '@zoom/common-utils';

export const FEATUTRE_FLAG = COMMON_FEATUTRE_FLAG;

export const BIT_AND_LOGIC = (opBit, bit) => (opBit & bit) === bit;

export const opFeatureUtility = new OpFeatureUtility(
  meetingConfig.webclientFeatureOptions || '',
);

const getOpFeatureOption = (opBit) =>
  opFeatureUtility.getOpFeatureFlag(opBit, 1, (opBit) =>
    BIT_AND_LOGIC(opBit, 0b1),
  );

const isAppleMobileDevice = () => {
  // ipad iphone and safari on ipad os
  return (
    /iPad|iPhone|iPod/i.test(navigator.userAgent) ||
    (/Macintosh/i.test(navigator.userAgent) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2)
  );
};
const isTeslaMode = () => {
  return /TESLA/.test(navigator.userAgent);
};
export const isAndroidDevice = () => navigator.userAgent.match(/Android/i);
export const isMTRAndroid = () => {
  return /MSFT Teams Android Room/.test(navigator.userAgent);
};
/** if you can use audioBridge */

const bizAllowAudioBridge = () =>
  !meetingConfig.meetingInterpretationInfo?.isEnableLanguageInterpretation &&
  !meetingConfig.signInterpreterInfo?.isEnable;

// if the case is out of control by meetingConfig.webclientFeatureOptions, check ifBusinessAllowAudioBridge
export const isAudioBridge = () => {
  if (!meetingConfig.webclientFeatureOptions) {
    return false;
  }
  const bits = parseInt(meetingConfig.webclientFeatureOptions.slice(-2), 16);
  if (easyStore.easyGet('audioBridgeNotWork_shouldFailOverToWASM')) {
    return false;
  } else if (isMTRAndroid()) {
    return (1 << 3) & bits;
  } else if (isTeslaMode()) {
    return (1 << 1) & bits;
  } else if (isAndroidDevice()) {
    return (1 << 2) & bits;
  } else if (isAppleMobileDevice()) {
    return (1 << 0) & bits;
  } else if (
    bizAllowAudioBridge() &&
    !!JsMediaSDK_Instance?.util?.isSuggestAudioBridge?.()
  ) {
    return (1 << 5) & bits;
  }

  return false;
};

const op360PHwEncodeFlag = opFeatureUtility.getOpFeatureFlag(
  3,
  1,
  // eslint-disable-next-line @babel/new-cap
  (opBit) => BIT_AND_LOGIC(opBit, 0b1),
);
export const isOpOpen1080PVideoDecodeFlag = () => {
  const op1080PVideoDecodeFlag = opFeatureUtility.getOpFeatureFlag(
    6,
    1,
    // eslint-disable-next-line @babel/new-cap
    (opBit) => BIT_AND_LOGIC(opBit, 0b1),
  );
  return op1080PVideoDecodeFlag;
};

export const isOpOpen720PVideoDecodeFlag = () => {
  const op720PVideoDecodeFlag = opFeatureUtility.getOpFeatureFlag(
    5,
    1,
    // eslint-disable-next-line @babel/new-cap
    (opBit) => BIT_AND_LOGIC(opBit, 0b1),
  );
  return op720PVideoDecodeFlag;
};

export const is360penablehw =
  op360PHwEncodeFlag === FEATUTRE_FLAG.FEATUTRE_FLAG_ENABLE;

const opReplaceCanvasWhenWebglContextLostFlag =
  opFeatureUtility.getOpFeatureFlag(
    4,
    1,
    // eslint-disable-next-line @babel/new-cap
    (opBit) => BIT_AND_LOGIC(opBit, 0b1),
  );
/** op feature flag: replace canvas when webgl context lost */
export const replaceCanvasWhenWebglContextLost =
  opReplaceCanvasWhenWebglContextLostFlag ===
  FEATUTRE_FLAG.FEATUTRE_FLAG_ENABLE;

export const isOpOpenGoogleMeetMultiViewFlag = () => {
  const opOpenGoogleMeetMultiViewFlag = opFeatureUtility.getOpFeatureFlag(
    7,
    1,
    // eslint-disable-next-line @babel/new-cap
    (opBit) => BIT_AND_LOGIC(opBit, 0b1),
  );
  return opOpenGoogleMeetMultiViewFlag;
};

export const isEnableZoomAppFlag = () => {
  return opFeatureUtility.getOpFeatureFlag(
    9,
    1,
    // eslint-disable-next-line @babel/new-cap
    (opBit) => BIT_AND_LOGIC(opBit, 0b1),
  );
};

export const isEnableDecoderWorkletFlag = () => {
  const opEnableDecoderWorkletFlag = opFeatureUtility.getOpFeatureFlag(
    10,
    1,
    // eslint-disable-next-line @babel/new-cap
    (opBit) => BIT_AND_LOGIC(opBit, 0b1),
  );
  return opEnableDecoderWorkletFlag;
};

export const isEnableAudioDenoiseFlag = () => {
  return opFeatureUtility.getOpFeatureFlag(
    11,
    1,
    // eslint-disable-next-line @babel/new-cap
    (opBit) => BIT_AND_LOGIC(opBit, 0b1),
  );
};

export const isEnableVBForFirefox = () => {
  return opFeatureUtility.getOpFeatureFlag(12, 1, (opBit) =>
    // eslint-disable-next-line @babel/new-cap
    BIT_AND_LOGIC(opBit, 0b1),
  );
};

export const isEnableZoomRemoteControl = () => {
  return opFeatureUtility.getOpFeatureFlag(13, 1, (opBit) =>
    // eslint-disable-next-line @babel/new-cap
    BIT_AND_LOGIC(opBit, 0b1),
  );
};

export const isSwitchToOneChatList = () => {
  return opFeatureUtility.getOpFeatureFlag(18, 1, (opBit) =>
    // eslint-disable-next-line @babel/new-cap
    BIT_AND_LOGIC(opBit, 0b1),
  );
};

export const isEnableOriginalSound = () => {
  return opFeatureUtility.getOpFeatureFlag(22, 1, (opBit) =>
    BIT_AND_LOGIC(opBit, 0b1),
  );
};

export const isUseUnifiedRender = () => {
  return opFeatureUtility.getOpFeatureFlag(20, 1, (opBit) =>
    // eslint-disable-next-line @babel/new-cap
    BIT_AND_LOGIC(opBit, 0b1),
  );
};

export const isEnableJMFLog = () => {
  return opFeatureUtility.getOpFeatureFlag(23, 1, (opBit) =>
    // eslint-disable-next-line @babel/new-cap
    BIT_AND_LOGIC(opBit, 0b1),
  );
};

export const isEnableVideo1080PSendFeature = () => {
  return opFeatureUtility.getOpFeatureFlag(24, 1, (opBit) =>
    // eslint-disable-next-line @babel/new-cap
    BIT_AND_LOGIC(opBit, 0b1),
  );
};

export const isEnableVideoShare1080PSendFeature = () => {
  return opFeatureUtility.getOpFeatureFlag(25, 1, (opBit) =>
    // eslint-disable-next-line @babel/new-cap
    BIT_AND_LOGIC(opBit, 0b1),
  );
};

export const isEnableUseZMKFromConfJ = () => getOpFeatureOption(26);

export const isEnableWebgpuRender = () => getOpFeatureOption(21);

export const isEnableWebgl2Render = () => getOpFeatureOption(29);

export const isEnablePictureInPictureFeature = () => {
  return opFeatureUtility.getOpFeatureFlag(28, 1, (opBit) =>
    // eslint-disable-next-line @babel/new-cap
    BIT_AND_LOGIC(opBit, 0b1),
  );
};

export const isEnableRWGCommandBypassToWCL = () => getOpFeatureOption(30);

export const isEnableMultiSpeaker = () => getOpFeatureOption(32);

export const isEnableWaitingRoomJoinWithoutFailoverFlow = () =>
  getOpFeatureOption(33);

export const isEnableAnnotation = () => getOpFeatureOption(40);
