export const WC_ERRORS = {
  NO_RWG_INSTANCE: {
    code: 1,
    getMessage: ({ name }) =>
      `there is no ${name} instance, please connect() first`,
  },
};

export const buildWCErrorProps = (errorType, props) => {
  return {
    code: errorType.code,
    message: errorType.message || errorType.getMessage(props),
  };
};

export class WCSocketError extends Error {
  constructor(props) {
    const { message, code } = props;
    super(props);
    this.message = message;
    this.code = code;
  }
}
