import React, { useEffect } from 'react';
import { iText } from '../../../global/util';

const RESOURE_LOAD_ERROR = iText(
  'Resource Load Error',
  'apac.wc_resource_load_error',
);
const RESOURE_LOAD_EROOR_BTN = iText(
  'Refresh',
  'apac.wc_resource_load_error_btn',
);
const RESOURE_LOAD_ERROR_ACTIO = iText(
  'Some resource files failed to load, please try refresh this page to rejoin the meeting',
  'apac.wc_resource_load_error_action',
);

const LoadResourceErrorPage = () => {
  useEffect(() => {
    const loadingNode = document.querySelector('#wc-loading');
    if (loadingNode.parentNode) {
      loadingNode.parentNode.removeChild(loadingNode);
    }
  }, []);

  const style = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#fff',
    background: '#222',
  };

  return (
    <div style={style}>
      <h1>{RESOURE_LOAD_ERROR}</h1>
      <p style={{ marginTop: '24px' }}>{RESOURE_LOAD_ERROR_ACTIO}</p>
      <button
        onClick={() => {
          window.location.reload();
        }}
        aria-label="Refresh"
        type="primary"
        style={{
          marginTop: '48px',
          border: 'none',
          width: '140px',
          backgroundColor: '#0E71EB',
          color: '#fff',
          borderRadius: '8px',
          fontSize: '16px',
          fontWeight: '700',
          height: '32',
        }}
      >
        {RESOURE_LOAD_EROOR_BTN}
      </button>
    </div>
  );
};

export default LoadResourceErrorPage;
