import { SESSIONSTORAGE_KEYS } from '../constant';
import { easyStore, storeType } from '../easy-store';

export function getAVStatus() {
  return easyStore.easyGet(SESSIONSTORAGE_KEYS.avStatus) ?? {};
}

export function setAVStatus(obj) {
  const avStatus = getAVStatus();
  easyStore.easySet(
    SESSIONSTORAGE_KEYS.avStatus,
    { ...avStatus, ...obj },
    storeType.sessionStorage,
  );
}
