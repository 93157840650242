export const saveJsonParse = (v, defaultValue) => {
  try {
    return JSON.parse(v);
  } catch (e) {
    return defaultValue ?? v;
  }
};

export const safeJsonStringify = (v) => {
  try {
    return JSON.stringify(v);
  } catch (e) {
    return v;
  }
};

export const safeJsonStringifyWithNoNest = (obj) => {
  if (typeof obj !== 'object') {
    return obj;
  }
  const firstLevel = {};
  for (let key in obj) {
    if (
      typeof obj[key] !== 'object' ||
      obj[key] === null ||
      key !== 'waterMarkText'
    ) {
      firstLevel[key] = obj[key];
    }
  }
  return safeJsonStringify(firstLevel);
};
