import meetingConfig from 'meetingConfig';
import { getMeetingTokens } from './meetingToken';
import axios from 'axios';
import qs from 'qs';
import { makeLogger } from '../logger';
import { getJoinMeetingLog } from '../util';

const logger = makeLogger(['Join Meeting Flow']);

/*
 * currently, it fetch
 * 1. the DLP
 * 2. vbList
 * 3. limit share
 * 4. user pronoun
 * 5. restrict features
 * 6. web login user's userId
 */
const doFetchProfile = () => {
  const { isLogin, baseUrl } = meetingConfig;
  const tokens = getMeetingTokens();
  const { zak } = tokens;
  return new Promise((resolve, reject) => {
    if (!isLogin) {
      logger.log(getJoinMeetingLog('fetch profile'), ['FETCH_PROFILE_UNLOGIN']);
      return resolve();
    }
    const url = `${baseUrl}/wc/profile/get`;
    return axios.post(url, qs.stringify({ zak })).then(({ data }) => {
      if (data.result) {
        logger.log(getJoinMeetingLog('fetch profile'), [
          'FETCH_PROFILE_SUCCESS',
        ]);
        resolve(data.result);
      } else {
        logger.log(
          getJoinMeetingLog(
            `fetch profile error code: ${data.errorCode}, message: ${data.errorMessage}`,
          ),
          ['FETCH_PROFILE_FAILED'],
        );
        reject('empty profile!');
      }
    });
  });
};

let promise = null;
export const fetchProfile = () => {
  if (!promise) {
    promise = doFetchProfile();
  }
  return promise;
};
