import { PARTICIPANT_ICON_NAME } from '../participants/constant';
import { NONVERBAL_TYPE } from '../reaction/constant';
export const ROOMS_DISTRIBUTION_PATTERN = {
  auto: 1,
  manually: 2,
  selfSelect: 3,
};

export const ROOMS_SIZE = {
  min: 1,
  max: 50,
  hugeBoMax: 100,
  maxLength: 2,
  hugeBoMaxLength: 3,
};

export const DEFAULT_ROOM_LOCAL_ID = 1;

export const ROOM_NAME_MAX_LENGTH = 32;
export const BROADCAST_MESSAGE_MAX_LENGTH = 500;

export const CONTROL_STATUS = Object.freeze({
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  CLOSING: 3,
  CLOSED: 4,
});

export const ATTENDEE_STATUS = Object.freeze({
  INITIAL: 'initial',
  BE_INVITED: 'be invited',
  JOINING: 'joining',
  IN_ROOM: 'in room',
  RETURNING: 'returning',
  NOT_JOINED: 'not joined',
  TIME_UP: 'time up',
  RETURN_MAIN_SESSION: 'return main session',
});

export const BO_ROOM_STATUS = {
  NO_TOKEN: 1,
  GOT_TOKEN: 2,
  STARATED: 3,
  STOPPING: 4,
  ENDED: 5,
};

export const BO_COMMAND_TYPES = {
  JOIN: 'joinRequest',
  SWITCH: 'switchRequest',
  LEAVE: 'leaveRequest',
  BROADCAST: 'messageText',
  HELP_REQ: 'helpRequest',
  HELP_RES: 'helpResponse',
  CO_HOST_WANT_JOIN_THIS_BO: 'wantJoinThisBO',
  BO_ACTIVITY: 'BOActivity',
};

export const BO_HOST_HELP_REQ_TYPES = {
  HAS_RECEIVED: 0,
  BUSY: 1,
  IGNORE: 2,
  ALREADY_IN: 3,
};

export const batchCreateRoomsConstants = {
  renderRoomSize: 25,
  renderRoomSizeEachBatch: 50,
};

export const BO_JOIN_METHODS = {
  NORMAL_JOIN: 0,
  FORCE_JOIN: 1,
};

export const BO_JOIN_REASONS = {
  JOIN: 0,
  START: 1,
};

export const BO_ROOM_CREATE_WAY_WHILE_START = {
  CREATE_BO_BY_NAME_WHILE_START: 1,
  CREATE_BO_BY_INDEX_WHILE_START: 2,
};

export const SAVE_BO_ERROR_TYPES = {
  MAX_LIMIT: 3185,
  NAME_EXIST: 3183,
};

export const BO_ATTENDEE_STATUS_SVG_PROPS = {
  width: 16,
  height: 16,
  viewBox: '0 0 16 16',
};

const BO_SKIN_TONE_FLAG_BASE = {
  0: 0x00000800,
  1: 0x00001000,
  2: 0x00002000,
};
const BO_NONVERBAL_REACTION_FLAG_BASE = {
  0: 0x00000080,
  1: 0x00000100,
  2: 0x00000200,
  3: 0x00000400,
};
const BO_AUDIO_FLAG_BASE = {
  CONNECT: 0x00000001,
  MUTED: 0x00000002,
  VOIP: 0x00000004,
};
const BO_VIDEO_FLAG_BASE = {
  CONNECT: 0x00000008,
  ON: 0x00000010,
};
const BO_SHARE_FLAG_BASE = {
  SHARE: 0x00000020,
  SHARE_AUDIO: 0x00000040,
};
const checkZeroFlag = (all_base) => (flag) => {
  return Object.values(all_base).reduce((prev, current) => {
    return prev && !(flag & current);
  }, true);
};
const makeFlagChecker = (base) => (flag) => {
  return base === (base & flag);
};
export const NONVERBAL_STATUS = {
  NONVERBAL_NONE: {
    key: 'NONVERBAL_NONE',
    checker: checkZeroFlag(BO_NONVERBAL_REACTION_FLAG_BASE),
    type: NONVERBAL_TYPE.NONVERBAL_NONE,
  },
  NONVERBAL_HAND: {
    key: 'NONVERBAL_HAND',
    checker: makeFlagChecker(BO_NONVERBAL_REACTION_FLAG_BASE[0]), // 0001
    type: NONVERBAL_TYPE.NONVERBAL_HAND,
  },
  NONVERBAL_YES: {
    key: 'NONVERBAL_YES',
    checker: makeFlagChecker(BO_NONVERBAL_REACTION_FLAG_BASE[1]), // 0010
    type: NONVERBAL_TYPE.NONVERBAL_YES,
  },
  NONVERBAL_NO: {
    key: 'NONVERBAL_NO',
    checker: makeFlagChecker(
      BO_NONVERBAL_REACTION_FLAG_BASE[1] | BO_NONVERBAL_REACTION_FLAG_BASE[0], // 0011
    ),
    type: NONVERBAL_TYPE.NONVERBAL_NO,
  },
  NONVERBAL_FAST: {
    key: 'NONVERBAL_FAST',
    checker: makeFlagChecker(BO_NONVERBAL_REACTION_FLAG_BASE[2]), // 0100
    type: NONVERBAL_TYPE.NONVERBAL_FAST,
  },
  NONVERBAL_SLOW: {
    key: 'NONVERBAL_SLOW',
    checker: makeFlagChecker(
      BO_NONVERBAL_REACTION_FLAG_BASE[2] | BO_NONVERBAL_REACTION_FLAG_BASE[0], // 0101
    ),
    type: NONVERBAL_TYPE.NONVERBAL_SLOW,
  },
  NONVERBAL_COFFEE: {
    key: 'NONVERBAL_COFFEE',
    checker: makeFlagChecker(
      BO_NONVERBAL_REACTION_FLAG_BASE[3] | BO_NONVERBAL_REACTION_FLAG_BASE[0], // 1001
    ),
    type: NONVERBAL_TYPE.NONVERBAL_COFFEE,
  },
};

export const SKIN_TONE_STATUS = {
  UNKNOWN: {
    key: 'UNKNOWN',
    checker: checkZeroFlag(BO_SKIN_TONE_FLAG_BASE),
    type: 0,
  },
  DEFAULT: {
    key: 'DEFAULT',
    checker: makeFlagChecker(BO_SKIN_TONE_FLAG_BASE[0]),
    type: 1,
  }, // 001
  LIGHT: {
    key: 'LIGHT',
    checker: makeFlagChecker(BO_SKIN_TONE_FLAG_BASE[1]),
    type: 2,
  }, // 010
  MEDIUM_LIGHT: {
    key: 'MEDIUM_LIGHT',
    checker: makeFlagChecker(
      BO_SKIN_TONE_FLAG_BASE[1] | BO_SKIN_TONE_FLAG_BASE[0],
    ),
    type: 3,
  }, // 011
  MEDIUM: {
    key: 'MEDIUM',
    checker: makeFlagChecker(BO_SKIN_TONE_FLAG_BASE[2]),
    type: 4,
  }, // 100,
  MEDIUM_DARK: {
    key: 'MEDIUM_DARK',
    checker: makeFlagChecker(
      BO_SKIN_TONE_FLAG_BASE[2] | BO_SKIN_TONE_FLAG_BASE[0],
    ),
    type: 5,
  }, // 101,
  DARK: {
    key: 'DARK',
    checker: makeFlagChecker(
      BO_SKIN_TONE_FLAG_BASE[2] | BO_SKIN_TONE_FLAG_BASE[1],
    ),
    type: 6,
  }, // 110
};

export const AUDIO_STATUS = {
  CONNECT: {
    key: 'CONNECT',
    checker: makeFlagChecker(BO_AUDIO_FLAG_BASE.CONNECT),
    iconName: PARTICIPANT_ICON_NAME.PHONE_UNMUTED,
    activeIconName: PARTICIPANT_ICON_NAME.PHONE_SPEAKING,
    mutedIconName: PARTICIPANT_ICON_NAME.PHONE_MUTED,
  },
  VOIP: {
    key: 'VOIP',
    checker: makeFlagChecker(BO_AUDIO_FLAG_BASE.VOIP),
    iconName: PARTICIPANT_ICON_NAME.AUDIO_UNMUTED,
    activeIconName: PARTICIPANT_ICON_NAME.AUDIO_SPEAKING,
    mutedIconName: PARTICIPANT_ICON_NAME.AUDIO_MUTED,
  },
};
export const AUDIO_MUTED_STATUS = {
  MUTED: {
    key: 'MUTED',
    checker: makeFlagChecker(BO_AUDIO_FLAG_BASE.MUTED),
    iconName: PARTICIPANT_ICON_NAME.AUDIO_MUTED,
  },
};
export const VIDEO_STATUS = {
  CONNECT: {
    key: 'CONNECT',
    checker: makeFlagChecker(BO_VIDEO_FLAG_BASE.CONNECT),
    iconName: PARTICIPANT_ICON_NAME.VIDEO_OFF,
  },
  ON: {
    key: 'ON',
    checker: makeFlagChecker(BO_VIDEO_FLAG_BASE.ON),
    iconName: PARTICIPANT_ICON_NAME.VIDEO_ON,
  },
};
export const SHARE_STATUS = {
  SHARE: {
    key: 'SHARE',
    checker: makeFlagChecker(BO_SHARE_FLAG_BASE.SHARE),
    iconName: PARTICIPANT_ICON_NAME.SHARING_SCREEN,
  },
};
export const SHARE_AUDIO_STATUS = {
  SHARE_AUDIO: {
    key: 'SHARE_AUDIO', // We can get this flag. But it's not in use now.
    checker: makeFlagChecker(BO_SHARE_FLAG_BASE.SHARE_AUDIO),
  },
};
