import meetingConfig from 'meetingConfig';
import {
  CONTROL_MODE_CONNECTORS_ENUM,
  AUTO_MUTE_STATUS,
  AUTO_CONNECT_STATUS,
  PREVIEW_OPTIONS,
} from './enum';
import {
  inIframe,
  queryBit,
  isMSFTMode,
  isTeslaMode,
  isExternalControlledMode,
  isSafari,
  isGoogleMeetMode,
} from '../global/util';
import { isNewJoinFlowEnabled, isOnZoomMeeting } from '../global/service';
import { PWAExist } from '../global/pwa-integration';
import { SESSIONSTORAGE_KEYS } from '../global/constant';
import qs from 'qs';
import {
  getPreviewOptions,
  isViewOnlyInPreview,
} from '../features/preview/service';
import { isViewOnly } from '../global/service/user-types';

// if 'isInvite' in url params is consistent with 'isInvited' in pwa message, PO is secure.
// else PO is not secure, audio and video should turn off.
const isPOSecure = () => {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isInvitedInParams = params.invite;
  const isInvitedInPwa = easyStore.easyGet(SESSIONSTORAGE_KEYS.isInvited) ?? 0;
  if (isInvitedInParams !== '1') {
    return true;
  }
  return Boolean(isInvitedInPwa);
};

// not for platform
export const isPreviewAudioOn = () => {
  /* eslint-disable-next-line @babel/new-cap */
  if (inIframe() && !PWAExist()) {
    return false;
  }

  if (isOnZoomMeeting()) {
    return true;
  }

  if (!isPOSecure()) {
    return false;
  }
  // @see ZOOM-412611
  if (isSafari()) {
    return false;
  }
  // for viewonly attendee, preview video & audio was skipped.
  // in this case always auto join audio when joining meeting.
  if (isViewOnlyInPreview()) {
    return true;
  }
  return queryBit(getPreviewOptions(), PREVIEW_OPTIONS.AUTO_JOIN_AUDIO);
};

export const isPreviewAudioUnmuted = () => {
  if (isOnZoomMeeting()) {
    return false;
  }
  return queryBit(getPreviewOptions(), PREVIEW_OPTIONS.AUDIO_ON);
};

// not for platform
export const isPreviewVideoOn = (userRole) => {
  /* eslint-disable-next-line @babel/new-cap */
  if ((inIframe() && !PWAExist()) || isViewOnly(userRole)) {
    return false;
  }

  if (isOnZoomMeeting()) {
    return meetingConfig.isStart
      ? meetingConfig.optionVideoHost
      : meetingConfig.optionVideoParticipants;
  }

  if (!isPOSecure()) {
    return false;
  }
  const turnOff = !queryBit(getPreviewOptions(), PREVIEW_OPTIONS.VIDEO_ON);

  if (meetingConfig.isStart) {
    return turnOff ? false : meetingConfig.optionVideoHost;
  } else {
    return !turnOff;
  }
};

export const isControllerAutoConnectVOIP = () => {
  return (
    meetingConfig.autoConnectVOIP4Partner === AUTO_CONNECT_STATUS.ON_CONNECT
  );
};

export const isControllerAutoUnmuteVOIP = () => {
  return meetingConfig.autoUnmuteVOIP4Partner === AUTO_MUTE_STATUS.ON_UNMUTE;
};

export const isControllerAutoMuteVOIP = () => {
  return meetingConfig.autoUnmuteVOIP4Partner === AUTO_MUTE_STATUS.ON_MUTE;
};

export const isControllerAutoUnmuteVideo = () => {
  return meetingConfig.autoUnmuteVideo4Partner === AUTO_MUTE_STATUS.ON_UNMUTE;
};

// micro soft: true
export function isAutoTurnOnMedia() {
  return !!meetingConfig.autoTurnOnMedia;
}

const match = (type, target) => {
  return type && type === target;
};
export const isAutoTurnOnVideo = (userRole, type) => {
  if (isTeslaMode() && !isNewJoinFlowEnabled()) {
    return isControllerAutoUnmuteVideo();
  }

  if (isMSFTMode() || match(type, CONTROL_MODE_CONNECTORS_ENUM.MSFT)) {
    return (
      meetingConfig.optionVideoParticipants &&
      (isAutoTurnOnMedia() || isControllerAutoUnmuteVideo())
    );
  }

  // normal mode: browsers
  return isPreviewVideoOn(userRole);
};

export const isAutoTurnOnAudio = (type) => {
  if (isTeslaMode()) {
    if (isNewJoinFlowEnabled()) {
      return true;
    } else {
      return (
        isControllerAutoConnectVOIP() || !meetingConfig.autoConnectVOIP4Partner
      );
    }
  }

  if (isMSFTMode() || match(type, CONTROL_MODE_CONNECTORS_ENUM.MSFT)) {
    return isAutoTurnOnMedia() || isControllerAutoConnectVOIP();
  }

  if (
    isGoogleMeetMode() ||
    match(type, CONTROL_MODE_CONNECTORS_ENUM.GOOGLE_MEET)
  ) {
    return isControllerAutoConnectVOIP();
  }

  // normal mode: browsers
  return isPreviewAudioOn();
};

export const isAutoUnmuteAudio = (type) => {
  if (isTeslaMode() && !isNewJoinFlowEnabled()) {
    return isControllerAutoUnmuteVOIP();
  }

  if (isMSFTMode() || match(type, CONTROL_MODE_CONNECTORS_ENUM.MSFT)) {
    return isAutoTurnOnMedia() || isControllerAutoUnmuteVOIP();
  }

  if (
    isGoogleMeetMode() ||
    match(type, CONTROL_MODE_CONNECTORS_ENUM.GOOGLE_MEET)
  ) {
    return isAutoTurnOnMedia() || isControllerAutoUnmuteVOIP();
  }

  // normal mode: browsers
  return isPreviewAudioUnmuted();
};

/**
 * is support preview option setting.
 * @returns bool
 */
export function isSupportPOSetting() {
  if (isExternalControlledMode()) {
    return false;
  }
  if (isOnZoomMeeting()) {
    return false;
  }
  return true;
}

export const isDisableTeslaSendSensitiveWSMessage = () =>
  meetingConfig.meetingOptions.isDisableTeslaSendWSMessage;
