import * as socketEventTypes from '../constants/ZoomSocketEventTypes';

const SHOW_WINDOW_TYPE_LIMIT = 800;

const SOCKET_NAME_ENUM = Object.freeze({
  COMMAND: Symbol('COMMAND'),
  XMPP: Symbol('XMPP'),
  BO: Symbol('BO'),
});

const LOCALSTORAGE_KEYS = {
  isHideChatPersistentTip: 'isHideChatPersistentTip',
  webClient_Bo_HasShowWhereCanJoinRoomTip:
    'WEB-CLIENT_BO_HAS-SHOW-WHERE-CAN-JOIN-ROOM-TIP',
  webClient_SIMD_Flag: 'WEB-CLIENT_SIMD_Flag',
  webClient_first_enter_flag: 'WEB-CLIENT-FIRST-ENTER-FLAG',
  webClient_save_custom_view_flag: 'WEB-CLIENT-SAVE-CUSTOM_VIEW-FLAG',
  webClient_new_reaction_animation_feature_notification_flag:
    'WEB-CLIENT-NEW-REACTION-ANIMATION-FEATURE-NOTIFICATION-FLAG',
  webClient_AutoHideSuspensionEnabled:
    'WEB-CLIENT_AUTO-HIDE-SUSPENSION-ENABLED',
  webClient_meetingUqiueId: 'WEB-CLIENT_MeetingUqiueId',
  webClient_update_browser_to_share_notification_flag:
    'WEB-CLIENT-UPDATE-BROWSER-TO-SHARE-NOTIFICATION-FLAG',
  webClient_is_hide_muted_while_sharing_tips:
    'WEB-CLIENT-IS-HIDE-MUTED-WHILE-SHARING-TIPS',
  webClient_is_hide_muted_while_sharing_tips2:
    'WEB-CLIENT-IS-HIDE-MUTED-WHILE-SHARING-TIPS2',

  webClient_frequentlyUsedEmojis: 'WEB-CLIENT_FREQUENTLY_USED_EMOJIS',
  // for production, not use now.
  webClient_enableHardwareAccelerationForReceivingVideo:
    'WEB-CLIENT-ENABLE-HARDWARE-ACCELERATION-FOR-RECEIVING-VIDEO-NEW',
  webClient_enableHardwareAccelerationForSendingVideo:
    'WEB-CLIENT-ENABLE-HARDWARE-ACCELERATION-FOR-SENDING-VIDEO',
  webClient_enableSeeSelfActiveWhenSpeaking:
    'WEB-CLIENT-ENABLE-SEE-SELF-ACTIVE-WHEN-SPEAKING',
  webClient_enableAutoJoinAudio: 'WEB-CLIENT-ENABLE-AUTO-JOIN-AUDIO',
  webClient_enableMuteAudioWhenJoinMeeting:
    'WEB-CLIENT-ENABLE-MUTE-WHEN-JOIN-MEETING',
  webClient_enableTurnOffVideoWhenJoinMeeting:
    'WEB-CLIENT-ENABLE-TURN-OFF-VIDEO-WHEN-JOIN-MEETING',
  webClient_enableHoldSpaceKeyToUnmute:
    'WEB-CLIENT-ENABLE-HOLD-SPACE-KEY-TO-UNMUTE',
  webClient_enableSyncButtonsOnHeadset:
    'WEB-CLIENT-ENABLE-SYNC-BUTTONS-ON-HEADSET',
  webClient_enableDenoise: 'WEB-CLIENT-DENOISE',
  webClient_enableStereo: 'WEB-CLIENT-STEREO',
  // for dev, manually set it if needed.
  webClient_devHardwareAccelerationFlag:
    'WEB-CLIENT-DEV-HARDWARE-ACCELERATION-FLAG',
  webClient_enableHideNonVideo: 'WEB-CLIENT-ENABLE-HIDE-NON-VIDEO',
  webClient_enableHideSelfView: 'WEB-CLIENT-ENABLE-HIDE-SELF-VIEW',
  webClient_trackingIds: 'WEB-CLIENT-TRACKING-IDS',
  recent_selected_translate: 'RecentSelectedTranslateLang',
  failoverCount: 'failoverCount',
  webClient_is_hide_preassign_rename_tip:
    'webClient_is_hide_preassign_rename_tip',
  webClient_is_not_show_request_host_record_tip_again:
    'webClient_is_not_show_request_host_record_tip_again',
  audio_profile_type: 'audio_profile_type',
  denoise_type: 'denoise_type',
  enable_high_fidelity: 'enable_high_fidelity',
  enable_stereo: 'enable_stereo',
  video_render_method_type: 'video_render_method_type',
};

const SESSIONSTORAGE_KEYS = {
  webClient_opt: 'WEB-CLIENT_OPT_TOKEN',
  // When users refresh the page,we need join the bo again auto
  // or give just a confirm dialog to join;
  // We sholud store the progress of joining the bo meeting.
  // We sholud remove it when the user returned back to the main session.
  // The value looks like "CF7CB9EB-F81C-4350-83B8-6C747CB7BE39;in room;boid;botoken;isHost"
  webClient_Bo_HasJoinBoMeeting: 'WEB-CLIENT_BO_HAS-JOIN-BO-MEETING',
  // key : WEB-CLIENT_BO_HAS-RECEIVE-JOIN-CMD;zoomId
  // value: true
  webClient_Bo_HasReceiveJoinCmd: 'WEB-CLIENT_BO_HAS-RECEIVE-JOIN-CMD',
  webClient_isHost: 'WEB-CLIENT_IS-HOST',
  webClient_BCoHost: 'WEB-CLIENT_B-COHOST',
  webClient_RemovedParticipants: 'WEB-CLIENT_REMOVED-PARTICIPANTS',
  webClient_webinarToken: 'WEB-CLIENT_WEBINAR-TOKEN',
  webClient_meetingToken: 'WEB-CLIENT_MEETING-TOKEN',
  webClient_hasRecordingReminderForHost:
    'WEB-CLIENT_HAS-RECORDING-REMINDER-FOR-HOST',
  webClient_freeMeetingHasAutoUpgraded: 'webClient_freeMeetingHasAutoUpgraded',
  webClient_freeMeetingBeginToConutdown:
    'webClient_freeMeetingBeginToConutdown',
  NDIDialogHasShown: 'NDIDialogHasShown',
  webClient_audioEncryptKey: 'webClient_audioEncryptKey',
  webClient_videoEncryptKey: 'webClient_videoEncryptKey',
  webClient_sharingEncryptKey: 'webClient_sharingEncryptKey',
  webClient_hasAlertStopShareDialogBeforeJoinBO:
    'webClient_hasAlertStopShareDialogBeforeJoinBO',
  webClient_hasAlertSharingToBOConfirm: 'webClient_hasAlertSharingToBOConfirm',
  archiveConfirmed: 'archiveConfirmed',
  summaryConfirmed: 'summaryConfirmed',
  meetingQueryConfirmed: 'meetingQueryConfirmed',
  privateMeetingConfirmed: 'privateMeetingConfirmed',
  liveStreamConfirmed: 'liveStreamConfirmed',
  recordingConfirmed: 'recordingConfirmed',
  CCConfirmed: 'CCConfirmed',
  smartRecordingConfirmed: 'smartRecordingConfirmed',
  promoteConfirmed: 'promoteConfirmed',
  avStatus: 'avStatus',
  callMeStatusCode: 'callMeStatusCode',
  callMePhoneNumber: 'callMePhoneNumber',
  isMeeting: 'isMeeting',
  inMeeting: 'inMeeting',
  // if user be put on hold upon entry, hasBeenInMeeting is false.
  hasBeenInMeeting: 'hasBeenInMeeting',
  isInvited: 'isInvited',
  PO: 'preview_options',
  reactionEnablesDialogDisplayChanged: 'reactionEnablesDialogDisplayChanged',
  AICPlusConfirmed: 'AICPlusConfirmed',
  captchaVerifyHash: 'captchaVerifyHash',
};

const MEMORYSTORAGE_KEYS = {
  webclient_meeting_tokens: 'WEB-CLIENT-MEETING-TOKENS',
  webclient_rwc_response: 'rwc.oldResponse',
  rwcGeoFenceResponse: 'rwc.geoFenceResponse',
  rwgUrl: 'rwg.url',
  currentRwcIsGood: 'rwc.isGood',
  meetingInfo: 'meetingInfo_memory',
  showedMultiSpeakerActiveTips: 'showedMultiSpeakerActiveTips',
};

const RESET_WEBSOCKET_EVENTS = {
  BO_JOIN: 'BOJoin',
  BO_LEAVE: 'BOLeave',
  IN_BO: 'INBO',
  PROMOTE_DEPROMOTE: 'PROMOTE_DEPROMOTE',
  NEW_WAITING_ROOM_JOIN: 'NEW_WAITING_ROOM_JOIN',
};

// You can use this string to replace the placeholder when a sentence contains a component or other dom
const ELEMENT_PLACEHOLDER = '!@#$%%$#@!';
// split paragraph and ul>li
const ELEMENT_PARAGRAPH_BREAK = `~~!@#$%%$#@!`;
const ELEMENT_LI_BREAK = '~!@#$%%$#@!';

export {
  SHOW_WINDOW_TYPE_LIMIT,
  SOCKET_NAME_ENUM,
  LOCALSTORAGE_KEYS,
  SESSIONSTORAGE_KEYS,
  MEMORYSTORAGE_KEYS,
  RESET_WEBSOCKET_EVENTS,
  ELEMENT_PLACEHOLDER,
  ELEMENT_PARAGRAPH_BREAK,
  ELEMENT_LI_BREAK,
};
const bitSet = (n) => {
  return 0b1 << (n - 1);
};
export const WEBINAR_CLIENT_CAP_CAN_MUTE = bitSet(1);
export const WEBINAR_CLIENT_CAP_SUPPORT_FETCH_MEETING_TOKEN = bitSet(2);
export const WEBNIAR_CLIENT_CAP_SUPPORT_FILE_TRANSFER = bitSet(3);
export const WEBNIAR_CLIENT_CAP_SUPPORT_CONF_CHAT_CHANNEL = bitSet(4); // ZOOM-199247
export const WEBNIAR_CLIENT_CAP_SUPPORT_ENDPOINT_ENCRYPTED = bitSet(5); // clientCap
export const WEBNIAR_CLIENT_CAP_SUPPORT_ENDPOINT_IS_H323 = bitSet(6); // clientCap
export const WEBNIAR_CLIENT_CAP_SUPPORT_IS_CRC_FREE = bitSet(10);

export const CLIENT_CAPS_SUPPORT_ASL = bitSet(1); // support ASL
export const CLIENT_CAPS_SIDECAR_PHASE_2 = bitSet(2);
/** support bo broadcast voice */
export const CLIENT_CAPS_SUPPORT_BROADCAST_VOICE = bitSet(4);
export const CLIENT_CAPS_SEND_MEETING_TOKEN_SEPARATELY = bitSet(5); // support return meeting token in a separate message
export const CLIENT_CAPS_SUPPORT_PREVIEW = bitSet(6); //preview flow cap
export const CLIENT_CAPS_WEB_CAMERA_ON = bitSet(7);
/**
 * !! cautious use bitSet(8) / 0x80. Web SDK use 0x80 incorrectly on the production without video share
 */
export const CLIENT_CAPS_SUPPORT_VIDEO_SHARE = bitSet(10); // support video share ZOOM-412275 (0b1000000000/512)
export const CLIENT_CAPS_SUPPORT_UNIQUE_ERROR_CODE = bitSet(11); // support 1000 - 1014 -> 131000 - 131014
export const CLIENT_CAPS_IN_MEETING_WHITE_BOARD = bitSet(12); // suport whiteboard feature
export const CLIENT_CAPS_GALLERY_VIEW_SORT_BY_TYPE = bitSet(13);
export const CLIENT_CAPS_REQUEST_REMOTE_CONTROL = bitSet(14); // support be remote controled
export const CLIENT_CAPS_SMART_SUMMARY = bitSet(15);
export const CLIENT_CAPS_SUPPORT_FAR_END_CAMERA_CONTROL = 0x100; // far end camera control
export const CLIENT_SUPPORT_SWITCH_REMOTE_CAMERA = 0x40000; // switch remote camera
export const CLIENT_CAPS_SUPPORT_SHARE_MULTI_STREAM = bitSet(18);
export const CLIENT_CAPS_SUPPORT_VIDEO_LTR = bitSet(19);
export const CLIENT_CAPS_WEBRTC_AUDIO_RTCP_TIME = bitSet(20);
export const CLIENT_CAPS_SUPPORT_SIMULIVE_PHRASE_2 = bitSet(21); // support simulive phase2
export const CLIENT_CAPS_MEETING_QUERY_NOTIFICATION = bitSet(22);
export const CLIENT_CAPS_SUPPORT_ANSWER_START_CMR_REQ = bitSet(23);
export const CLIENT_CAPS_SUPPORT_GRANT_LOCAL_LIVESTREAMING = bitSet(25);
export const CLIENT_CAPS_SUPPORT_MEETING_QUERY = bitSet(26);
export const CLIENT_CAPS_SUPPORT_QUERY_REQUEST_ENABLE_FLOW = bitSet(27);
export const CLIENT_CAPS_SUPPORT_QUERY_REQUEST_START_FLOW = bitSet(28);
export const CLIENT_CAPS_SUPPORT_SEND_1080P_VIDEO = bitSet(29);
export const CLIENT_CAPS_SUPPORT_SEND_1080P_SHARE = bitSet(30);
// local record
export const CLIENT_CAPS_SUPPORT_LOCALRECORDING = 0x8000; // 0x00008000L;
export const CLIENT_CAPS_GRANT_LOCALRECORDING_PERMISSION = 0x10000; // 0x00010000L;

export const CLIENT_CAPS_WEBINAR_NO_NEED_TYPE_EMAIL = bitSet(31);

/**
 * Attention:
 * (0x00020000).toString(2).length === 18
 * (0x00020000) === bitSet(18)
 */

/**
 * ClientCapEx
 */
export const DataCenterRegionType = Object.freeze({
  HYBRID: 'HYBRID',
  ONPREM: 'ONPREM',
});
export const ZOOM_DOMAIN_TYPE = Object.freeze({
  ZOOM_DOMAIN_TYPE_NONE: 0,
  /** @description zoom  production(zoom.us) */
  ZOOM_DOMAIN_TYPE_PROD: 1,
  /** @description zoom  dev(zoomdev.us) */
  ZOOM_DOMAIN_TYPE_DEV: 2,
  /** @description zoom gov production(zoomgov.com) */
  ZOOM_DOMAIN_TYPE_GOV: 3,
  /** @description zoom gov dev(zoomgovdev.com) */
  ZOOM_DOMAIN_TYPE_GOVDEV: 4,
  /** @description zoom CN poruction(zoom.com.cn) */
  ZOOM_DOMAIN_TYPE_CN: 5,
  /** @description zoom dev CN (dev.zoom.com.cn) */
  ZOOM_DOMAIN_TYPE_DEVCN: 6,
});
export const EncryptionType = Object.freeze({
  NONE: 0,
  ECB: 1,
  GCM: 2,
});

export const PARTICIPANT_NEED_ASK_UNMUTE = 0x0001;
export const PARTICIPANT_CAN_BE_ASSIGNED_HUGE_BO = 0x0004;

export const MAX_CONCURRENCY_VIDEO_THREADS = {
  SIZE_1: 1,
  SIZE_5: 5,
  SIZE_10: 10,
  SIZE_26: 26,
};

export const MAX_VIDEO_BAR_SIZE = {
  SIZE_1: 1,
  SIZE_5: 3,
  SIZE_10: 5,
  SIZE_26: 5,
  SIZE_3: 3,
};

//
export const MAX_VIDEO_BAR_SIZE_SPECIAL = {
  1: 1,
  2: 1,
  3: 2,
  4: 2,
  5: 3,
  6: 4,
  7: 4,
  8: 5,
  9: 5,
  25: 5,
};

export const MAX_VIDEO_ACTIVE_SIZE = {
  SIZE_1: 1,
  SIZE_5: 2,
  SIZE_10: 4,
  SIZE_26: 9,
};

export const MAX_VIDEO_ACTIVE_SIZE_SPECIAL = {
  1: 1,
  2: 1,
  3: 1,
  4: 2,
  5: 2,
  6: 2,
  7: 3,
  8: 3,
  9: 4,
  25: 9,
};

export const CAPS_OPTION_SUPPORT_CHOOSE_ROOM = 0x00000008;
// Current co-host user, host and target user should all has this cap.
export const CAPS_OPTION_SUPPORT_FORCE_JOIN_LEAVE_BO = 0x00000010;
// Current co-host user and host should both has this cap.
export const CAPS_OPTION_SUPPORT_COHOST_START_STOP_BO = 0x00000020;

export const OPTION_SUPPORT_TG_MOVE_TO_MAIN_SESSION = 0x00000040; // For phone users only.
export const CAPS_OPTION_SUPPORT_COHOST = 0x00000100;
export const CAPS_OPTION_SUPPORT_ZOOM_PRESENCE = 0x00000080;
export const CAPS_OPTION_SUPPORT_CLOSEDCAPTION = 0x00000400;
export const CAPS_OPTION_SUPPORT_EDIT_WHILE_BO_START = 0x00000800; // not in use
export const CAPS_OPTION_SUPPORT_EDIT_WHILE_BO_START2 = 0x00001000;
export const CAPS_OPTION_SUPPORT_VEIW_BO_ACTIVITY = 0x00002000;
export const CAPS_OPTION_SUPPORT_AUTO_MOVE_TO_MAIN_SESSION = 0x800;

export const CAPS_OPTION_SUPPORT_GALLERY_VIEW_SORT_TYPE = 0x00080000; // is current host support gallery sort

export const CAPS_OPTION_SUPPORT_ANSWER_START_CMR_REQ = 0x00200000;
export const CAPS_OPTION_SUPPORT_LOCAL_LIVESTREAMING = 0x00800000;

/** user share pronouns type */
export const SHARE_PRONOUNS_TYPE = Object.freeze({
  SHARE_PRONOUNS_TYPE_ASK: 0,
  SHARE_PRONOUNS_TYPE_ALAWYS: 1,
  SHARE_PRONOUNS_TYPE_NOT: 2,
});

export const JOIN_MEETING_POLICY = Object.freeze({
  CHAT: 'chat',
  CC: 'closeCaption',
  REMOTE_CONTROL: 'remoteControl',
  SHARING: 'screenShare',
  MEETING_POLL: 'meetingPolls',
  WEBINAR_POLL: 'webinarPolls',
  QA: 'webinarQnA',
  FILE_TRANSFER: 'fileTransfer', // not support
  REACTIONS: 'meetingReactions',
  NON_VERBAL_FEEDBACK: 'nonVerbalFeedback',
  HIDE_AVATAR: 'participantAvatar',
  DISABLE_COMPUTER_AUDIO: 'computerAudio',
  DISABLE_DIRECT_MESSAGE: 'directMessage',
  DISABLE_NEW_LT: 'liveTranscription',
  DISABLE_WEBINAR_REACTIONS: 'webinarReactions',
  DISABLE_IN_MEETING_WHITEBOARD: 'inMeetingWhiteBoard', // not support
  WEBINAR_SURVEY: 'webinarSurvey',
  JOIN_WEBINAR: 'joinWebinar', // 4098 error 79
  MEETING_SURVEY: 'meetingSurvey', // rwg handle this, doesn't return surveyUrl
  SAVE_CAPTION: 'saveCaptions',
  ZOOM_APP: 'inMeetingZoomApps',
  SMART_SUMMARY: 'smartMeetingSummaries',
  MEETING_QUERIES: 'DisableAICQueries',
  // statusSharingInCalendar: 'statusSharingInCalendar' // not support
  // annotation: 'DisableAnnotation', // not support
  // whiteBoard: 'DisableWhiteBoard', // classicWhiteBoard,not support
  // newWhiteBoard: 'DisableNewWhiteBoard', not support
  // localRecording: 'DisableLocalRecording', not support
  // remoteSupport: 'DisableRemoteSupport', not support
});

export const DISABLE_MESSAGE_TYPES_ON_TESLA = {
  WS_CONF_BO_TOKEN_RES: socketEventTypes.WS_CONF_BO_TOKEN_RES,
  WS_CONF_BO_ATTRIBUTE_INDICATION:
    socketEventTypes.WS_CONF_BO_ATTRIBUTE_INDICATION,
  WS_CONF_BO_COMMAND_INDICATION: socketEventTypes.WS_CONF_BO_COMMAND_INDICATION,
  WS_CONF_BO_JOIN_RES: socketEventTypes.WS_CONF_BO_JOIN_RES,
  WS_CONF_BO_PRE_ASSIGN_RES: socketEventTypes.WS_CONF_BO_PRE_ASSIGN_RES,
  WS_CONF_HOST_RECEIVED_ENABLE_LIVE_TRANSCRIPT_REQ_INDICATION:
    socketEventTypes.WS_CONF_HOST_RECEIVED_ENABLE_LIVE_TRANSCRIPT_REQ_INDICATION,
  WS_CONF_ALLOW_ASK_HOST_START_LIVE_TRANSCRIPT_INDICATION:
    socketEventTypes.WS_CONF_ALLOW_ASK_HOST_START_LIVE_TRANSCRIPT_INDICATION,
  WS_CONF_CLOSED_CAPTION_INDICATION:
    socketEventTypes.WS_CONF_CLOSED_CAPTION_INDICATION,
  WS_CONF_LIVE_TRANSCRIPTION_ON_OFF_RES:
    socketEventTypes.WS_CONF_LIVE_TRANSCRIPTION_ON_OFF_RES,
  WS_CONF_LIVE_TRANSCRIPTION_STATUS_INDICATION:
    socketEventTypes.WS_CONF_LIVE_TRANSCRIPTION_STATUS_INDICATION,
  WS_CONF_POLLING_USER_ACTION_ERROR:
    socketEventTypes.WS_CONF_POLLING_USER_ACTION_ERROR,
  WS_CONF_POLLING_IN_MEETING_OPTIONS_INDICATION:
    socketEventTypes.WS_CONF_POLLING_IN_MEETING_OPTIONS_INDICATION,
  WS_CONF_INTERPRETER_START: socketEventTypes.WS_CONF_INTERPRETER_START,
};

export const WAITING_ROOM_SORT_TYPES = {
  JOIN_ORDER: 1,
  ALPHABETICAL: 2,
};

export const TOKEN_TYPE = {
  TOKEN_TYPE_UNKNOW: 0,
  TOKEN_TYPE_APP_ACCESS: 1,
  TOKEN_TYPE_POLLING: 2,
  TOKEN_TYPE_GR_JOIN: 3,
  TOKEN_TYPE_USER_INFO: 4,
  TOKEN_TYPE_GET_HOSTKEY_JWT: 5,
  TOKEN_TYPE_END: 6,
  TOKEN_TYPE_BO_ROLE_TOKEN: 200,
};

export const CHAT_MSG_FROM = {
  CHAT_FROM_CLIENT: '0',
  CHAT_FROM_PMC_IM: '1',
  CHAT_FROM_DLP: '2',
};

export const CHAT_FROM = {
  IM: 'im',
  MEET: 'meet',
};

export const CONF_SESSION_STATUS = {
  NONE: 0,
  FREE_USER_30MIN_OUT_NOTIFY: 1,
  PAID_MEETING_STAR: 2,
  JBH_MEETING_START: 3,
  FREE_MEETING_START: 4,
  MEETING_STAT_PUSH_READY: 5,
  HOST_LEAVED_AND_AUTO_ASSIGN_WR: 6,
  END: 7,
};

export const CHAT_CONSTANTS = {
  SYS: -1,
  DUMMY_HEADER: -2,
  DELETED: -3,
  INPUT: 1,
  PMC_HEADER: -4,
};

export const CHAT_THREAD = {
  NONE: -1,
  EXPAND: 1,
  COLLAPSE: 2,
  MORE: 3,
};
export const SEND_CHAT_WARNING = {
  NONE: 0,
  NO_PRIVILEGE: -1, //
  ABSENT: -2, //
  RESTRICTED: -3, //
  IMPROPER_RECEIVER: -4, //
  EXCEPTION_WR: -5,
  UNSUPPORTED_TYPE: -6,
  EDIT_DM_ON_HOLD: -7,
  NOT_IN_SAME_MEETING: -8,
  FILE_BLOCKED: -9, //
  FILE_SIZE_LIMIT: -10, //
  FILE_COUNT_FULL: -11,
  FILE_REMOVED: -12,
  WR_UN_SUPPORT_FILES: -13,
};

export const MAX_FILES_COUNT = 10;
export const IQ_FROM_IM = 'IQ_FROM_IM';

export const SESSION_TYPE = {
  SESSION_TYPE_AUDIO: 1,
  SESSION_TYPE_DESKSHARE: 2,
  SESSION_TYPE_VIDEO: 3,
};

export const CRC_TYPE = {
  H323: 1,
  SIP: 2,
};

export const STY_API_USER = 99;
export const STY_NORMAL_USER = 100;

export const supportedBrowserVersions = {
  CHROME: 102,
  EDGE: 102,
  FIREFOX: 105,
  SAFARI: 16.4,
};
