import { Task } from '@zoom/task-core';
import { taskLoadWebclient } from './optional-task/task.load-webclient';
import { taskRunWebClient } from './main-task/task.run-webclient/task.run-webclient';
import { taskBeforeLoadWebClient } from './main-task/task.before-load-webclient/task.before-load-webclient';
import { taskToLeaveWebClient } from './main-task/task.to-leave-webclient';
import { taskLoadWhiteboard } from './optional-task/task.load-whiteboard';
import { logJoinFlowTelemetry } from '../global/logger/log-service/join-flow-telemetry';

export function runMainClient(fromPreview) {
  logJoinFlowTelemetry('wait_webclientJS_download');
  return Task.watch(taskLoadWebclient).then(() => {
    return Task.run({
      expectMainTask: taskRunWebClient,
      taskProps: { fromPreview },
    });
  });
}

export function loadMainClient() {
  return Task.run({
    optionalTask: taskLoadWebclient,
  });
}

// Task.run will find out the valid task and run it
// if there is no 'optionalTask' passed into Task.run, it will run main task
// if there is no main task has been run before, the first main task is what 'isEntry' indicating in config loaded in the 'Task.init'
// At any time, it allow only one main task existing.
// main task can't be canceled by user, it's managed by task-core. user can cancel the optional task.
export function beforeLoadWebClient() {
  return Task.run({
    taskProps: 'test props 1',
    expectMainTask: taskBeforeLoadWebClient,
  });
}

export function leaveWebClient(data) {
  return Task.run({
    taskProps: {
      leaveReason: data.body.reason,
    },
    expectMainTask: taskToLeaveWebClient,
  });
}

export function loadWhiteboard() {
  return Task.run({
    optionalTask: taskLoadWhiteboard,
  });
}
