import meetingField from 'meetingField';
import { easyStore, storeType } from '../easy-store';
import meetingConfig from 'meetingConfig';

export const setMeetingInfo = (meetingInfo) => {
  easyStore.easySet('meetingInfo', meetingInfo, storeType.sessionStorage);
};

export const getMeetingInfo = () => {
  const valInStore = easyStore.easyGet('meetingInfo');
  return valInStore ? valInStore : getDefaultMeetingInfo();
};

export const cacheMeetingInfo = (email = '') => {
  const valInStore = easyStore.easyGet('meetingInfo');
  if (!valInStore) {
    setMeetingInfo(getDefaultMeetingInfo(email));
  }
};

export const getDefaultMeetingInfo = (email = '') => {
  if (meetingConfig.isStart) {
    return {
      email: email || meetingConfig.userEmail,
      meetingId: meetingConfig.meetingNumber,
      name: meetingConfig.userName,
      pwd: meetingConfig.password,
    };
  } else {
    return {
      email: email || meetingField.emailVal,
      meetingId: meetingConfig.meetingNumber,
      name: meetingField.nameVal,
      pwd: meetingField.pwdVal,
    };
  }
};
