import meetingConfig from 'meetingConfig';

export function initUserName() {
  /* eslint-disable-next-line @babel/no-invalid-this */
  const { sessionUserName, originName } = this.sessionStorageData;
  const newOriginName = meetingConfig.userName;
  let userName = null;

  if (
    !sessionUserName ||
    (originName &&
      originName !== newOriginName &&
      sessionUserName !== newOriginName)
  ) {
    userName = newOriginName;
    /* eslint-disable-next-line @babel/no-invalid-this */
    Object.assign(this.memory, { isOldUser: false, isOldRwgUser: false });
  } else if (sessionUserName) {
    userName = sessionUserName;
    /* eslint-disable-next-line @babel/no-invalid-this */
    Object.assign(this.memory, { isOldUser: true, isOldRwgUser: true });
  }

  /* eslint-disable-next-line @babel/no-invalid-this */
  Object.assign(this.sessionStorageData, {
    sessionUserName: userName,
    originName: newOriginName,
  });
}

export function isOldUserName() {
  /* eslint-disable-next-line @babel/no-invalid-this */
  return this.memory.isOldUser;
}

export function isOldRWGUserName() {
  /* eslint-disable-next-line @babel/no-invalid-this */
  const tmp = this.memory.isOldRwgUser;
  /* eslint-disable-next-line @babel/no-invalid-this */
  if (!this.memory.isOldRwgUser) {
    /* eslint-disable-next-line @babel/no-invalid-this */
    this.memory.isOldRwgUser = true;
  }
  return tmp;
}
