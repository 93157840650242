import { getBaseUrl } from '../global/task-utils/common-utils';

function waitingForHostJob({ cleanJobRegister, taskProps }) {
  // eslint-disable-next-line no-undef
  __webpack_public_path__ = `${getBaseUrl()}/`;
  import(
    /* webpackMode: "lazy" */
    /* webpackChunkName: "preview" */
    '../../features/preview/task'
  ).then(({ runWaitingForHost }) => {
    const { waitingTip } = taskProps;
    const unmount = runWaitingForHost(waitingTip);
    cleanJobRegister(() => {
      unmount();
    });
  });
}

const taskRunWaitingForHost = [waitingForHostJob];

export { taskRunWaitingForHost };
