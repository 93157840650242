export * from './main';

export const sdkIvValKeyEnum = {
  0: 'VIDEO_ENCODE',
  1: 'VIDEO_DECODE',
  2: 'AUDIO_ENCODE',
  3: 'AUDIO_DECODE',
  4: 'SHARING_ENCODE',
  5: 'SHARING_DECODE',
};

export const sdkIvTypeKeyEnum = {
  VIDEO_ENCODE: '0',
  VIDEO_DECODE: '1',
  AUDIO_ENCODE: '2',
  AUDIO_DECODE: '3',
  SHARING_ENCODE: '4',
  SHARING_DECODE: '5',
};

export const encryptType = {
  NONE: 0,
  AES256: 1,
  AES_GCM: 2,
};
