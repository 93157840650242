import { deepFreeze } from '../../global/util';

const makeSkinTone = () => {
  const rawType = {
    EmojiReactionSkinTone_Unknown: {
      type: 0,
      name: 'Unknown',
      content: '',
      code: '',
    },
    EmojiReactionSkinTone_1: {
      type: 1,
      name: 'Default',
      content: '',
      code: '',
    },
    EmojiReactionSkinTone_2: {
      type: 2,
      name: 'Light',
      content: '🏻',
      code: '1f3fb',
    },
    EmojiReactionSkinTone_3: {
      type: 3,
      name: 'Medium Light',
      content: '🏼',
      code: '1f3fc',
    },
    EmojiReactionSkinTone_4: {
      type: 4,
      name: 'Medium',
      content: '🏽',
      code: '1f3fd',
    },
    EmojiReactionSkinTone_5: {
      type: 5,
      name: 'Medium Dark',
      content: '🏾',
      code: '1f3fe',
    },
    EmojiReactionSkinTone_6: {
      type: 6,
      name: 'Dark',
      content: '🏿',
      code: '1f3ff',
    },
  };
  const propertiesToFlat = ['type', 'code'];
  const rawTypeValues = Object.values(rawType);
  propertiesToFlat.forEach((property) =>
    rawTypeValues.forEach((value) => {
      if (value[property] || value[property] === 0) {
        rawType[value[property]] = value;
      }
    }),
  );
  return rawType;
};
const SKIN_TONE_TYPE = deepFreeze(makeSkinTone());

const TAB_COLORS = {
  general: '#D2D2D2',
  video: '#AFD784',
  audio: '#82C786',
  background: '#4FD1E2',
  stats: '#C182D1',
  about: '#7A86CC',
};

const SETTINGS_TAB_KEY = {
  GENERAL: 'general',
  VIDEO: 'video',
  AUDIO: 'audio',
  BACKGROUND: 'background',
  STATS: 'stats',
  ABOUT: 'about',
};

export { SKIN_TONE_TYPE, TAB_COLORS, SETTINGS_TAB_KEY };

export const DENOISE_TYPE = {
  ZOOM: 'Zoom',
  BROWSER: 'Browser',
};

export const AUDIO_PROFILE_TYPE = {
  DENOISE: 'backgroundNoiseSuppression',
  ORIGINAL_SOUND: 'originalSound',
};
