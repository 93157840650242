import meetingConfig from 'meetingConfig';
import {
  USER_ROLE_HOST,
  USER_ROLE_VIEWONLY,
  USER_ROLE_ATTENDEE,
} from '../../constants/UserRoles';

/* hot fix for the difference between the role from RWG and the role from Web START */

const [USRE_ROLE_HOST_WEB, USRE_ROLE_VIEWONLY_WEB, USRE_ROLE_PANELIST_WEB] = [
  1, 0, -1,
];
const USRE_ROLE_PANELIST_RWG = 0;

const userRoleMapping = {
  [USRE_ROLE_VIEWONLY_WEB]: meetingConfig.isWebinar
    ? USER_ROLE_VIEWONLY
    : USER_ROLE_ATTENDEE,
  [USRE_ROLE_HOST_WEB]: USER_ROLE_HOST,
  [USRE_ROLE_PANELIST_WEB]: USRE_ROLE_PANELIST_RWG,
};
/* hot fix for the difference between the role from RWG and the role from Web END */

export function getDefaultUserRole() {
  return userRoleMapping[meetingConfig.userRole] || 0;
}
