import { forbidTranslate } from './global/forbidTranslate';
import { PERFORMANCE_MARK, performanceMark } from './performance';
performanceMark(PERFORMANCE_MARK.start_entryJS);
import { initTask } from './tasks';
import { beforeLoadWebClient } from './tasks/model';

forbidTranslate();
initTask();

beforeLoadWebClient();
