import UAParser from 'ua-parser-js';

/*
Doc: https://www.npmjs.com/package/ua-parser-js
*/
export function getUA() {
  if (!window?.navigator) return {};
  // eslint-disable-next-line @babel/new-cap
  return UAParser(window.navigator.userAgent);
}
