import { iText } from '../../global/util';

export const moreText = iText('More', 'apac.wc_more');
export const unmuteAllText = iText('Unmute All', 'apac.toolbar_unmuteall');
export const muteAllText = iText('Mute All', 'apac.toolbar_muteall');
export const clearAllFeedbackText = iText(
  'Clear All Feedback',
  'apac.reaction.clear_all_feedback',
);
export const lowerAllHandsText = iText(
  'Lower All Hands',
  'apac.wc_lower_all_hands',
);

export const participantsText = iText('Participants', 'apac.wc_participants');
export const BOparticipantsText = iText(
  'Breakout Room Participants',
  'apac.wc_beackout_room_participants',
);
export const closeText = iText('Close', 'apac.wc_chat.close');
export const popOutText = iText('Pop Out', 'apac.wc_chat.popout');
export const changeRoleToAttendeeText = iText(
  'Change Role to Attendee',
  'apac.wc_webinar.change_to_attendee',
);
export const promoteToPanelistText = iText(
  'Promote to panelist',
  'apac.wc_webinar.promote_to_panelist',
);
export const stopParticipantSharingText = iText(
  "Stop Participant's Sharing",
  'apac.stop_participant_sharing',
);
export const putOnHoldText = iText('Put on hold', 'apac.wc_webinar_putonhold');
export const putInWaitingRoomText = iText(
  'Put in Waiting Room',
  'apac.wc_put_in_waiting',
);
export const renameText = iText('Rename', 'apac.dialog.rename');
export const removeText = iText('Remove', 'apac.wc_remove');
export const makeHostText = iText('Make Host', 'apac.wc_make_host');
export const withDrawCCText = iText(
  'Withdraw Closed Caption permission',
  'apac.wc_cc_withdraw',
);
export const assignCCText = iText(
  'Assign to type Closed Caption',
  'apac.wc_cc_assign_to',
);
export const disableTalkText = iText(
  'Disable talking',
  'apac.wc_disable_talking',
);
export const allowToTalkText = iText('Allow to talk', 'apac.wc_allow_to_talk');
export const takeOffHoldText = iText(
  'Take off hold',
  'apac.wc_webinar_takeoffhold',
);
export const admitText = iText('Admit', 'apac.wc_admit');
export const admitToMainSessionText = iText(
  'Admit to Main Session',
  'apac.wc_admit_to_main_session',
);
export const clearAllText = iText('clear all', 'apac.wc_nonverbal.clear_all');
export const yesText = iText('yes', 'apac.wc_nonverbal.yes');
export const noText = iText('no', 'apac.wc_nonverbal.no');
export const goSlowerText = iText('go slower', 'apac.wc_nonverbal.go_slower');
export const goFasterText = iText('go faster', 'apac.wc_nonverbal.go_faster');
export const dislikeText = iText('dislike', 'apac.wc_nonverbal.dislike');
export const likeText = iText('like', 'apac.wc_nonverbal.like');
export const clapText = iText('clap', 'apac.wc_nonverbal.clap');
export const needBreakText = iText('need a break', 'apac.wc_nonverbal.break');
export const awayText = iText('away', 'apac.wc_nonverbal.away');
export const raiseHandText = iText(
  'Raise hand',
  'apac.wc_nonverbal.raise_hand',
);
export const lowerHandText = iText(
  'Lower hand',
  'apac.wc_nonverbal.lower_hand',
);
export const PanelistsText = iText('Panelists', 'apac.wc_webinar_panelists');
export const AttendeesText = iText('Attendees', 'apac.wc_webinar_attendees');
export const participantTitle1 = iText(
  'participant in the meeting',
  'apac.wc_participants_title1',
);
export const participantTitle2 = iText(
  'participants in the meeting',
  'apac.wc_participants_title2',
);
export const waitingRoomTitle1 = iText(
  'person is waiting',
  'apac.wc_waiting_room_title1',
);
export const waitingRoomTitle2 = iText(
  'people is waiting',
  'apac.wc_waiting_room_title2',
);
export const waitingRoomTitle3 = iText(
  'person is on hold',
  'apac.wc_waiting_room_title3',
);
export const waitingRoomTitle4 = iText(
  'people is on hold',
  'apac.wc_waiting_room_title4',
);
export const admitAllText = iText(
  'Admit All',
  'apac.wc_waiting_room_admit_all',
);
export const findParticipantText = iText(
  'Find a participant',
  'apac.wc_find_a_participant',
);
export const MergeWithAudioText = iText(
  'Merge with Audio...',
  'apac.wc_merge_audio_1',
);
export const MergeWithVideoText = iText(
  'Merge with Video...',
  'apac.wc_merge_audio_2',
);
export const SeparateAudioVideoText = iText(
  'Separate Audio and Video',
  'apac.wc_separate_audio_menu_item',
);
export const RemoveParticipant = iText(
  'Remove Participant',
  'apac.security.remove_participant',
);
export const Cancel = iText('Cancel', 'apac.dialog.btn_cancle');
export const Invite = iText('Invite', 'apac.toolbar_invite');
export const reclaimHostText = iText('Reclaim Host', 'apac.wc_reclaim_host');
export const claimHostText = iText('Claim Host', 'apac.dialog.claim_host');
export const joiningText = iText('Joining...', 'apac.wc_joining');
export const messageText = iText('Message', 'apac.wc_message');
export const askAllToUnmute = iText(
  'Ask All to Unmute',
  'apac.toolbar_ask_all_unmute',
);
export const waitingRoomText = iText('Waiting Room', 'apac.wc_waiting_room');
export const boWaitingRoomText = iText(
  'Participants will be admitted to the main session',
  'apac.wc_waiting_room_mention_in_bo',
);
export const inTheMeetingText = iText(
  'In the Meeting',
  'apac.wc_in_the_meeting',
);
// TODO: should update the apac key
export const rmcAdminTooltip = iText(
  'This user has host controls and does not have access to any media content (audio, video, chat and reactions).',
  'apac.wc.rmc_admin_tooltip',
);
export const rmcAdminName = iText(
  'Meeting Control Admin',
  'apac.wc.rmc_admin_name',
);

export const NotJoinedTxt = iText('Not Joined', 'apac.wc_not_joined');
export const JoinedTxt = iText('Joined', 'apac.wc_joined');
export const MaybeTxt = iText('Maybe', 'apac.wc_maybe');
export const DeclinedTxt = iText('Declined', 'apac.wc_declined');
export const AcceptedTxt = iText('Accepted', 'apac.wc_accepted');
export const NoResponseTxt = iText('No Response', 'apac.wc_no_response');
export const NotJoinMenuItemTxt = iText(
  'Show Not Joined Participants',
  'apac.wc_show_not_joined',
);

export const HIDE_ROOM_VIEW = iText('Hide Room View', 'apac.wc_hide_room_view');
export const SHOW_ROOM_VIEW = iText('Show Room View', 'apac.wc_show_room_view');

export const attendeeInPracticeSessionTipFn = (count) =>
  iText(
    `${count} Attendees are in the Waiting Room. Start the webinar to admit them.`,
    'apac.attendee-in-practice-session-tip',
    [count],
  );
export const attendeeInPracticeSessionTipForPanelistFn = (count) =>
  iText(
    `${count} Attendees are in the Waiting Room.`,
    'apac.attendee-in-practice-session-tip-for-panelist',
    [count],
  );

export const userJoinTipFn = (user) =>
  iText(
    `${user} has joined the meeting`,
    'apac.wc_meeting.user_joined_tip',
    user,
  );

export const userLeaveTipFn = (user) =>
  iText(`${user} has left the meeting`, 'apac.wc_meeting.user_left_tip', user);

export const userEnterWRTipFn = (user) =>
  iText(
    `${user} has entered waiting room`,
    'apac.wc_meeting.user_entered_wr_tip',
    user,
  );

export const userLeaveWRTipFn = (user) =>
  iText(
    `${user} has left waiting room for this meeting`,
    'apac.wc_meeting.user_left_wr_tip',
    user,
  );
