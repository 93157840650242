import { getBaseUrl } from '../global/task-utils/common-utils';
import { PERFORMANCE_MARK, performanceMark } from '../../performance';
import { pingRWC } from '../../global/service/rwc';
import { fetchProfile } from '../../global/service/profile';
import { createRWGConnect } from '../global/websocket/webclient-websocket';
import { isSinglePageFlowEnabled } from '../../global/service';
import { logJoinFlowTelemetry } from '../../global/logger/log-service/join-flow-telemetry';
import { LoadJs } from '../global/task-utils/load-js';
import meetingConfig from 'meetingConfig';

function previewJob({ cleanJobRegister, ctx }) {
  const runPreview = () => {
    // eslint-disable-next-line no-undef
    __webpack_public_path__ = `${getBaseUrl()}/`;
    import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "preview" */
      '../../features/preview/task'
    ).then(({ runPreview }) => {
      ctx({ beenPreview: true });
      performanceMark(PERFORMANCE_MARK.start_PreviewJS);
      const unmountPreview = runPreview();
      cleanJobRegister(() => {
        unmountPreview();
      });
      return;
    });
  };

  return LoadJs.start(meetingConfig)
    .then((v) => {
      return v.add('js/preview.webclient.min.js');
    })
    .then(() => {
      runPreview();
    });
}

function pingRWCJob() {
  logJoinFlowTelemetry('preview_start_ping_rwc');
  pingRWC().then((props) => {
    if (isSinglePageFlowEnabled()) {
      createRWGConnect(props);
    }
  });
}

function fetchProfileJob() {
  fetchProfile();
}

const taskRunPreview = [previewJob, pingRWCJob, fetchProfileJob];
taskRunPreview.isPreview = true;
export { taskRunPreview };
