/** JsMediaSDK */
export const CHANGE_AUDIO_SPEAKER = 11;
export const CHANGE_AUDIO_MIC = 18;
export const LEAVE_COMPUTER_AUDIO = 21;
export const JOIN_COMPUTER_AUDIO = 22;
export const AUDIO_INTERPRETATION_MUTE = 58;
export const AUDIO_INTERPRETATION_SELECT_LANGUAGE = 59;
export const AUDIO_CC_SELECT_LANGUAGE = 57;
export const AUDIO_INTERPRETATION_LIST_INFO = 60;
export const AUDIO_INTERPRETATION_ENABLE = 61;

/** RWG */
export const AUDIO_EVT_TYPE_BASE = 0x2000;
export const WS_AUDIO_VOIP_JOIN_CHANNEL_REQ = 11 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_MUTE_REQ = 1 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_DROP_REQ = 3 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_DIALOUT_REQ = 5 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_CANCEL_DIALOUT_REQ = 7 | AUDIO_EVT_TYPE_BASE;
export const JoinType = Object.freeze({
  VOIP: 'voip',
  PHONE: 'phone',
  CALL_ME: 'call_me',
  THIRD_Party: '3rd-party',
  SIMU_LIVE: 'simu-live',
});

export const AudioConnectionStatus = Object.freeze({
  NOT_CONNECT: 0,
  CONNECTING: 1,
  CONNECT_SUCCESS: 2,
  CONNECT_FAIL: 3,
});

export const REMEMBER_PHONE_COOKIE_KEY = 'wc_pn';
export const REMEMBER_PHONE_COOKIE_EXPIRE_TIME = 1209600000; // 14 days

export const CALL_TYPES = {
  CALLNUMBER_TOLL: 0,
  CALLNUMBER_TOLLFREE: 1,
  CALLNUMBER_PREMIUM: 10,
  CALLNUMBER_INTERNAL: 999,
};

export const CallMeStatus = {
  success: 8,
  disconnected: -1,
};

export const AUDIO_ANIMATION_TIMEOUT = 3000;
export const SPEAKER = 'speaker';
export const MICROPHONE = 'microphone';
