import meetingConfig from 'meetingConfig';
import { makeLogger, reportToGlobalTracing } from '../../logger';
import {
  encodeBase64,
  getJoinMeetingLog,
  getSharingPlatform,
  isChromeOS,
  isTeslaMode,
  loadCookie,
} from '../../util';
import { getDeviceId } from '../../service/get-device-id';
import { getBrowserVersion } from '../../utils/browser-detect';
import { currentVersion } from '../../../../version';
import { easyStore } from '../../easy-store';
import { PWAExist } from '../../pwa-integration';
import { isOnZoomMeeting, isUISelfPaintSharing } from '../../service';
import { SESSIONSTORAGE_KEYS } from '../../constant';
import { isJoiningRoom } from '../../../features/breakout-room/utils';
import { getClientCap, getClientCapsEx } from '../../service/rwg';
import { isAudioBridge } from '../../op-feature-option';
import { escapeSensitiveContent } from '../../logger/escapeSensitiveContent';
import _ from 'lodash';

const logger = makeLogger(['Join Meeting Flow']);

interface urlParam {
  name: string;
  value: string | number;
  uriEncode?: boolean;
  sensitive?: boolean;
}

function makeParams(args: urlParam[], escapeSensitive: boolean) {
  return args
    .filter((arg) => _.isNumber(arg.value) || !_.isEmpty(arg.value))
    .map((arg) => {
      let val = arg.value;
      if (arg.uriEncode) {
        val = encodeURIComponent(val);
      }
      if (escapeSensitive && arg.sensitive) {
        val = escapeSensitiveContent(val) || '';
      }
      return `${arg.name}=${val}`;
    })
    .join('&');
}

function getJoinRWGURL(
  {
    rwcData,
    zak,
    ts,
    auth,
    trackAuth,
    tid,
    isOnHold,
    boId,
    boToken,
    userName,
    boRoomAttendeeStatus,
    lcp,
    boConfId = '',
    isInPreview,
  },
  escapeSensitive: boolean = false,
) {
  if (!rwcData) {
    logger.log('no rwc data', ['RWG_URL']);
    return null;
  }
  const zmMtkGuid = loadCookie('_zm_mtk_guid');
  const deviceId = getDeviceId();
  const {
    isHost,
    password,
    trackId,
    jmfCode,
    meetingResult,
    lang,
    googleMeetToken,
    googleMeetDeviceId,
    mid,
    userEmail,
    webinarToken,
    enableCustomEmailByUser,
    cid2,
    omn,
    meetingNumber,
  } = meetingConfig;
  const args: urlParam[] = [];
  let islch = 0;

  const isPrevUser = easyStore.isOldRWGUserName();
  const meetingTokenInStorage = easyStore.easyGet(
    SESSIONSTORAGE_KEYS.webClient_meetingToken,
  );
  const zoomId = easyStore.easyGet('zoomId');
  const participantId = easyStore.easyGet('participantId');
  const isBo = isJoiningRoom(boRoomAttendeeStatus) && boId && boToken;
  const jmak = meetingConfig.onZoomOptions && meetingConfig.onZoomOptions.jmak;
  const webClientOpt = easyStore.easyGet(SESSIONSTORAGE_KEYS.webClient_opt);
  let cfs = 0;
  let isdj = 0;
  const uriEncode = true,
    sensitive = true;
  if (zak || isInPreview || lcp) {
    islch = 1;
  } else {
    if (!zak && googleMeetToken && googleMeetDeviceId) {
      islch = 1;
    }
  }
  args.push(
    { name: 'islch', value: islch },
    {
      name: 'dn2',
      value: isInPreview ? '' : encodeBase64(userName),
      uriEncode,
    },
    { name: 'ts', value: ts },
    { name: 'auth', value: auth, uriEncode },
    { name: 'trackAuth', value: trackAuth, uriEncode },
    { name: 'mid', value: mid, uriEncode },
    { name: 'tid', value: tid, uriEncode },
    {
      name: 'browser',
      value: getBrowserVersion() ? getBrowserVersion() : '',
      uriEncode,
    },
    { name: 'ZM-CID', value: deviceId, uriEncode },
    { name: 'lang', value: lang ? lang : '', uriEncode },
    { name: '_ZM_MTG_TRACK_ID', value: zmMtkGuid, uriEncode },
    { name: 'wccv', value: currentVersion.version, uriEncode },
    { name: 'mpwd', value: !isHost && password ? password : '', uriEncode },
    { name: 'track_id', value: trackId },
    { name: 'jmf_code', value: jmfCode },
    { name: 'meeting_result', value: meetingResult ? meetingResult : '' },
    { name: 'isCamera', value: easyStore.easyGet('isCamera') ?? 1 },
  );

  if (rwcData.rwcAuth) {
    args.push({ name: 'rwcAuth', value: rwcData.rwcAuth });
  } else if (rwcData.rwcToken) {
    args.push({ name: 'rwcToken', value: rwcData.rwcToken });
  }
  // eslint-disable-next-line @babel/new-cap
  if (PWAExist()) {
    let fromValue = '';
    if (isTeslaMode()) {
      fromValue = 'pwa-tesla';
    } else if (isChromeOS()) {
      fromValue = 'pwa';
    }
    if (fromValue) {
      args.push({
        name: 'from',
        value: fromValue,
      });
    }
  }
  args.push(
    { name: 'as_type', value: isUISelfPaintSharing() ? 0 : 2 },
    {
      name: 'email',
      value: userEmail ? userEmail : '',
      sensitive,
      uriEncode: !escapeSensitive,
    },
  );
  const webinarTokenInStorage = easyStore.easyGet(
    SESSIONSTORAGE_KEYS.webClient_webinarToken,
  );
  args.push({
    name: 'tk',
    value: webinarTokenInStorage || webinarToken,
    uriEncode,
  });

  if (isPrevUser) {
    if (zoomId && meetingTokenInStorage && webClientOpt) {
      if (!isInPreview) {
        isdj = 1;
      }
      args.push({
        name: 'zoomid',
        value: zoomId,
        sensitive,
      });
      args.push({ name: 'opt', value: webClientOpt });
    }
    if (participantId) {
      args.push({
        name: 'participantID',
        value: participantId,
        sensitive,
      });
    }
    cfs = Number(isOnHold);
  }
  if (isBo) {
    args.push(
      { name: 'bid', value: encodeBase64(boId) },
      { name: 'botk', value: boToken },
      { name: 'confID', value: boConfId },
    );
  }
  // onzoom parameters:
  if (isOnZoomMeeting() && jmak) {
    isdj = 1;
  }
  if (isdj) {
    args.push({ name: 'isdj', value: isdj });
  }
  args.push(
    { name: 'cfs', value: cfs },
    { name: 'clientCaps', value: getClientCap() },
    { name: 'clientCapsEx', value: getClientCapsEx() },
  );
  if (isAudioBridge()) {
    args.push({ name: 'useABAudio', value: 1 });
  }
  if (enableCustomEmailByUser) {
    args.push({
      name: 'customEmailByUser',
      value: 1,
    });
  }

  if (cid2) {
    args.push({ name: 'ZM-CID2', value: cid2, uriEncode });
  }
  args.push({ name: 'clientOS', value: getSharingPlatform() });
  args.push({
    name: 'audioFeature',
    value: JsMediaSDK_Instance.util.getAudioFeatureFlags(),
  });
  args.push({ name: 'omn', value: omn });
  const mpath = isOnZoomMeeting() ? 'onzoom' : 'webclient';
  const mn = escapeSensitive
    ? escapeSensitiveContent(meetingNumber)
    : meetingNumber;
  const params = makeParams(args, escapeSensitive);
  return `wss://${rwcData.rwg}/${mpath}/${mn}?${params}`;
}

export function getJoinRwgUrlAndLog(params) {
  const { tid } = params;
  const url = getJoinRWGURL(params);
  const escapedLogResult = getJoinRWGURL(params, true);
  const meetingTokenInStorage = easyStore.easyGet(
    SESSIONSTORAGE_KEYS.webClient_meetingToken,
  );
  const webClientOpt = easyStore.easyGet(SESSIONSTORAGE_KEYS.webClient_opt);
  const zoomId = easyStore.easyGet('zoomId');
  logger.log(
    getJoinMeetingLog(
      `RWG_URL:${escapedLogResult},\nfixVersion:${currentVersion.fixVersion}`,
    ),
    ['RWG_URL', `tid: ${tid}`],
  );
  if (
    (meetingTokenInStorage || zoomId || webClientOpt) &&
    !(meetingTokenInStorage && zoomId && webClientOpt)
  ) {
    const failoverLog: string[] = [];
    if (!meetingTokenInStorage) {
      failoverLog.push('Meeting_Token_Not_Exist');
    }
    if (!zoomId) {
      failoverLog.push('ZoomId_Not_Exist');
    }
    if (!webClientOpt) {
      failoverLog.push('Opt_Not_Exist');
    }
    logger.log(failoverLog.join(','), ['FAILOVER_PARAMS']).then(() => {
      reportToGlobalTracing({ filter: ['FAILOVER_PARAMS'] });
    });
  }
  return url;
}

function getJoinBORWGURL(svcUrl, conID, escapeSensitive = false) {
  const { meetingNumber } = meetingConfig;
  const args = [
    { name: 'type', value: 'm' },
    { name: 'cid', value: conID },
    { name: 'mode', value: 2 },
  ];
  const mn = escapeSensitive
    ? escapeSensitiveContent(meetingNumber)
    : meetingNumber;
  const params = makeParams(args, escapeSensitive);
  const url = `wss://${svcUrl}/wc/media/${mn}?${params}`;
  return url;
}
export function getJoinBORWGURLAndLog(svcUrl, conID) {
  const url = getJoinBORWGURL(svcUrl, conID);
  const escapedUrl = getJoinBORWGURL(svcUrl, conID, true);
  logger.log(getJoinMeetingLog(`RWG_URL_BO:${escapedUrl}`), ['RWG_URL_BO']);
  return url;
}
