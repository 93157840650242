import { iText } from '../../global/util';

export const INTERPRETATION = iText('Interpretation', 'apac.interpretation');
export const INTERPRETER = iText('Interpreter', 'apac.interpreter');
export const MUTE_ORIGINAL_AUDIO_TEXT = iText(
  'Mute Original Audio',
  'apac.interpretation.mute_original_audio',
);
export const UNMUTE_ORIGINAL_AUDIO_TEXT = iText(
  'Unmute Original Audio',
  'apac.interpretation.unmute_original_audio',
);
export const ORIGINAL_AUDIO_TEXT = iText(
  'Original Audio',
  'apac.interpretation.original_audio',
);

const INTERPRETATION_LANG0 = iText('English', 'apac.interpretation.en');
const INTERPRETATION_LANG1 = iText('Chinese', 'apac.interpretation.cn');
const INTERPRETATION_LANG2 = iText('Japanese', 'apac.interpretation.jp');
const INTERPRETATION_LANG3 = iText('German', 'apac.interpretation.de');
const INTERPRETATION_LANG4 = iText('French', 'apac.interpretation.fr');
const INTERPRETATION_LANG5 = iText('Russian', 'apac.interpretation.ru');
const INTERPRETATION_LANG6 = iText('Portuguese', 'apac.interpretation.pt');
const INTERPRETATION_LANG7 = iText('Spanish', 'apac.interpretation.es');
const INTERPRETATION_LANG8 = iText('Korean', 'apac.interpretation.kr');

export const INTERPRETATION_LANG = {
  INTERPRETATION_LANG0,
  INTERPRETATION_LANG1,
  INTERPRETATION_LANG2,
  INTERPRETATION_LANG3,
  INTERPRETATION_LANG4,
  INTERPRETATION_LANG5,
  INTERPRETATION_LANG6,
  INTERPRETATION_LANG7,
  INTERPRETATION_LANG8,
  'INTERPRETATION_LANG-1': INTERPRETATION,
};

export const INTERPRETATION_END_TIP = iText(
  'Host has ended interpretation',
  'apac.interpretation.end_tip',
);

export const AUDIO_INTERPRETATION_END_TIP = iText(
  'Host has ended language interpretation',
  'apac.interpretation.end_audio_tip',
);

export const SIGN_LANGUAGE_INTERPRETATION_END_TIP = iText(
  'Host has ended sign language interpretation',
  'apac.interpretation.end_sign_language_tip',
);

export const INTERPRETATION_AVAILABLE_TIP = iText(
  'Language interpretation available. Click here to get started.',
  'apac.interpretation.audio_languages_available_tip',
);

export const INTERPRETATION_SL_AVAILABLE_TIP = iText(
  'Sign language interpretation available. Click here to get started.',
  'apac.interpretation.sl_available_tip',
);

export const INTERPRETATION_BOTH_AVAILABLE_TIP = iText(
  'Language and sign language interpretation available. Click here to get started.',
  'apac.interpretation.audio_and_sign_languages_available',
);

export const ORIGINAL_AUDIO_TEXT_NEW = iText(
  'Original Audio (Interpretation off)',
  'apac.interpretation.original_audio_new',
);
export const LISTEN_IN = iText('Listen In:', 'apac.interpretation.listen_in');
export const VIDEO = iText('Video:', 'apac.interpretation.video');

export const SIGN_LANGUAGE_OFF = iText(
  'Sign Language off',
  'apac.interpretation.sign_language_off',
);

export const INTERPRETERS = iText(
  'Interpreters',
  'apac.interpretation.interpreters',
);

export const ALL_INTERPRETERS_VIDEO_OFF = iText(
  'Waiting for interpreters to join or turn on video',
  'apac.interpretation.interpreters_video_off',
);

export const yourVideoIsNowBroadcastingTextOnlyInChannel = (language) =>
  iText(
    `Your video is now broadcasting in the ${language} channel`,
    'apac.interpretation.sign_language_now_broadcasting_channel',
    [language],
  );

export const yourVideoIsNowBroadcastingTextInMainAndChannel = (language) =>
  iText(
    `Your video is now broadcasting in the ${language} channel and main meeting`,
    'apac.interpretation.sign_language_now_broadcasting_both',
    [language],
  );

export const ON_AIR = iText('On air', 'apac.interpration.on_air');

export const MANAGE_WINDOW_TITLE = iText(
  'Language Interpretation',
  'apac.interpretation.manage_window_title',
);

export const ADD_INTERPRETER_TEXT = iText(
  'Add Interpreter',
  'apac.interpretation.add_interpreter',
);

export const START_INTERPRETATION_TEXT = iText(
  'Start',
  'apac.interpretation.start_interpretation',
);

export const UPDATE_INTERPRETATION_TEXT = iText(
  'Update',
  'apac.interpretation.update_interpretation',
);

export const END_INTERPRETATION_TEXT = iText(
  'End',
  'apac.interpretation.end_interpretation',
);

export const MANAGE_INTERPRETATION_MENU_TEXT = iText(
  'Manage Language Interpretation...',
  'apac.interpretation.manage_interpretation',
);

export const INTERPRETER_NOT_JOINED = iText(
  '****** (not joined)',
  'apac.interpretation.interpreter_not_joined',
);

export const CUSTOM_MAXIMUM_ERROR = iText(
  'You have selected the maximum number of custom languages',
  'apac.interpretation.custom_maximum_error',
);

export const INTERPRETER_SELECT_PLACEHOLDER = iText(
  'Search by name',
  'apac.interpretation.interpreter_select_placeholder',
);

export const LANGUAGE_SELECT_PLACEHOLDER = iText(
  'language',
  'apac.interpretation.language_select_placeholder',
);

export const ATTENDEE_ADDED_AS_INTERPRETER_TEXT = iText(
  'Added',
  'apac.interpretation.attendee_added_as_interpreter_text',
);

export const INTERPRETER_TEXT = iText(
  'Interpreter',
  'apac.interpretation.interpreter_text',
);

export const SOURCE_LANGUAGE_TEXT = iText(
  'Source language',
  'apac.interpretation.source_language_text',
);

export const TARGET_LANGUAGE_TEXT = iText(
  'Target language',
  'apac.interpretation.target_language_text',
);

export const DELETE_INTERPRETER_BUTTON = iText(
  'Delete',
  'apac.interpretation.delete_interpreter_button',
);

export const languageSelectedText = (language) =>
  iText(`${language} selected`, 'apac.interpretation.language_selected_text', [
    language,
  ]);

export const NO_LANAGUAGE_SELECTED_TEXT = iText(
  'No language selected',
  'apac.interpretation.no_language_selected_text',
);
