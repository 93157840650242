/**
 * CREATED BY JOHNSON ON 2016/5/27.
 */
export const LEAVEREASON_UNKNOWN = 0;
export const LEAVEREASON_USERREQUEST = 2;
export const LEAVEREASON_SDKCONNECTION = 3;
export const LEAVEREASON_1ON1 = 4;
export const LEAVEREASON_RECONNECT = 5;
export const LEAVEREASON_PTREQUEST = 6;
export const LEAVEREASON_KICKEDBYHOST = 7;
export const LEAVEREASON_ENDBYHOST = 8;
export const LEAVEREASON_ENDBYHOSTSTARTANOTHERMEETING = 9;
export const LEAVEREASON_FREEMEETINGTIMEOUT = 10;
export const LEAVEREASON_JBHTIMEOUT = 11;
export const LEAVEREASON_ENDBYSINGLESTATUS = 12;
export const LEAVEREASON_WEBINARNEEDREGISTER = 13;
export const LEAVE_REASON_ARCHIVE_FAIL = 14;
export const LEAVE_REASON_ENDBYNONE = 15; // just for host
export const LEAVE_REASON_ENDBYADMIN = 16; // just for host
export const LEAVE_REASON_DUPLICATE_SESSION = 17; // duplicate session
export const LEAVE_REASON_MEETINGTRANSFER = 18;
export const LEAVE_REASON_RSGW_END_SIMU_LIVE = 19;
export const LEAVE_REASON_RSGW_TIMEOUT = 20;

// subreason
export const LEAVESUBREASON_WAITINGROOMFAILOVER = 1;
export const LEAVESUBREASON_WEB_END_AND_REJOIN = 2;
