import meetingConfig from 'meetingConfig';
import * as socketTypes from '../../../constants/ZoomSocketEventTypes';
import { isTeslaMode } from '../../../global/util';
import { PERFORMANCE_MARK, performanceMark } from '../../../performance';
import ConnectService from '@zoom/connect-service';
import { SocketType, WCSockets } from './websocket-utils';
import {
  fetchRWCToken,
  getMeetingTokens,
} from '../../../global/service/meetingToken';
import { getPingRWCUrls, getRWCResponse } from '../../../global/service/rwc';
import { easyStore, storeType } from '../../../global/easy-store';
import { heartbeatProvider } from '../../../global/heartbeat-provider';
import { getJoinRwgUrlAndLog } from '../../../global/containers/websocket/websocket-helper';
import { MEMORYSTORAGE_KEYS } from '../../../global/constant';
import {
  WS_CONF_PREVIEW_INFO_RES,
  EVT_TYPE_WS_VIDEO_DATACHANNEL_ANSWER,
} from '../../../constants/ZoomSocketEventTypes';
import { globalVariable } from '../../../global/global-variable';
import { isAudioBridge } from '../../../global/op-feature-option';
import {
  getAudioRwcIpAddress,
  getVideoRwcIpAddress,
} from '../../../global/service';
import { COMMAND_SOCKET_MESSAGE_NOTIFY } from '../../../constants/AVNotifyMediaSDKTypes';
import { FAILOVER_REASON } from '../../../global/enum';
import { Job } from '@zoom/common-utils';
import { JOB_ENUM } from '../../../job_enum';
import { logJoinFlowTelemetry } from '../../../global/logger/log-service/join-flow-telemetry';

// import { makeLogger } from '../../../global/logger';
// const logger = makeLogger(['Join Meeting Flow']);

const heartbeatIns = heartbeatProvider.get('command');

const isEnableGeoFenceRWC = meetingConfig.meetingOptions.isEnableGeoFenceRWC;
const retryTimes = 5;
const { userName, mid } = meetingConfig;
let uselessReconnectionTimesOut = 1;
let connectionALiveTime = 0;
let connectionAliveTimeHandler;
let reconnectLock = false;

export const sdkUninitPreivew = () => {
  if (globalVariable.avSocket?.socketInstance?.previewUnInitRtcConnection) {
    globalVariable.avSocket.socketInstance.previewUnInitRtcConnection();
  }
};

const sdkInitPreview = ({
  conID,
  reportDomain,
  ABToken,
  webtransportPort,
  enableWebTransport,
  supportLocalAB,
}) => {
  if (globalVariable.avSocket && globalVariable.avSocket.socketInstance) {
    const rwgHost = new URL(easyStore.easyGet(MEMORYSTORAGE_KEYS.rwgUrl) || '')
      .host;
    const videoWebsocketUrl = getVideoRwcIpAddress({
      svcUrl: rwgHost,
      meetingNumber: meetingConfig.meetingNumber,
      conferenceID: conID,
    });
    const audioWebsocketUrl = getAudioRwcIpAddress({
      svcUrl: rwgHost,
      meetingNumber: meetingConfig.meetingNumber,
      conferenceID: conID,
    });
    const webtransportInfo = {
      conId: conID,
      meetingNumber: meetingConfig.meetingNumber,
      rwgHost,
      webtransportPort,
    };
    globalVariable.avSocket.socketInstance.previewInit({
      videoDataChannel: {
        conId: conID,
      },
      videoEncodeMediaWss: {
        websocketUrl: videoWebsocketUrl,
      },
      videoDecodeMediaWss: {
        websocketUrl: videoWebsocketUrl,
      },
      ...(enableWebTransport
        ? {
            videoEncodeWebtransport: webtransportInfo,
            videoDecodeWebtransport: webtransportInfo,
          }
        : {}),
      ...(isAudioBridge()
        ? {
            audioBridge: {
              nginxHost: reportDomain,
              rwgHost,
              cid: conID,
              abToken: ABToken,
              supportLocalAB,
            },
          }
        : {
            audioDataChannel: { conId: conID },
            audioEncodeMediaWss: {
              websocketUrl: audioWebsocketUrl,
            },
            audioDecodeMediaWss: {
              websocketUrl: audioWebsocketUrl,
            },
            ...(enableWebTransport
              ? {
                  audioEncodeWebtransport: webtransportInfo,
                  audioDecodeWebtransport: webtransportInfo,
                }
              : {}),
          }),
    });
  }
};

const retryRWC = (reason) => {
  // new RWC
  if (reconnectLock) {
    return Promise.resolve('already reconnecting');
  }
  reconnectLock = true;
  logJoinFlowTelemetry('preview_retry_rwc');
  if (
    isEnableGeoFenceRWC &&
    WCSockets.getConnectTimes(SocketType.RWG) < retryTimes - 1
  ) {
    // check if the RWC is can be connected successfully before
    const isCurrentRwcGood = easyStore.easyGet(
      MEMORYSTORAGE_KEYS.currentRwcIsGood,
    );
    let rwcResponse;
    if (isCurrentRwcGood) {
      const urls = getPingRWCUrls();
      rwcResponse = ConnectService.ping(urls);
    } else {
      rwcResponse = ConnectService.retry();
    }
    // clean the previous RWC recorded status
    easyStore.easySet(
      MEMORYSTORAGE_KEYS.currentRwcIsGood,
      false,
      storeType.memory,
    );
    return rwcResponse.then(({ response, request, log }) => {
      let data = {};
      if (response) {
        data = { ...response };
      } else {
        data = {
          rwg: request.domain,
          rwcToken: request.rwcToken,
        };
      }
      easyStore.easySet(
        MEMORYSTORAGE_KEYS.rwcGeoFenceResponse,
        { data, log },
        storeType.memory,
      );
      return { data, log };
    });
    // .catch((error) => {
    //   // // logger.log(getJoinMeetingLog(error));
    //   // todo show error dialog?
    //   // checkError(dispatch);
    // });
  } else if (
    /* old RWC */
    !isEnableGeoFenceRWC &&
    WCSockets.getConnectTimes(SocketType.RWG) < getRWCResponse().length - 1
  ) {
    // todo resetWebclient to increase resetTime to remount this component to connect RWG again.
    // eslint-disable-next-line no-console
    console.warn(`${reason} to Reconnect websocket start to resetWebclient`);
    return Promise.resolve(
      getRWCResponse()[WCSockets.getConnectTimes(SocketType.RWG)],
    );
  }
  return Promise.resolve('no-rwc-available');
};

const onOpen = (url) => {
  // dispatch(openSocket(url)); commandSocketUrl
  easyStore.easySet(MEMORYSTORAGE_KEYS.rwgUrl, url, storeType.memory);
  connectionAliveTimeHandler = setInterval(() => {
    connectionALiveTime = connectionALiveTime + 2;
  }, 2000);
};

const _reconnect = (evt, reason) => {
  logJoinFlowTelemetry('preview_rwg_close');
  const log = `websocket onclose event:
      type:${evt.type}
      code:${evt.code}
      reason:${evt.reason}
      timeStamp:${evt.timeStamp}
      target:${evt.target && evt.target.readyState}
      url:${evt.target && evt.target.url}`;

  clearInterval(connectionAliveTimeHandler);
  sdkUninitPreivew();
  // if connection keep alive over 110s when onclose, we can say it is close by rwg since no any action.
  if (connectionALiveTime > 110) {
    uselessReconnectionTimesOut = uselessReconnectionTimesOut + 1;
  } else {
    // recover the times
    uselessReconnectionTimesOut = 1;
  }
  connectionALiveTime = 0;
  // over 10min, we say user is leave away or doesn't care about joining meeting
  if (uselessReconnectionTimesOut === 5) {
    return;
  }

  // eslint-disable-next-line no-console
  console.error(log);
  // // logger.log(getJoinMeetingLog(log));

  Promise.all([fetchRWCToken(), retryRWC(reason)])
    .then(([, props]) => createRWGConnect(props))
    .catch(() => {
      logJoinFlowTelemetry('preview_retry_rwc_fail');
    });
};
const reconnect = _.debounce(_reconnect, 500, { trailing: true });
const onClose = (evt) => {
  reconnect(evt, 'onClose');
};

const onError = (evt) => {
  reconnect(evt, 'onError');
};

const onMessage = (evt) => {
  const data = evt.data;
  if (!WCSockets.checkStatus(SocketType.RWG) || !data) {
    return;
  }
  let message = {};
  try {
    message = JSON.parse(data);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  heartbeatIns.aliveChecked = true;

  if (message.evt === socketTypes.WS_CONN_KEEPALIVE) {
    if (message.body && message.body.test === 'invalid parameters!') {
      // eslint-disable-next-line no-console
      console.error(message);
      closeRWGSocket(FAILOVER_REASON.INVALID_PARAMETERS);
    }
    // if (message.seq) {
    //   // eslint-disable-next-line no-console
    //   console.log('Recv KeepAlive', message.seq);
    // }
    return;
  }
  const body = message.body;
  switch (message.evt) {
    case WS_CONF_PREVIEW_INFO_RES: {
      const {
        ABtoken: ABToken,
        conID,
        reportDomain,
        WebTransportPort: webtransportPort,
        enableWebTransport,
        supportLocalAB,
      } = body;
      const rwgHost = new URL(
        easyStore.easyGet(MEMORYSTORAGE_KEYS.rwgUrl) || '',
      ).host;
      easyStore.easySet(
        MEMORYSTORAGE_KEYS.meetingInfo,
        {
          ABToken,
          conID,
          reportDomain,
          enableWebTransport,
          webtransportPort,
          supportLocalAB,
          rwgHost,
        },
        storeType.memory,
      );
      Job.completeSync(JOB_ENUM.RECV_PREVIEW_INFO);
      easyStore.easySet(
        MEMORYSTORAGE_KEYS.currentRwcIsGood,
        true,
        storeType.memory,
      );
      // use the connect times as failed times, if connection is good, don't increase connection times
      const currentTimes = WCSockets.getConnectTimes(SocketType.RWG);
      WCSockets.resetConnectTimes(SocketType.RWG, currentTimes - 1);
      sdkInitPreview({
        conID,
        reportDomain,
        ABToken,
        webtransportPort,
        enableWebTransport,
        supportLocalAB,
      });
      break;
    }
    case EVT_TYPE_WS_VIDEO_DATACHANNEL_ANSWER:
    case socketTypes.WS_CONF_AB_TOKEN_RES: {
      if (globalVariable.avSocket && globalVariable.avSocket.sendSocket) {
        globalVariable.avSocket.sendSocket(
          COMMAND_SOCKET_MESSAGE_NOTIFY,
          message,
        );
      }
      break;
    }
  }
  // todo dispatch(throttleHandleSocketMessage(message));
};

const pingPong = () => {
  let keepAliveInterval = 0;
  heartbeatIns.reset();
  const sendKeepAlive = () => {
    if (!heartbeatIns.aliveChecked) {
      clearInterval(keepAliveInterval);
      performanceMark(PERFORMANCE_MARK.inPreview_RWG_timeout);
      WCSockets.close(SocketType.RWG, FAILOVER_REASON.NO_HEARTBEAT);
      // reconnect(
      //   {
      //     type: 'close',
      //     code: -1,
      //     reason: 'heart beat no response',
      //     timeStamp: Date.now(),
      //     url: easyStore.easyGet('rwg.url'),
      //   },
      //   'onPingPong',
      // );
      return;
    }
    heartbeatIns.aliveChecked = false;
    sendRWGMessage({ evt: socketTypes.WS_CONN_KEEPALIVE });
  };
  sendKeepAlive();
  keepAliveInterval = setInterval(sendKeepAlive, isTeslaMode() ? 10000 : 60000);
  return () => {
    clearInterval(keepAliveInterval);
  };
};

export const closeRWGSocket = (closeCode = FAILOVER_REASON.NORMAL_CASE) => {
  WCSockets.close(SocketType.RWG, closeCode);
};

export const sendRWGMessage = (msg) => {
  if (!WCSockets.checkStatus(SocketType.RWG)) {
    return;
  }
  if (msg && typeof msg === 'object') {
    msg.seq = heartbeatIns.incrementSeq().getSeq();
  }
  WCSockets.get(SocketType.RWG).send(msg);
  return msg.seq;
};

export const startRWGCacheBeforeResetHandlers = () => {
  if (!WCSockets.checkStatus(SocketType.RWG)) {
    return;
  }
  WCSockets.get(SocketType.RWG).waitingResetHandlers();
};

export const createRWGConnect = (props) => {
  if (props === 'no-rwc-available' || !props) {
    // todo throw some error?
    // eslint-disable-next-line no-console
    console.error('no rwc available');
    return;
  }
  if (props === 'already reconnecting') {
    return;
  }
  const { data } = props;
  const { zak, auth, trackAuth, tid, ts } = getMeetingTokens();

  const commandSocketUrl = getJoinRwgUrlAndLog({
    zak,
    auth,
    trackAuth,
    tid,
    ts,
    userName,
    meetingId: mid,
    isOnHold: true,
    boId: null,
    boToken: null,
    boRoomAttendeeStatus: null,
    boConfId: null,
    rwcData: data,
    isInPreview: true,
  });

  const handlers = {
    onOpen: () => onOpen(commandSocketUrl),
    onClose,
    onError,
    onMessage,
    pingPong,
  };
  if (WCSockets.checkInstanceExist(SocketType.RWG)) {
    logJoinFlowTelemetry('preview_retry_rwg');
  } else {
    logJoinFlowTelemetry('preview_start_rwg');
  }
  WCSockets.connect(commandSocketUrl, SocketType.RWG, handlers);
  reconnectLock = false;
  performanceMark(PERFORMANCE_MARK.inPreview_RWG_start, {
    detail: commandSocketUrl,
  });
};
