import { iText } from '../../global/util';

export const POLLING_APAC = {
  Waiting: iText('Waiting...', 'apac.polling.Waiting'),
  Launching: iText('Launching...', 'apac.polling.Launching'),
  Relaunching: iText('Relaunching...', 'apac.polling.Relaunching'),
  Sharing: iText('Sharing...', 'apac.polling.Sharing'),
  Preview: iText('Preview', 'apac.polling.Preview'),
  ReviewResult: iText('Review Result', 'apac.polling.ReviewResult'),
  ShareResult: iText('Share Result', 'apac.polling.ShareResult'),
  ReLaunch: iText('Re-Launch', 'apac.polling.ReLaunch'),
  Launch: iText('Launch', 'apac.polling.Launch'),
  Launched: iText('Launched', 'apac.polling.Launched'),
  AllowPanelistToVote: iText(
    'Allow panelist to vote',
    'apac.polling.AllowPanelistToVote',
  ),
  AllowPanelistToParticipate: iText(
    'Allow panelist to participate',
    'apac.polling.AllowPanelistToParticipate',
  ),
  Close: iText('Close', 'apac.polling.Close'),
  AddA_Poll: iText('Add a Poll', 'apac.polling.AddA_Poll'),
  Back: iText('Back', 'apac.polling.Back'),
  StopSharing: iText('Stop Sharing', 'apac.polling.StopSharing'),
  Submit: iText('Submit', 'apac.polling.Submit'),
  EndPoll: iText('End Poll', 'apac.polling.EndPoll'),
  AttendeesAreNowViewingPollResults: iText(
    'Attendees are now viewing poll results',
    'apac.polling.AttendeesAreNowViewingPollResults',
  ),
  HostIsSharingPullResults: iText(
    'Host is sharing poll results',
    'apac.polling.HostIsSharingPullResults',
  ),
  HostAndParticipantsCantVote: iText(
    'Host and participants can’t vote',
    'apac.polling.HostAndParticipantsCantVote',
  ),
  HostAndPanelistsCantVote: iText(
    'Host and panelists can’t vote',
    'apac.polling.HostAndPanelistsCantVote',
  ),
  PollingClosed: iText('Polling Closed', 'apac.polling.PollingClosed'),
  PollingComputedSummaryData: (nTotalVotedUsers, allOtherMemberSize, percent) =>
    iText(
      `${nTotalVotedUsers} of ${allOtherMemberSize} (${percent}) voted`,
      'apac.polling.PollingComputedSummaryData',
      [nTotalVotedUsers, allOtherMemberSize, percent],
    ),
  AllTypes: iText('All Types', 'apac.polling.AllTypes'),
  Polls: iText('Polls', 'apac.polling.Polls'),
  Quizzes: iText('Quizzes', 'apac.polling.Quizzes'),
  PollsOrQuizzes: iText('Polls/Quizzes', 'apac.polling.PollsOrQuizzes'),
  SharingPollResults: iText(
    'Sharing Poll Results',
    'apac.polling.SharingPollResults',
  ),
  PollResult: iText('Poll Result', 'apac.polling.PollResult'),
  Download: iText('Download', 'apac.polling.Download'),
  SingleChoice: iText('(Single Choice)', 'apac.polling.SingleChoice'),
  MultipleChoice: iText('(Multiple Choice)', 'apac.polling.MultipleChoice'),
  Matching: iText('(Matching)', 'apac.polling.Matching'),
  RankOrder: iText('(Rank Order)', 'apac.polling.RankOrder'),
  ShortAnswer: iText('(Short Answer)', 'apac.polling.ShortAnswer'),
  LongAnswer: iText('(Long Answer)', 'apac.polling.LongAnswer'),
  FillInTheBlank: iText('(Fill in The Blank)', 'apac.polling.FillInTheBlank'),
  RatingScale: iText('(Rating Scale)', 'apac.polling.RatingScale'),
  Conflict: iText(
    'You are logged in from another device. Your Polling session is inactive.',
    'apac.polling.Conflict',
  ),
  PollingIndex: (index) =>
    iText(`Poll ${index}: `, 'apac.polling.PollingIndex', [index]),
  Poll: iText('Poll ', 'apac.polling.Poll'),
  Quiz: iText('Quiz ', 'apac.polling.Quiz'),
  PollFillItemTitleAriaLabel: (title, number) =>
    iText(
      `${title}. The question has ${number} alternative answers.`,
      'apac.polling.PollFillItemTitleAriaLabel',
      [title, number],
    ),
  MoreMeetingControl: iText(
    'More polling control',
    'apac.polling.MoreMeetingControl',
  ),
  ClockAriaLabelShort: (minutes, seconds) =>
    iText(
      `It has lasted ${minutes} minutes and ${seconds} seconds`,
      'apac.polling.ClockAriaLabelShort',
      [minutes, seconds],
    ),
  ClockAriaLabelLong: (hours, minutes, seconds) =>
    iText(
      `It has lasted ${hours} hours and ${minutes} minutes and ${seconds} seconds`,
      'apac.polling.ClockAriaLabelLong',
      [hours, minutes, seconds],
    ),
  PollingInProgress: iText(
    'Polling in progress',
    'apac.polling.PollingInProgress',
  ),
  FooterBarButtonAriaLabel: iText(
    'Open polling dialog',
    'apac.polling.FooterBarButtonAriaLabel',
  ),
  NoPollsCreated: iText('No polls created', 'apac.polling.NoPollsCreated'),
  NoPollsOrQuizzesCreated: iText(
    'No polls or quizzes created',
    'apac.polling.NoPollsOrQuizzesCreated',
  ),
  Create: iText('Create', 'apac.polling.Create'),
  SelectAnswer: iText('Select Answer', 'apac.polling.SelectAnswer'),
  PleaseEnterAnAnswer: iText(
    'Please enter an answer',
    'apac.polling.PleaseEnterAnAnswer',
  ),
  PleaseSelectAnAnswer: iText(
    'Please select an answer',
    'apac.polling.PleaseSelectAnAnswer',
  ),
  YouVeReachedTheMaximumAmountOfCharactersAllowed: iText(
    "You've reached the maximum amount of characters allowed",
    'apac.polling.YouVeReachedTheMaximumAmountOfCharactersAllowed',
  ),
  PleaseEnterAtLeastCharacters: (number) =>
    iText(
      `Please enter at least ${number} characters`,
      'apac.polling.PleaseEnterAtLeastCharacters',
      [number],
    ),
  Correct: iText('Correct! ', 'apac.polling.Correct'),
  CorrectAnswer: iText('Correct answer: ', 'apac.polling.CorrectAnswer'),
  CorrectAnswerIs: iText('Correct answer is ', 'apac.polling.CorrectAnswerIs'),
  TheCorrectAnswerIs: iText(
    'The correct answer is ',
    'apac.polling.TheCorrectAnswerIs',
  ),
  TheCorrectAnswerAre: iText(
    'The correct answers are: ',
    'apac.polling.TheCorrectAnswersAre',
  ),
  YourAnswer: iText('Your answer: ', 'apac.polling.YourAnswer'),
  pollQuestion: (questionCount) =>
    iText(`${questionCount} question`, 'apac.polling.pollQuestion', [
      questionCount,
    ]),
  multiPollQuestions: (questionCount) =>
    iText(`${questionCount} questions`, 'apac.polling.multiPollQuestions', [
      questionCount,
    ]),
  PollEnded: iText('Poll ended', 'apac.polling.PollEnded'),
  QuizEnded: iText('Quiz ended', 'apac.polling.QuizEnded'),
  End: iText('End', 'apac.polling.End'),
  Ended: iText('Ended', 'apac.polling.Ended'),
  NumberOfNumberAnswered: (number1, number2) =>
    iText(
      `${number1} of ${number2} answered`,
      'apac.polling.NumberOfNumberAnswered',
      [number1, number2],
    ),
  NumberOfNumberPercentAnswered: (NumberOfNumberPercent) =>
    iText(
      `${NumberOfNumberPercent} answered`,
      'apac.polling.NumberOfNumberPercentAnswered',
      [NumberOfNumberPercent],
    ),
  ShowCorrectAnswersToAll: iText(
    'Show correct answers to all',
    'apac.polling.ShowCorrectAnswersToAll',
  ),
  EditPoll: iText('Edit Poll', 'apac.polling.EditPoll'),
  DisplayQuestionsInARandomOrder: iText(
    'Display questions in a random order',
    'apac.polling.DisplayQuestionsInARandomOrder',
  ),
  ShowOneQuestionAtATime: iText(
    'Show one question at a time',
    'apac.polling.ShowOneQuestionAtATime',
  ),
  ViewResultsFromBrowser: iText(
    'View Results from Browser',
    'apac.polling.ViewResultsFromBrowser',
  ),
  ViewResults: iText('View Results', 'apac.polling.ViewResults'),
  DownloadResults: iText('Download Results', 'apac.polling.DownloadResults'),
  ReLaunchPoll: iText('Re-launch Poll', 'apac.polling.ReLaunchPoll'),
  ReLaunchQuiz: iText('Re-launch Quiz', 'apac.polling.ReLaunchQuiz'),
  Previous: iText('Previous', 'apac.polling.Previous'),
  Next: iText('Next', 'apac.polling.Next'),
  ViewDetails: iText('View Details', 'apac.polling.ViewDetails'),
  YouAreViewingThePollResultsSharedByHost: iText(
    'You are viewing the poll results (shared by host)',
    'apac.polling.YouAreViewingThePollResultsSharedByHost',
  ),
  YouAreViewingYourQuizResults: iText(
    'You are viewing your quiz results',
    'apac.polling.YouAreViewingYourQuizResults',
  ),
  UnableToDownloadTheImage: iText(
    'Unable to download the image',
    'apac.polling.UnableToDownloadTheImage',
  ),
  UnableToLoadThisImage: iText(
    'Unable to load this image.',
    'apac.polling.UnableToLoadThisImage',
  ),
  Retry: iText('Retry', 'apac.polling.Retry'),
  Required: iText('required', 'apac.polling.Required'),
  CharacterRemaining: (count) =>
    iText(`Character Remaining: ${count}`, 'apac.polling.CharacterRemaining', [
      count,
    ]),
  MenuEdit: iText('Edit', 'apac.polling.MenuEdit'),
  MenuDuplicate: iText('Duplicate', 'apac.polling.MenuDuplicate'),
  MenuDelete: iText('Delete', 'apac.polling.MenuDelete'),
  listTitle: (count) =>
    iText(`${count} Item(s)`, 'apac.polling.ListTitle', [count]),
  FilterAllTypes: iText('All Types', 'apac.polling.FilterAllTypes'),
  PollListFilter: iText('Poll list filter', 'apac.polling.PollListFilter'),
  pollSavedTip: (name) =>
    iText(`"${name}" has been saved`, 'apac.polling.PollSavedTip', [name]),
  PollLaunchedTip: iText(
    'A poll has been launched in this meeting',
    'apac.polling.PollLaunchedTip1',
  ),
  PollSharedTip: iText(
    'A poll has been shared in this meeting',
    'apac.polling.PollSharedTip',
  ),
  PollDeletedTip: iText(
    'This poll has been deleted',
    'apac.polling.PollDeletedTip',
  ),
  PollSaved: iText('Saved', 'apac.polling.PollSaved'),
  PollReachMaxSize: iText(
    'You have reached the maximum number of polls',
    'apac.polling.PollReachMaxSize',
  ),
  PollCreateLoadError: iText(
    'Unable to load page',
    'apac.polling.PollCreateLoadError',
  ),
  PollEditLoadError: iText(
    'Unable to load this poll',
    'apac.polling.PollEditLoadError',
  ),
  PollCreateButtonLabel: iText(
    'Create polls/quizzes',
    'apac.polling.PollCreateButtonLabel',
  ),
  fromBroswer: (action) =>
    iText(`${action} from Broswer`, 'apac.polling.FromBroswer', [action]),
  pollViewResultButtonLabel: (pollName) =>
    iText(
      `View Results button for ${pollName}`,
      'apac.polling.pollViewResultsButtonLabel',
      [pollName],
    ),
  pollStopSharingButtonLabel: (pollName) =>
    iText(
      `Stop Sharing button for ${pollName}`,
      'apac.polling.pollStopSharingButtonLabel',
      [pollName],
    ),
  moreButtonLabel: (pollName) =>
    iText(`more options for ${pollName}`, 'apac.polling.moreButtonLabel', [
      pollName,
    ]),
  PollListLabel: iText('Poll list', 'apac.polling.PollList'),
  DeletePollDialogTitle: iText(
    'Delete Poll',
    'apac.polling.DeletePollDialogTitle',
  ),
  deletePollDialogContent: (pollName) =>
    iText(`Delete ${pollName}`, 'apac.polling.deletePollDialogContent', [
      pollName,
    ]),
  PollSearchText: iText('Search', 'apac.polling.PollSearchText'),
  NoResult: iText('No Result', 'apac.poling.NoResult'),
  PollingText: iText('Polling', 'apac.polling.PollingText'),
  RelaunchConfirmDialogLabel: iText(
    'relaunch confirm dialog',
    'apac.polling.RelaunchConfirmDialogLabel',
  ),
  RelaunchConfirmDialogContent: iText(
    'Re-launching the poll will clear existing polling results. Do you want to continue?',
    'apac.polling.RelaunchConfirmDialogContent',
  ),
  HaveNoPollsOrQuizzes: iText(
    'You have no polls/quizzes',
    'apac.polling.HaveNoPollsOrQuizzes',
  ),
  HaveNoPolls: iText('You have no polls', 'apac.polling.HaveNoPoll'),
  NoPollsHint: iText(
    'Use a poll to collect responses/answers during meetings/webinars.',
    'apac.polling.NoPollsHint',
  ),
  NoPollsOrQuizzesHint: iText(
    'Use a poll or quiz to collect responses/answers during meetings/webinars.',
    'apac.polling.NoPollsOrQuizzesHint',
  ),
  CorrectInTitle: iText('Correct', 'apac.polling.CorrectInTitle'),
  IncorrectInTitle: iText('Incorrect', 'apac.polling.IncorrectInTitle'),
  NewPollLibraryBannerText: iText(
    'All your polls and quizzes in <a>Surveys & Polls</a> are available for this meeting.',
    'apac.polling.NewPollLibraryBannerText',
  ),
  TabAll: iText('All', 'apac.polling.TabAll'),
  Starred: iText('Starred', 'apac.polling.Starred'),
  PollAndStarred: iText('Poll and Starred', 'apac.polling.PollAndStarred'),
  QuizAndStarred: iText('Quiz and Starred', 'apac.polling.QuizAndStarred'),
  MarkAsStarred: iText('Mark as starred', 'apac.polling.MarkAsStarred'),
  MarkAsUnstarred: iText('Mark as unstarred', 'apac.polling.MarkAsUnstarred'),
};
