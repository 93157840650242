import { Task } from '@zoom/task-core';
// WATCH OUT!
// Here I rename taskLeaveWebClient to taskToLeaveWebClient.
// Although taskRunWebClient and taskToLeaveWebClient are sibling in dependency tree,
// we still want to force making the taskToLeaveWebClient code bundled behind taskRunWebClient,
// so that the css of components in taskToLeaveWebClient will be placed after the global util css of taskRunWebClient.
// For slibing modules, webpack order them by lexicographic order, so PLEASE DO NOT CHANGE THE NAME.
// I know this is a very bad pattern, the correct way is to correct the dependency,
// since taskToLeaveWebClient actually depends on "src/assets/scss/main.scss".
// We only use this workaround temporarily, in the transition period of developing the task-core.js.
// Ask Danny and Ruiyuan if you are confusing about this magic.
import { taskBeforeLoadWebClient } from './main-task/task.before-load-webclient/task.before-load-webclient';
import { taskToLeaveWebClient } from './main-task/task.to-leave-webclient';
import { taskRunWebClient } from './main-task/task.run-webclient/task.run-webclient';
import { taskRunPreview } from './main-task/task.run-preview';
import { globalVariable } from '../global/global-variable';
import { taskRequestJoinMeeting } from './main-task/task.request-join-meeting';
import { taskRunWaitingForHost } from './main-task/task.run-waiting-for-host';

globalVariable.wcCTX = (props) => {
  return Task.checkInstance().ctx(props);
};
export const initTask = () => {
  const req = require.context('./', true, /task\.[^/]+?\.js$/);
  // Task.init load the config which describe the main task execution flow
  Task.init(
    [
      {
        current: taskBeforeLoadWebClient,
        next: [taskRequestJoinMeeting, taskRunPreview, taskRunWebClient],
        isEntry: true,
      },
      {
        current: taskRequestJoinMeeting,
        next: [taskRunWaitingForHost, taskRunWebClient],
      },
      {
        current: taskRunWaitingForHost,
        next: taskRunWebClient,
      },
      {
        current: taskRunPreview,
        next: taskRunWebClient,
      },
      {
        current: taskRunWebClient,
        next: [taskToLeaveWebClient, taskRunPreview],
      },
      {
        current: taskToLeaveWebClient,
        isExit: true,
      },
    ],
    req,
    CLIENT_ENV,
  );
};
