import * as errorTypes from '../constants/ErrorTypes';
import * as leaveReasonType from '../constants/LeaveReasons';

export const CONTROL_MODE_CONNECTORS_ENUM = Object.freeze({
  MSFT: 'MSFT',
  GOOGLE_MEET: 'GOOGLE_MEET',
  TESLA: 'TESLA',
  NORMAL: 'NORMAL',
});

// root container classname
export const CONTROL_MODE_CLASSNAME_ENUM = Object.freeze({
  TESLA: 'tesla-ui',
  TESLA_BODY: 'tesla-body',
});

export const CONTROL_MODE_ZOOM_UI_ACTION_TYPE = Object.freeze({
  START_VIDEO: 'START_VIDEO',
  STOP_VIDEO: 'STOP_VIDEO',
  UNMUTE_AUDIO: 'UNMUTE_AUDIO',
  MUTE_AUDIO: 'MUTE_AUDIO',
  CONNECTING: 'CONNECTING',
  FAILOVER: 'FAILOVER',
  LEAVE_MEETING: 'LEAVE_MEETING',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  PING_RWC: 'PING_RWC',
  NETWORK_RECONNECTING: 'NETWORK_RECONNECTING',
  AUDIO_ASN: 'AUDIO_ASN',
  STOP_PARTICIPANT_SHARING: 'STOP_PARTICIPANT_SHARING',
  PRESENTATION_AUDIO_MUTED: 'PRESENTATION_AUDIO_MUTED',
  PRESENTATION_AUDIO_UNMUTED: 'PRESENTATION_AUDIO_UNMUTED',
});

export const CONTROL_MODE_ZOOM_MSG_TYPE = Object.freeze({
  UI: 'UI',
  RWG: 'RWG',
  XMPP: 'XMPP',
  MEDIA_SDK: 'MEDIA_SDK',
});

export const CONTROL_MODE_XMPP_MSG_TYPE = Object.freeze({
  EXPEL: 'expel',
  PROMOTE: 'promote',
  DEPROMOTE: 'depromote',
  MEMBER_CHANGE: 'memberChange',
});

export const CONTROL_MODE_MEETING_STATUS = {
  LOBBY: 'lobby',
  CONNECTED: 'connected',
  CONNECTING: 'connecting',
  DISCONNECTED: 'disconnected',
  NETWORK_RECONNECTING: 'reconnecting',
  RWG_CONNECTED: 'server_connected',
};

export const CONTROL_MODE_READY_FACTOR = {
  AUDIO_ENCODE_INIT_SUCCESS: 0x1,
  AUDIO_DECODE_INIT_SUCCESS: 0x2,
  VIDEO_ENCODE_INIT_SUCCESS: 0x4,
  VIDEO_DECODE_INIT_SUCCESS: 0x8,
  SHARE_ENCODE_INIT_SUCCESS: 0x10,
  SHARE_DECODE_INIT_SUCCESS: 0x20,
  RWG_CONNECTED: 0x40,
};

export const CONTROL_MODE_CAP_FULL =
  CONTROL_MODE_READY_FACTOR.AUDIO_ENCODE_INIT_SUCCESS |
  CONTROL_MODE_READY_FACTOR.AUDIO_DECODE_INIT_SUCCESS |
  CONTROL_MODE_READY_FACTOR.VIDEO_ENCODE_INIT_SUCCESS |
  CONTROL_MODE_READY_FACTOR.VIDEO_DECODE_INIT_SUCCESS |
  // CONTROL_MODE_READY_FACTOR.SHARE_ENCODE_INIT_SUCCESS |
  CONTROL_MODE_READY_FACTOR.SHARE_DECODE_INIT_SUCCESS |
  CONTROL_MODE_READY_FACTOR.RWG_CONNECTED;

// for audio-bridge
export const CONTROL_MODE_CAP_FULL_AUDIO_BRIDGE =
  CONTROL_MODE_READY_FACTOR.VIDEO_ENCODE_INIT_SUCCESS |
  CONTROL_MODE_READY_FACTOR.VIDEO_DECODE_INIT_SUCCESS |
  // CONTROL_MODE_READY_FACTOR.SHARE_ENCODE_INIT_SUCCESS |
  CONTROL_MODE_READY_FACTOR.SHARE_DECODE_INIT_SUCCESS |
  CONTROL_MODE_READY_FACTOR.RWG_CONNECTED;

/* eslint-disable-next-line no-console */
//console.log(CONTROL_MODE_CAP_FULL);

export const CONTROL_MODE_CAP_LEVEL_VIEWONLY =
  CONTROL_MODE_READY_FACTOR.AUDIO_DECODE_INIT_SUCCESS |
  CONTROL_MODE_READY_FACTOR.VIDEO_DECODE_INIT_SUCCESS |
  CONTROL_MODE_READY_FACTOR.SHARE_DECODE_INIT_SUCCESS |
  CONTROL_MODE_READY_FACTOR.RWG_CONNECTED;

// for audio-bridge
export const CONTROL_MODE_CAP_LEVEL_VIEWONLY_AUDIO_BRIDGE =
  CONTROL_MODE_READY_FACTOR.VIDEO_DECODE_INIT_SUCCESS |
  CONTROL_MODE_READY_FACTOR.SHARE_DECODE_INIT_SUCCESS |
  CONTROL_MODE_READY_FACTOR.RWG_CONNECTED;

/* eslint-disable-next-line no-console */
//console.log(CONTROL_MODE_CAP_LEVEL_VIEWONLY);

export const CONTROL_MODE_COMMON_ERROR_TYPE = {
  UNMUTE_VIDEO: 'Failed to unmute video',
  MUTE_VIDEO: 'Failed to mute video',
  UNMUTE_AUDIO: 'Failed to unmute audio',
  MUTE_AUDIO: 'Failed to mute audio',
  CONNECTING: 'Failed to connect',
  FAILOVER: 'FAILOVER',
  LEAVE_MEETING: 'Failure to leave meeting',
  WEBGL_CONTEXT_LOST: 'WEBGL_CONTEXT_LOST',
  CONTENT_SHARE_NOT_ALLOWED: 'Content sharing not allowed.',
  CONTENT_SHARE_FAILED_TO_OPEN_MEDIA_STREAM:
    'Unable to open media stream for content sharing.',
  CONTENT_SHARE_MEDIA_STREAM_TRACK_ENDED:
    'Media stream track used for content sharing ended.',
  START_VIDEO: 'Failed to start video',
};

export const AUTO_MUTE_STATUS = {
  OFF: 0,
  ON_MUTE: 1,
  ON_UNMUTE: 2,
};
export const AUTO_CONNECT_STATUS = {
  OFF: 0,
  ON_NOT_CONNECT: 1,
  ON_CONNECT: 2,
};

export const RWG_JOIN_RESPONSE_ERROR_CODE = {
  RECOONECT_RWG_WHEN_CLOSE_ERROR: {
    type: 'rwg',
    code: -1,
    message: 'Reconnect to rwg failed',
  },
  CONF_FAIL_CONF_USER_FULL: {
    type: 'rwg',
    code: errorTypes.CONF_FAIL_CONF_USER_FULL,
    message: 'Meeting has reached the maximum number of participants allowed',
  },
  CONF_FAIL_CONFLOCKED: {
    type: 'rwg',
    code: errorTypes.CONF_FAIL_CONFLOCKED,
    message: 'Host has locked this meeting',
  },
  CONF_FAIL_MEETING_OVER: {
    type: 'rwg',
    code: errorTypes.CONF_FAIL_MEETING_OVER,
    message: 'The meeting has been ended',
  },
  CONF_RESULT_MMR_IB_REJECT: {
    type: 'rwg',
    code: errorTypes.CONF_RESULT_MMR_IB_REJECT,
    message:
      'Your admin has restricted communication between certain groups and users.',
  },
  CONF_FAIL_RESULT_MMR_CONF_PARTICIPANT_EXISTS: {
    type: 'rwg',
    code: errorTypes.CONF_FAIL_RESULT_MMR_CONF_PARTICIPANT_EXISTS,
    message: 'You have already joined this meeting on another device.',
  },
};

export const RWG_CONF_END_ERROR_CODE = {
  LEAVEREASON_USER_GESTURE: {
    type: 'rwg-leave-reason',
    code: -1,
    message: 'Leave meeting by user gesture',
  },
  LEAVEREASON_KICKEDBYHOST: {
    type: 'rwg-leave-reason',
    code: leaveReasonType.LEAVEREASON_KICKEDBYHOST,
    message: 'Expeled by host',
  },
  LEAVEREASON_ENDBYHOST: {
    type: 'rwg-leave-reason',
    code: leaveReasonType.LEAVEREASON_ENDBYHOST,
    message: 'The meeting has been ended',
  },
  LEAVEREASON_ENDBYHOSTSTARTANOTHERMEETING: {
    type: 'rwg-leave-reason',
    code: leaveReasonType.LEAVEREASON_ENDBYHOSTSTARTANOTHERMEETING,
    message: 'The meeting has been ended',
  },
  LEAVE_REASON_ARCHIVE_FAIL: {
    type: 'rwg-leave-reason',
    code: leaveReasonType.LEAVE_REASON_ARCHIVE_FAIL,
    message: 'The meeting has been archiving failed',
  },
  LEAVEREASON_FREEMEETINGTIMEOUT: {
    type: 'rwg-leave-reason',
    code: leaveReasonType.LEAVEREASON_FREEMEETINGTIMEOUT,
    message: 'Free meeting timeout',
  },
};

export const JOIN_FLOW_ERROR_CODE = {
  PING_RWC_DOMAIN_EMPTY: {
    type: 'rwc',
    code: 1001,
    message: 'Rwc ping domain list is empty',
  },
  PING_RWC_FAIL: {
    type: 'rwc',
    code: 1002,
    message: 'Ping rwc failed',
  },
  REFRESH_TOKEN_FAIL: {
    type: 'web',
    code: 2001,
    message: 'Refresh token failed',
  },
  CONTROLLER_QUIT: {
    type: 'controller',
    code: 3001,
    message: 'Controller leave meeting',
  },
  EXPEL_BY_HOST: {
    type: 'xmpp',
    code: 4001,
    message: 'Expeled by host',
  },
};

export const PREVIEW_OPTIONS = {
  AUTO_JOIN_AUDIO: 1,
  AUDIO_ON: 1 << 1,
  VIDEO_ON: 1 << 2,
};
