import meetingConfig from 'meetingConfig';
import { iText } from '../../global/util';

export const NEW_TEXT = iText('NEW', 'apac.wc_new');

export const VB_FEATURE_TIPS = iText(
  'Virtual Background is available',
  'apac.wc_vb_feature_tips',
);

export const NONE = iText('None', 'apac.wc_none');
export const CHOOSE_BACKGROUND = iText(
  'Choose Background',
  'apac.choose_background',
);

export const REACTION_SKIN_TONE = iText(
  'Reaction Skin Tone',
  'apac.settings.reaction_skin_tone',
);

export const SHOW_REACTION_TOOLBAR = iText(
  'Display your reactions above toolbar',
  'apac.settings.show_reaction_toolbar',
);

export const SHOW_USER_PROFILE_ICON = iText(
  'Show user profile icon next to in-meeting chat messages',
  'apac.settings.show_user_profile_icon',
);

export const ENABLE_FORCE_USE_VB_TIPS = iText(
  'This option is not available because your admin requires a background to start video',
  'apac.settings.enable_force_use_vb_tips',
);

export const ENABLE_CLIENT_UPLOAD_VB_TIPS = iText(
  'You cannot upload images due to restrictions placed by your admin',
  'apac.settings.enable_client_upload_vb_tips_new',
);

export const CAN_NOT_USE_DB_TIPS = iText(
  'You cannot upload images due to restrictions by your browser',
  'apac.settings.can_not_use_db_tips',
);

export const IS_ADMIN_UPLOAD_TIPS = iText(
  'Uploaded by your admin',
  'apac.settings.is_admin_upload_tips',
);

export const EDIT_SHAPE_TIPS = iText(
  'Click to edit shape',
  'apac.settings.edit_shape_tips',
);

export const FORCE_IMAGE_TIPS = iText(
  'This image was set as the default by your admin and cannot be deleted',
  'apac.settings.force_image_tips',
);

export const UPLOAD_FAIL_FORMAT_TIPS = iText(
  'Unable to upload. The format is not supported.',
  'apac.settings.upload_fail_format_tips',
);

export const UPLOAD_FAIL_COUNT_TIPS = iText(
  'You can upload up to 10 images',
  'apac.settings.upload_fail_count_tips',
);

export const UPLOAD_FAIL_SIZE_TIPS = iText(
  'Upload image less than 15 MB',
  'apac.settings.upload_fail_size_tips',
);

export const UPLOAD_FAIL_TIPS = iText(
  'Unable to upload. Please try again.',
  'apac.settings.upload_fail_tips',
);

export const USER_DISABLED_VB_TIPS = iText(
  'Virtual Background has been disabled',
  'apac.settings.user_disabled_vb_tips',
);

export const USER_DISABLED_VB_LINK1 = iText(
  'To enable, go to',
  'apac.settings.user_disabled_vb_link1',
);

export const USER_DISABLED_VB_LINK2 = iText(
  'Meeting Settings',
  'apac.settings.user_disabled_vb_link2',
);

export const ADMIN_DISABLED_VB_TIPS = iText(
  'Virtual Background has been turned off by your admin',
  'apac.settings.admin_disabled_vb_tips',
);

export const ENABLED_VB_TIPS = iText(
  'You have enabled background at the request of your admin',
  'apac.settings.enable_vb_tips',
);

export const DOWNLOAD_FAILED_IMAGE = iText(
  'Unable to download background',
  'apac.settings.download_failed_image',
);

export const BG_LOADING_ERROR1 = iText(
  'Unable to start video at this time.',
  'apac.settings.mask_loading_error1',
);

export const BG_LOADING_ERROR2 = iText(
  'Please resolve the error and <a>restart video.</a>',
  'apac.settings.mask_loading_error2',
);

export const videoText = iText('Video', 'apac.video');

export const enableGalleryViewText = iText(
  'Enable Speaker and Gallery View',
  'apac.settings.enable_gallery_view_new',
);

export const useHardwareAccelerationText = iText(
  'Use hardware acceleration for:',
  'apac.settings.use_hardware_acceleration',
);

export const receivingVideoText = iText(
  'Receiving video',
  'apac.settings.receiving_video',
);

export const sendingVideoText = iText(
  'Sending video',
  'apac.settings.sending_video',
);

export const chatText = iText('Chat', 'apac.chat');

const rangeTime = `2012-${meetingConfig.copyrightYear || 2022}`;
export const copyRightYearText = iText(
  `Copyright \u00A9 ${rangeTime} `,
  'apac.about.copy_right_year',
  rangeTime,
);

export const companyText = iText(
  'Zoom Video Communications, Inc.',
  'apac.about.company',
);

export const allRightsReservedText = iText(
  'All rights reserved.',
  'apac.about.all_rights_reserved',
);

export const openSourceText = iText(
  'Open Source Software',
  'apac.about.open_source2',
);

export const badNetworkErrorMessage = iText(
  'Poor internet connection may affect your meeting experience, please close other applications or check your network bandwidth to improve your experience.',
  'apac.settings.badNetworkErrorMessage',
);

export const generalText = iText('General', 'apac.general');

export const backgroundText = iText('Background', 'apac.background');

export const aboutText = iText('About', 'apac.about');

export const REJOIN = iText('Rejoin', 'apac.settings.rejoin');

export const statisticsText = iText('Statistics', 'apac.settings.statistics');

export const overallTabText = iText(
  'Overall',
  'apac.settings.overall_tab_text',
);

export const audioTabText = iText('Audio', 'apac.settings.audio_tab_text');

export const videoTabText = iText('Video', 'apac.settings.video_tab_text');

export const shareTabText = iText('Share', 'apac.settings.share_tab_text');

export const calculatingMemoryUsage = iText(
  'Calculating Memory Usage...',
  'apac.settings.memory_usage',
);

export const memoryUsedText = iText('Memory Used', 'apac.settings.memory_used');

export const frequencyText = iText('Frequency', 'apac.settings.frequency');

export const jitterText = iText('Jitter', 'apac.settings.jitter');

export const latencyText = iText('Latency', 'apac.settings.latency');

export const itemNameText = iText('Item Name', 'apac.settings.item_name');

export const sendText = iText('Send', 'apac.settings.send');

export const receiveText = iText('Receive', 'apac.settings.receive');

export const packetLossText = iText(
  'Packet Loss - Avg(Max)',
  'apac.settings.packet_loss',
);

export const resolutionText = iText('Resolution', 'apac.settings.resolution');

export const framePerSecondText = iText(
  'Frame Per Second',
  'apac.settings.frame_per_second',
);

export const SWITCH_VIEW_MODE_TEXT = iText(
  'You must rejoin the meeting to apply this change',
  'apac.settings.switch_view_mode_text',
);

export const receivingVideoTipsText = iText(
  'Use hardware video decoder of the graphics card to save CPU usage for receiving video.',
  'apac.settings.receiving_video_tips',
);

export const sendingVideoTipsText = iText(
  'Use hardware video encoder of the graphics card to save CPU usage for sending video.',
  'apac.settings.sending_video_tips',
);

export const VIRTUAL_BACKGROUND = iText(
  'Virtual Background',
  'apac.settings.virtual_background',
);

export const VB_INIT_3S_TIP = iText(
  'Loading Virtual Background',
  'apac.settings.vb_init_3s_tip',
);

export const VB_INIT_10S_TIP = iText(
  'Failed to enable Virtual Background. Your video has been turned off.',
  'apac.settings.vb_init_10s_tip_new',
);

export const BLUR_TEXT = iText('Blur', 'apac.settings.blur_text');

export const VB_INIT_FAIL = iText(
  'Failed to enable Virtual Background. Your video has been turned off.',
  'apac.settings.vb_init_fail_new',
);

export const TOOLBAR_LOCK = iText(
  'Always show meeting controls',
  'apac.settings.toolbar_lock',
);

export const MAXIMUM_VIEW = iText(
  'Maximum participants displayed per screen in Gallery View:',
  'apac.settings.maximum_view',
);

export const PARTICIPANTS9 = iText(
  '9 participants',
  'apac.settings.participants9',
);

export const PARTICIPANTS25 = iText(
  '25 participants',
  'apac.settings.participants25',
);

export const HOLD_SPACE_TO_UNMUTE = iText(
  'Press and hold SPACE key to temporarily unmute yourself',
  'apac.settings.hold_space',
);

export const SYNC_BUTTONS_ON_HEADSET = iText(
  'Sync buttons on headset',
  'apac.settings.sync_headset',
);

export const MUTE_MIC_WHEN_JOIN = iText(
  'Mute my microphone when join a meeting',
  'apac.settings.mute_when_hoin',
);

export const AUTO_JOIN_AUDIO = iText(
  'Automatically join audio by computer when joining a meeting',
  'apac.settings.auto_join_audio',
);

export const INPUT_LEVEL = iText('Input level', 'apac.settings.input_level');

export const MICROPHONE = iText('Microphone', 'apac.settings.microphone');

export const SPEAKER = iText('Speaker', 'apac.settings.speaker');

export const OUTPUT_LEVEL = iText('Output level', 'apac.settings.output_level');

export const STOP = iText('Stop', 'apac.settings.stop');

export const TEST_SPEAKER = iText('Test Speaker', 'apac.settings.test_speaker');

export const TEST_MIC = iText('Test Mic', 'apac.settings.test_mic');

export const RECORDING = iText('Recording', 'apac.settings.recording');

export const PLAYING = iText('Playing', 'apac.settings.playing');

export const TURN_OFF_VIDEO_WHEN_JOIN = iText(
  'Turn off my video when join a meeting',
  'apac.settings.turnoff_video_when_join',
);

export const SEE_SELF_AS_ACTIVE_WHILE_SPEAKING = iText(
  'See myself as the active speaker while speaking',
  'apac.settings.see_self_as_active_when_speaking',
);

export const SPEAKER_TIP = iText(
  'Click Test Speaker to make sure you can hear others',
  'apac.settigns.speaker_tip',
);

export const MIC_TIP = iText(
  'Click Test Mic to make sure others can hear you',
  'apac.settings.mic_tip',
);

export const MIC_SELECT_TIP = iText(
  'If you cannot hear your voice, select a different microphone',
  'apac.settings.mic_select_tip',
);

export const SPEAKER_SELECT_TIP = iText(
  'If you cannot hear the test sound, select a different speaker',
  'apac.settings.speaker_select_tip',
);

const emptyPlaceHolderForTranslaction = '';
export const MEETING_SHOW_ANIMATED_REACTION = iText(
  `Animate these emojis: ${emptyPlaceHolderForTranslaction}`,
  'apac.settings.meeting_show_animated_reaction',
  [emptyPlaceHolderForTranslaction],
);

export const SKIN_TONE = iText('Skin Tone', 'apac.settings.skin_tone');

export const VIDEO_SETTINGS = iText(
  'Video Settings...',
  'apac.wc_video.video_settings',
);

export const BLUR_MY_BACKGROUND = iText(
  'Blur My Background',
  'apac.wc_video.blur_my_background',
);
export const FOUND_A_PROBLEM = iText(
  'Found a problem?',
  'apac.settings.found_a_problem',
);
export const AUDIO_PROFILE = iText(
  'Audio Profile',
  'apac.settings.audio_profile',
);

export const DENOISE = iText(
  'Background noise suppression',
  'apac.settings.denoise',
);
export const DENOISE_TIP = iText(
  'Automatically adjusts noise suppression. Enabling this option may increase CPU usage.',
  'apac.settigns.denoise_tip',
);
export const SEND_REPORT = iText('Send report', 'apac.setting.send_report');

export const ALLOW = iText('Allow', 'apac.setting.allow');

export const DONOTALLOW = iText("Dont't Allow", 'apac.setting.donot_allow');

export const RESET_NONE_TITLE = iText(
  'Virtual Backgrounds wants to reset your virtual background to none',
  'apac.setting.reset_none_title',
);

export const RESET_NONE_TEXT = iText(
  "Clicking 'Allow' will remove your virtual background and allow meeting participants to see your surroundings.",
  'apac.setting.reset_none_text',
);

export const RESET_BLUR_TITLE = iText(
  'Virtual Backgrounds wants to reset your virtual background to blur',
  'apac.setting.reset_blur_title',
);

export const RESET_BLUR_TEXT = iText(
  "Clicking 'Allow' will remove your virtual background and blur surroundings that may be recognizable by meeting participants.",
  'apac.setting.reset_blur_text',
);

export const MEDIA_SDK_DEBUG_MODE = iText(
  'Enable debug mode',
  'apac.settings.enable_debug_mode',
);

export const ENABLE_STEREO_AUDIO_WHEN_SHARE = iText(
  'Enable stereo audio when sharing',
  'apac.settings.enable_stereo_audio_when_sharing',
);

export const SAVE_LOGS_BUTTON_TEXT = iText(
  'Save logs',
  'apac.settings.save_logs',
);

export const background_noise_suppression = iText(
  'Background noise suppression',
  'apac.settings.background_noise_suppression',
);

export const noise_suppression_note = iText(
  'recommended for most users',
  'apac.settings.denoise_note',
);

export const denoise_zoom = iText(
  'Zoom background noise removal',
  'apac.settings.denoise_zoom',
);

export const denoise_browser = iText(
  'Browser built-in noise suppression',
  'apac.settings.denoise_browser',
);

export const original_sound = iText(
  'Original sound for musicians',
  'apac.settings.original_sound',
);

export const original_sound_note = iText(
  'recommended for studio environments',
  'apac.settings.original_sound_note',
);

export const high_fidelity_music_mode = iText(
  'High fidelity music mode',
  'apac.settings.high_fidelity_music_mode',
);

export const stereo_audio = iText('Stereo audio', 'apac.settings.stereo_audio');

export const enable_denoise_tip = iText(
  'Noise suppression is enabled',
  'apac.settings.enable_noise_suppression_tip',
);

export const enable_denoise_tip2 = iText(
  'Noise suppression and echo cancellation are enabled.',
  'apac.settings.enable_noise_suppression_tip2',
);

export const enable_original_sound_tip = iText(
  'Original sound for musicians is on. Noise suppression is disabled.',
  'apac.settings.enable_original_sound_tip',
);

export const enable_original_sound_tip2 = iText(
  'Original sound for musicians is on. Noise suppression and echo cancellation are disabled.',
  'apac.settings.enable_original_sound_tip2',
);

export const enable_stereo_tip = iText(
  'Stereo audio is on. Echo cancellation is disabled.',
  'apac.settings.enable_stereo_tip',
);

export const disable_stereo_tip = iText(
  'Echo cancellation is enabled.',
  'apac.settings.enable_stereo_tip',
);

export const original_sound_help = iText(
  'Enables in-meeting option that disables background noise suppression and removes high pass filtering and automatic gain control.',
  'apac.settings.original_sound_help',
);

export const high_fidelity_help = iText(
  'Optimizes Zoom audio for highest quality music. It can increase CPU utilization and consume greater network bandwidth. For best results, an ethernet connection (not wifi) is strongly recommended.',
  'apac.settings.high_fidelity_help',
);

export const stereo_audio_help = iText(
  'This option enables Zoom to encode audio in stereo and disables echo cancellation. A stereo capable microphone or audio interface is necessary. It will increase CPU utilization and consume greater network bandwidth.',
  'apac.settings.stereo_audio_help',
);

export const video_rendering_method = iText(
  'Video Rendering Method',
  'apac.settings.video_rendering_method',
);

export const auto_renderer_type = iText(
  'Auto',
  'apac.settings.auto_renderer_type',
);

export const video_renderer_change_tip = iText(
  'Changes will take effect after you reload the page',
  'apac.settings.video_renderer_change_tip',
);
