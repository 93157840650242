import meetingConfig from 'meetingConfig';
import {
  isSupportAV,
  isTeslaMode,
  isTransferMeeting,
} from '../../../global/util';
import { SESSIONSTORAGE_KEYS } from '../../../global/constant';
import { easyStore, storeType } from '../../../global/easy-store';
import { taskRunPreview } from '../task.run-preview';
import { taskRunWebClient } from '../task.run-webclient/task.run-webclient';
import { isSupportPOSetting } from '../../../controller/utils';
import { isNewJoinFlowEnabled } from '../../../global/service';
import { getSkipPreview } from '../../../features/preview/service';
import { taskRequestJoinMeeting } from '../task.request-join-meeting';
import { logJoinFlowTelemetry } from '../../../global/logger/log-service/join-flow-telemetry';
import { directReport } from '../../../global/logger/log-service/direct-report';
import { trackingId } from '../../../global/logger/laplace';

const reportPreviewPageView = () => {
  const visited = easyStore.easyGet('haveVisitedPreview');
  if (visited) {
    directReport(
      {
        name: trackingId,
        duration: 1,
        text: `visit_preview_1`,
      },
      { tags: ['visit_preview'] },
    );
  } else {
    directReport(
      {
        name: trackingId,
        duration: 0,
        text: `visit_preview_0`,
      },
      { tags: ['visit_preview'] },
    );
    easyStore.easySet('haveVisitedPreview', true, storeType.sessionStorage);
  }
};
export default function () {
  const available = isSupportAV() && (isSupportPOSetting() || isTeslaMode());
  const enabled = isNewJoinFlowEnabled();
  const necessary =
    !meetingConfig.isHost &&
    !easyStore.easyGet(SESSIONSTORAGE_KEYS.inMeeting) &&
    !isTransferMeeting;
  const shouldPreview = available && enabled && necessary;
  if (shouldPreview) {
    if (getSkipPreview()) {
      return taskRequestJoinMeeting;
    }
    reportPreviewPageView();
    logJoinFlowTelemetry('just_start_preview');
    return taskRunPreview;
  }
  logJoinFlowTelemetry('just_start_meeting');
  return taskRunWebClient;
}
