import { buildWCErrorProps, WCSocketError, WC_ERRORS } from './WCSocketError';
import { FAILOVER_REASON } from '../../../global/enum';

export const SocketType = {
  RWG: 'RWG',
  XMPP: 'XMPP',
  ZPNS: 'ZPNS',
};

export const SocketStatus = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

/**
 * @typedef {Object} WCSockets
 */
export class WCSockets {
  static instance = {};
  static connectCounts = {};
  static existWaiter = {};
  /**
   *
   * @param name
   * @returns {WCSockets}
   */
  static get(name) {
    if (!this.instance[name]) {
      throw new WCSocketError(
        buildWCErrorProps(WC_ERRORS.NO_RWG_INSTANCE, { name }),
      );
    }
    return this.instance[name];
  }

  static checkInstanceExist(name) {
    return Boolean(this.instance[name]);
  }

  static checkStatus(name, status = SocketStatus.OPEN) {
    return this.instance[name]?.socket.readyState === status;
  }

  static waitExist(name) {
    if (this.checkStatus(name)) {
      return Promise.resolve(true);
    } else {
      return new Promise((resolve) => {
        const waiter = () => {
          resolve(true);
        };
        if (this.existWaiter[name]) {
          this.existWaiter[name].push(waiter);
        } else {
          this.existWaiter[name] = [waiter];
        }
      });
    }
  }

  static close(name, closeCode) {
    const instance = this.instance[name];
    if (instance) {
      instance.close(closeCode);
    }
    return null;
  }

  static forceClose(name) {
    const instance = this.instance[name];
    if (instance) {
      instance.forceClose();
    }
    return null;
  }
  // connect() will kill exist connection automaticly
  static connect(url, name, handlers) {
    if (this.checkStatus(name)) {
      this.forceClose(name);
    }
    this.instance[name] = new WCSockets(url, handlers, name);
    if (this.connectCounts[name]) {
      this.connectCounts[name] += 1;
    } else {
      this.connectCounts[name] = 1;
    }
  }

  static getConnectTimes(name) {
    return this.connectCounts[name] || 0;
  }

  static resetConnectTimes(name, times) {
    this.connectCounts[name] = times;
  }

  constructor(url, handlers, name) {
    this.socket = new WebSocket(url);
    this.cache = null;
    this.setHandlers(handlers, false, name);
  }

  setHandlers(handlers, isReset, name) {
    if (!handlers) {
      return;
    }
    handlers._onStart = () => {
      if (typeof handlers.onStart === 'function') {
        handlers.onStart();
      }
    };
    handlers._onClose = (e, closeCode) => {
      if (handlers.onClose) {
        handlers.onClose(e, closeCode ?? this.closeCode);
      }
      this.stopPingPong();
      this.closeCode = null;
    };
    handlers._onError = (e) => {
      if (handlers.onError) {
        handlers.onError(e);
      }
      this.stopPingPong();
    };
    handlers._onOpen = (e) => {
      this.closeCode = null;
      if (handlers.onOpen) {
        handlers.onOpen(e);
      }
      if (handlers.pingPong) {
        this.exitPingPong = handlers.pingPong();
      }
      if (name && WCSockets.existWaiter[name]) {
        WCSockets.existWaiter[name].map((v) => v());
        WCSockets.existWaiter[name] = null;
      }
    };
    handlers._onMessage = (e) => {
      if (this.cache) {
        this.cache.push(e);
      }
      if (handlers.onMessage) {
        handlers.onMessage(e);
      }
    };
    this.handlers = handlers;
    const { _onOpen, _onClose, _onError, _onMessage, _onStart } = this.handlers;
    _onStart();
    if (isReset) {
      _onOpen('reset');
    }
    if (this.cache) {
      const cache = this.cache;
      this.cache = null;
      if (cache.length > 0) {
        cache.forEach(_onMessage);
      }
    }
    this.socket.addEventListener('open', _onOpen);
    this.socket.addEventListener('close', _onClose);
    this.socket.addEventListener('error', _onError);
    this.socket.addEventListener('message', _onMessage);
  }

  resetHandlers(handlers) {
    if (this.handlers) {
      const { _onOpen, _onClose, _onError, _onMessage } = this.handlers;
      this.stopPingPong();
      this.socket.removeEventListener('open', _onOpen);
      this.socket.removeEventListener('close', _onClose);
      this.socket.removeEventListener('error', _onError);
      this.socket.removeEventListener('message', _onMessage);
      this.backupHandlers = this.handlers;
      this.handlers = null;
    }

    this.setHandlers(handlers, true);
  }

  stopPingPong() {
    if (this.exitPingPong) {
      this.exitPingPong();
      this.exitPingPong = null;
    }
  }

  waitingResetHandlers() {
    this.cache = [];
  }

  send(payload) {
    if (this.socket.readyState === SocketStatus.OPEN) {
      this.socket.send(JSON.stringify(payload));
      return true;
    } else {
      this.stopPingPong();
      return false;
    }
  }

  close(closeCode) {
    this.closeCode = closeCode;
    this.socket.close();
  }

  forceClose() {
    this.resetHandlers();
    this.socket.close();
  }
}

window.WCSockets = WCSockets;
window.closeRWG = () =>
  WCSockets.close(SocketType.RWG, FAILOVER_REASON.CONSOLE_CLOSED);
