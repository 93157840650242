let joinFlowTelemetryData = {};
let count = 0;
export const logJoinFlowTelemetry = (telemetryName) => {
  if (typeof telemetryName !== 'string') {
    throw new Error('bad parameter');
  }
  count += 1;
  Object.assign(joinFlowTelemetryData, {
    [`${telemetryName}_${count}`]: new Date().toISOString(),
  });
};

export const getJoinFlowTelemetryData = () => {
  return ['\n', '\n'].join(
    Object.entries(joinFlowTelemetryData)
      .map(([key, value]) => {
        return `${key}: ${value}`;
      })
      .join('\n'),
  );
};

export const cleanJoinFlowTelemetryData = () => {
  joinFlowTelemetryData = {};
  count = 0;
};
