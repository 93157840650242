import { makeLogger, reportPerformance } from './global/logger';
import { tableLog } from './global/web-client-logger';
import { reportResourceTiming } from './global/utils/resource-timing';
import { reportUALog } from './global/utils/report-ua-log';
import { globalVariable } from './global/global-variable';

const logger = makeLogger(['performance', 'performance_join_flow_point']);

export const PERFORMANCE_MARK = {
  start_entryJS: 'start_entryJS',

  start_PreviewJS: 'start_PreviewJS',
  inPreview_hideLoading: 'inPreview_hideLoading',
  inPreview_RWC_start: 'inPreview_RWC_start',
  inPreview_RWC_end: 'inPreview_RWC_end',
  inPreview_RWG_start: 'inPreview_RWG_start',
  inPreview_RWG_timeout: 'inPreview_RWG_timeout',
  beenPreview_initAudioEncode: 'beenPreview_initAudioEncode',
  beenPreview_initAudioDecode: 'beenPreview_initAudioDecode',
  beenPreview_initVideoDecode: 'beenPreview_initVideoDecode',
  beenPreview_initVideoEncode: 'beenPreview_initVideoEncode',
  inPreview_audioBridgeDecodeSuccess: 'inPreview_audioBridgeDecodeSuccess',
  inPreview_audioBridgeEncodeSuccess: 'inPreview_audioBridgeEncodeSuccess',
  inPreview_onJoinFetch: 'inPreview_onJoinFetch',
  inPreview_onJoin: 'inPreview_onJoinEnd',
  inPreview_inPreview: 'inPreview_inPreview', // *
  inPreview_inJBH: 'inPreview_inJBH', // *
  inPreview_onJoin_fromJBH: 'inPreview_onJoin_fromJBH',
  start_InMeetingJS_fromPreview: 'start_InMeetingJS_fromPreview',

  start_InMeetingJS: 'start_InMeetingJS',
  inMeeting_RWC_start: 'inMeeting_rwc_start',
  inMeeting_RWC_end: 'inMeeting_RWC_end',
  inMeeting_RWG_start: 'inMeeting_RWG_start',
  inMeeting_RWG_timeout: 'inMeeting_RWG_timeout',
  inMeeting_RWG_success: 'inMeeting_RWG_success',
  inMeeting_hideLoading: 'inMeeting_hideLoading',
  inMeeting_initAudioDecode: 'inMeeting_initAudioDecode',
  inMeeting_initAudioBridge: 'inMeeting_initAudioBridge',
  inMeeting_initAudioEncode: 'inMeeting_initAudioEncode',
  inMeeting_initVideoDecode: 'inMeeting_initVideoDecode',
  inMeeting_initVideoEncode: 'inMeeting_initVideoEncode',
  inMeeting_initSharingDecode: 'inMeeting_initSharingDecode',
  beenPreview_initSharingDecode: 'beenPreview_initSharingDecode',
  inMeeting_initSharingEncode: 'inMeeting_initSharingEncode',
  beenPreview_initSharingEncode: 'beenPreview_initSharingEncode',
  inMeeting_initVB: 'inMeeting_initVB',
  inMeeting_initVBSuccess: 'inMeeting_initVBSuccess',
  inMeeting_audioDecodeSuccess: 'inMeeting_audioDecodeSuccess',
  inMeeting_audioEncodeSuccess: 'inMeeting_audioEncodeSuccess',
  inMeeting_videoDecodeSuccess: 'inMeeting_videoDecodeSuccess',
  inMeeting_videoEncodeSuccess: 'inMeeting_videoEncodeSuccess',
  inMeeting_sharingDecodeSuccess: 'inMeeting_sharingDecodeSuccess',
  inMeeting_sharingEncodeSuccess: 'inMeeting_sharingEncodeSuccess',
  inMeeting_audioBridgeDecodeSuccess: 'inMeeting_audioBridgeDecodeSuccess',
  inMeeting_audioBridgeEncodeSuccess: 'inMeeting_audioBridgeEncodeSuccess',
  inMeeting_videoDecodeFirstFrame: 'inMeeting_videoDecodeFirstFrame',

  inMeeting_failed: 'inMeeting_failed',
};

export const clearMarksForAVSOnceFailOver = () => {
  if (globalVariable.performanceHandler === 'done') {
    return;
  }
  clearTimeout(globalVariable.performanceHandler);
  const cleanList = [
    PERFORMANCE_MARK.inMeeting_initAudioEncode,
    PERFORMANCE_MARK.inMeeting_audioEncodeSuccess,
    PERFORMANCE_MARK.inMeeting_initVideoDecode,
    PERFORMANCE_MARK.inMeeting_videoDecodeSuccess,
    PERFORMANCE_MARK.inMeeting_initVideoEncode,
    PERFORMANCE_MARK.inMeeting_videoEncodeSuccess,
    PERFORMANCE_MARK.inMeeting_videoDecodeFirstFrame,
    PERFORMANCE_MARK.inMeeting_initSharingDecode,
    PERFORMANCE_MARK.inMeeting_sharingDecodeSuccess,
    PERFORMANCE_MARK.inMeeting_initSharingEncode,
    PERFORMANCE_MARK.inMeeting_sharingEncodeSuccess,
    PERFORMANCE_MARK.inMeeting_initAudioBridge,
    PERFORMANCE_MARK.inMeeting_audioBridgeDecodeSuccess,
    PERFORMANCE_MARK.inMeeting_audioBridgeEncodeSuccess,
    PERFORMANCE_MARK.inMeeting_initAudioDecode,
    PERFORMANCE_MARK.beenPreview_initAudioEncode,
    PERFORMANCE_MARK.beenPreview_initAudioDecode,
    PERFORMANCE_MARK.inMeeting_audioDecodeSuccess,
    PERFORMANCE_MARK.beenPreview_initVideoDecode,
    PERFORMANCE_MARK.beenPreview_initVideoEncode,
    PERFORMANCE_MARK.beenPreview_initSharingDecode,
    PERFORMANCE_MARK.beenPreview_initSharingEncode,
    PERFORMANCE_MARK.inMeeting_initVB,
    PERFORMANCE_MARK.inMeeting_initVBSuccess,
  ];
  cleanList.forEach((v) => {
    performance.clearMarks(v);
  });
};

export const measureList = [
  // preview
  [
    PERFORMANCE_MARK.start_entryJS,
    PERFORMANCE_MARK.start_PreviewJS,
    [
      [
        PERFORMANCE_MARK.start_PreviewJS,
        PERFORMANCE_MARK.inPreview_hideLoading,
      ],
      [
        PERFORMANCE_MARK.inPreview_RWC_start,
        PERFORMANCE_MARK.inPreview_RWC_end,
      ],
      [
        PERFORMANCE_MARK.inPreview_RWC_end,
        PERFORMANCE_MARK.inPreview_RWG_start,
      ],
      [PERFORMANCE_MARK.inPreview_RWG_timeout],
      [
        PERFORMANCE_MARK.inPreview_onJoinFetch,
        PERFORMANCE_MARK.inPreview_onJoin,
      ],
      [
        PERFORMANCE_MARK.inPreview_onJoin_fromJBH,
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
      ],
      [
        PERFORMANCE_MARK.inPreview_onJoin,
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
        PERFORMANCE_MARK.inMeeting_RWG_success,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
        PERFORMANCE_MARK.inMeeting_hideLoading,
      ],
      [
        PERFORMANCE_MARK.beenPreview_initAudioEncode,
        PERFORMANCE_MARK.inMeeting_audioEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.beenPreview_initAudioDecode,
        PERFORMANCE_MARK.inMeeting_audioDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.beenPreview_initVideoDecode,
        PERFORMANCE_MARK.inMeeting_videoDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.beenPreview_initVideoEncode,
        PERFORMANCE_MARK.inMeeting_videoEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.beenPreview_initVideoDecode,
        PERFORMANCE_MARK.inMeeting_videoDecodeFirstFrame,
      ],
      [
        PERFORMANCE_MARK.beenPreview_initSharingDecode,
        PERFORMANCE_MARK.inMeeting_sharingDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.beenPreview_initSharingEncode,
        PERFORMANCE_MARK.inMeeting_sharingEncodeSuccess,
      ],
    ],
  ],
  // in-meeting
  [
    PERFORMANCE_MARK.start_entryJS,
    PERFORMANCE_MARK.start_InMeetingJS,
    [
      [
        PERFORMANCE_MARK.start_InMeetingJS,
        PERFORMANCE_MARK.inMeeting_RWC_start,
      ],
      [
        PERFORMANCE_MARK.inMeeting_RWC_start,
        PERFORMANCE_MARK.inMeeting_RWC_end,
      ],
      [
        PERFORMANCE_MARK.inMeeting_RWC_end,
        PERFORMANCE_MARK.inMeeting_RWG_start,
      ],
      [PERFORMANCE_MARK.inMeeting_RWG_timeout],
      [
        PERFORMANCE_MARK.inMeeting_RWG_start,
        PERFORMANCE_MARK.inMeeting_RWG_success,
      ],
      [
        PERFORMANCE_MARK.inMeeting_RWG_success,
        PERFORMANCE_MARK.inMeeting_hideLoading,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initAudioDecode,
        PERFORMANCE_MARK.inMeeting_audioDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initAudioEncode,
        PERFORMANCE_MARK.inMeeting_audioEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initVideoDecode,
        PERFORMANCE_MARK.inMeeting_videoDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initVideoEncode,
        PERFORMANCE_MARK.inMeeting_videoEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_videoDecodeSuccess,
        PERFORMANCE_MARK.inMeeting_videoDecodeFirstFrame,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initSharingDecode,
        PERFORMANCE_MARK.inMeeting_sharingDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initSharingEncode,
        PERFORMANCE_MARK.inMeeting_sharingEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initAudioBridge,
        PERFORMANCE_MARK.inMeeting_audioBridgeDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initAudioBridge,
        PERFORMANCE_MARK.inMeeting_audioBridgeEncodeSuccess,
      ],
    ],
  ],
  [PERFORMANCE_MARK.inMeeting_initVB, PERFORMANCE_MARK.inMeeting_initVBSuccess],
];

const endToEndDuration = {
  previewFlow: [
    [PERFORMANCE_MARK.start_entryJS, PERFORMANCE_MARK.start_PreviewJS],
    [PERFORMANCE_MARK.start_PreviewJS, PERFORMANCE_MARK.inPreview_hideLoading],
    [PERFORMANCE_MARK.inPreview_onJoinFetch, PERFORMANCE_MARK.inPreview_onJoin],
    [
      PERFORMANCE_MARK.inPreview_onJoin,
      PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
    ],
    [
      PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
      PERFORMANCE_MARK.inMeeting_RWG_success,
    ],
  ],
  startingFlow: [
    [PERFORMANCE_MARK.start_entryJS, PERFORMANCE_MARK.start_InMeetingJS],
    [PERFORMANCE_MARK.start_InMeetingJS, PERFORMANCE_MARK.inMeeting_RWC_start],
    [PERFORMANCE_MARK.inMeeting_RWC_start, PERFORMANCE_MARK.inMeeting_RWC_end],
    [PERFORMANCE_MARK.inMeeting_RWC_end, PERFORMANCE_MARK.inMeeting_RWG_start],
    [
      PERFORMANCE_MARK.inMeeting_RWG_start,
      PERFORMANCE_MARK.inMeeting_RWG_success,
    ],
    [
      PERFORMANCE_MARK.inMeeting_RWG_success,
      PERFORMANCE_MARK.inMeeting_hideLoading,
    ],
  ],
};
export const performanceMark = (name, option) => {
  performance.mark(name, option);
};
export const performanceCancel = (name) => {
  performance.clearMarks(name);
};
const stack = {};

const setStack = (name, list) => {
  if (!stack[name]) {
    stack[name] = [];
  }
  const newList = list.filter(
    (v) => !stack[name].some((x) => x.startTime === v.startTime),
  );
  stack[name] = stack[name].concat(newList);
};
const previousMeasure = {};

const analysisDetail = {
  [PERFORMANCE_MARK.inMeeting_RWG_success]: (duration) => {
    if (Number(duration) > 10000) {
      return 'RWG_connection_time_bad';
    }
    return '';
  },
};

const parseName = (a, b) => {
  return `${a} to ${b}`;
};
const measureItem = (startPoint, stopPoint) => {
  const start = stack[startPoint][0];
  const stop = stack[stopPoint][0];
  if (!start || !stop) {
    return null;
  }
  const name = parseName(start.name, stop.name);

  if (!previousMeasure[name]) {
    previousMeasure[name] = true;
  } else {
    return null;
  }
  const startTime = parseInt(start.startTime);
  const duration = parseInt(stop.startTime - start.startTime);
  return {
    name,
    startTime,
    duration,
  };
};
const performanceDuration = (startPoint, stopPoint) => {
  const startList = performance.getEntriesByName(startPoint);
  setStack(startPoint, startList);
  if (!stopPoint) {
    return null;
  }
  const stopList = performance.getEntriesByName(stopPoint);
  setStack(stopPoint, stopList);
  return measureItem(startPoint, stopPoint);
};
export const measureDuration = (_startPoint, _stopPoint) => {
  try {
    let startPoint = _startPoint;
    if (Array.isArray(startPoint)) {
      startPoint = startPoint.find(
        (v) => !!performance.getEntriesByName(v).length,
      );
    }

    let stopPoint = _stopPoint;
    if (Array.isArray(stopPoint)) {
      stopPoint = stopPoint.find(
        (v) => !!performance.getEntriesByName(v).length,
      );
    }

    const data = performance.measure(
      parseName(startPoint, stopPoint),
      startPoint,
      stopPoint,
    );
    if (CLIENT_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn(
        '[performance measure]: ',
        `${parseName(startPoint, stopPoint)} = ${data.duration}`,
      );
    }
    return data;
  } catch (e) {
    if (CLIENT_ENV === 'development') {
      logger.print(e);
    }
    return null;
  }
};

const takeMeasure = (_list) => {
  const result = [];
  const doMeasure = (list) => {
    list.forEach(([startPoint, stopPoint, continueList]) => {
      let ret = performanceDuration(startPoint, stopPoint);
      if (ret) {
        result.push(ret);
      }
      if (continueList && continueList.length > 0) {
        doMeasure(continueList);
      }
    });
  };

  doMeasure(_list);

  return result.sort((a, b) => a.startTime - b.startTime);
};

const splitLog = (promises, mainMeasure) => {
  mainMeasure.forEach((v) => {
    promises.push(logger.log(v));
  });
  Object.entries(endToEndDuration)
    .map(([key, flow]) => {
      const flowStarting = flow[0];
      const flowEnding = flow[flow.length - 1];
      const flowStartingName = parseName(flowStarting[0], flowStarting[1]);
      const flowEndingName = parseName(flowEnding[0], flowEnding[1]);
      if (
        mainMeasure.find((v) => v.name === flowStartingName) &&
        mainMeasure.find((v) => v.name === flowEndingName)
      ) {
        const e2eDuration = flow.reduce((s, v, i) => {
          const pointName = parseName(v[0], v[1]);
          const point = mainMeasure.find((v) => v.name === pointName);
          if (point) {
            if (i === 0) {
              s = point.startTime + point.duration;
            } else {
              s += point.duration;
            }
            return s;
          }
        }, 0);
        return { name: key, duration: e2eDuration };
      } else {
        return null;
      }
    })
    .filter((v) => v)
    .forEach((v) => {
      promises.push(logger.log(v));
    });
  const content = Object.values(stack)
    .flatMap((v) => v)
    .filter((v) => v)
    .sort((a, b) => a.startTime - b.startTime)
    .reduce((s, v, i, o) => {
      if (!i) {
        s.push(
          `${v.name} after ${parseInt(v.startTime)}${
            v.detail ? ' info: ' + v.detail : ''
          }`,
        );
        return s;
      }
      const duration = parseInt(v.startTime - o[i - 1].startTime);
      const detail = [analysisDetail[v.name]?.(duration), v.detail]
        .filter((v) => v)
        .join('///');
      s.push(`${v.name} after ${duration}${detail ? ' info: ' + detail : ''}`);
      return s;
    }, [])
    .map((v) => v)
    .join('\n');
  promises.push(
    logger.log('\n' + content + '\n', ['performance_join_flow_map']),
  );
};
const myTableLog = (measureList) => {
  tableLog(measureList);
};

export const performanceMeasure = (cb) => {
  try {
    // Pull out all the measurements.
    const result = takeMeasure(measureList);
    const promises = [];
    myTableLog(result);
    splitLog(promises, result);
    cb?.(result);
    reportResourceTiming();
    reportUALog();

    Promise.all(promises).then(() => {
      reportPerformance();
    });

    // Finally, clean up the entries.
    performance.clearMarks();
    performance.clearMeasures();
  } catch (e) {
    logger.print(e);
  }
};

export const sendToRwgPerformanceData = (list) => {
  let joinBeforeKeys = [];
  let joinAfterKeys = [];
  const resultMap = list.reduce((res, { name, duration }) => {
    res[name] = duration;
    return res;
  }, {});
  const hasPreview = list.some((item) => {
    return /inPreview_/i.test(item.name);
  });

  if (hasPreview) {
    joinBeforeKeys = [
      [PERFORMANCE_MARK.start_entryJS, PERFORMANCE_MARK.start_PreviewJS],
      [
        PERFORMANCE_MARK.start_PreviewJS,
        PERFORMANCE_MARK.inPreview_hideLoading,
      ],
      [
        PERFORMANCE_MARK.inPreview_RWC_start,
        PERFORMANCE_MARK.inPreview_RWC_end,
      ],
      [
        PERFORMANCE_MARK.inPreview_RWC_end,
        PERFORMANCE_MARK.inPreview_RWG_start,
      ],
      [
        PERFORMANCE_MARK.inPreview_onJoinFetch,
        PERFORMANCE_MARK.inPreview_onJoin,
      ],
      [
        PERFORMANCE_MARK.inPreview_onJoin,
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
      ],
      [
        PERFORMANCE_MARK.inPreview_onJoinFetch,
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
      ],
      [
        PERFORMANCE_MARK.inPreview_onJoin_fromJBH,
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
      ],
      [
        PERFORMANCE_MARK.inPreview_onJoinFetch,
        PERFORMANCE_MARK.inMeeting_hideLoading,
      ],
      [
        PERFORMANCE_MARK.inPreview_onJoinFetch,
        PERFORMANCE_MARK.inMeeting_audioDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inPreview_onJoinFetch,
        PERFORMANCE_MARK.inMeeting_videoDecodeFirstFrame,
      ],
    ];
    joinAfterKeys = [
      [
        PERFORMANCE_MARK.beenPreview_initAudioEncode,
        PERFORMANCE_MARK.inMeeting_audioEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.beenPreview_initAudioDecode,
        PERFORMANCE_MARK.inMeeting_audioDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.beenPreview_initVideoDecode,
        PERFORMANCE_MARK.inMeeting_videoDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.beenPreview_initVideoEncode,
        PERFORMANCE_MARK.inMeeting_videoEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
        PERFORMANCE_MARK.inMeeting_sharingDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
        PERFORMANCE_MARK.inMeeting_sharingEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
        PERFORMANCE_MARK.inMeeting_audioBridgeDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
        PERFORMANCE_MARK.inMeeting_audioBridgeEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
        PERFORMANCE_MARK.inMeeting_RWG_success,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
        PERFORMANCE_MARK.inMeeting_hideLoading,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
        PERFORMANCE_MARK.inMeeting_audioDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
        PERFORMANCE_MARK.inMeeting_videoDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
        PERFORMANCE_MARK.inMeeting_videoDecodeFirstFrame,
      ],
    ];
  } else {
    joinBeforeKeys = [
      [PERFORMANCE_MARK.start_entryJS, PERFORMANCE_MARK.start_InMeetingJS],
      [
        PERFORMANCE_MARK.start_InMeetingJS,
        PERFORMANCE_MARK.inMeeting_RWC_start,
      ],
      [
        PERFORMANCE_MARK.inMeeting_RWC_start,
        PERFORMANCE_MARK.inMeeting_RWC_end,
      ],
      [
        PERFORMANCE_MARK.inMeeting_RWC_end,
        PERFORMANCE_MARK.inMeeting_RWG_start,
      ],
      [
        PERFORMANCE_MARK.inMeeting_RWG_start,
        PERFORMANCE_MARK.inMeeting_RWG_success,
      ],
      [
        PERFORMANCE_MARK.inMeeting_RWG_success,
        PERFORMANCE_MARK.inMeeting_hideLoading,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS,
        PERFORMANCE_MARK.inMeeting_hideLoading,
      ],
    ];
    joinAfterKeys = [
      [
        PERFORMANCE_MARK.inMeeting_initAudioDecode,
        PERFORMANCE_MARK.inMeeting_audioDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initAudioEncode,
        PERFORMANCE_MARK.inMeeting_audioEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initVideoDecode,
        PERFORMANCE_MARK.inMeeting_videoDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initVideoEncode,
        PERFORMANCE_MARK.inMeeting_videoEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initSharingDecode,
        PERFORMANCE_MARK.inMeeting_sharingDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initSharingEncode,
        PERFORMANCE_MARK.inMeeting_sharingEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initAudioDecode,
        PERFORMANCE_MARK.inMeeting_audioBridgeDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initAudioEncode,
        PERFORMANCE_MARK.inMeeting_audioBridgeEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_videoDecodeSuccess,
        PERFORMANCE_MARK.inMeeting_videoDecodeFirstFrame,
      ],
    ];
  }
  joinBeforeKeys.push([
    PERFORMANCE_MARK.inMeeting_initVB,
    PERFORMANCE_MARK.inMeeting_initVBSuccess,
  ]);

  const joinMeetingBeforeName = `WCL-JOIN-MEETING-BEFORE${
    hasPreview ? '-BEENPREVIEW' : ''
  }`;
  const joinMeetingAfterName = `WCL-JOIN-MEETING-AFTER${
    hasPreview ? '-BEENPREVIEW' : ''
  }`;
  return {
    [joinMeetingBeforeName]: joinBeforeKeys.map(
      (key) => resultMap[key.join(' to ')] || -1,
    ),
    [joinMeetingAfterName]: joinAfterKeys.map(
      (key) => resultMap[key.join(' to ')] || -1,
    ),
  };
};
