export const heartbeatProvider = (() => {
  const hbList = {};

  var createHeartbeat = function () {
    var seq = 1;
    return {
      getSeq: function () {
        return seq;
      },
      incrementSeq: function () {
        /* eslint-disable-next-line no-plusplus */
        seq++;
        return this;
      },
      aliveChecked: true,
      reset: function () {
        seq = 1;
        this.aliveChecked = true;
        return this;
      },
    };
  };

  return {
    get(name) {
      if (!name) return;
      if (!hbList[name]) {
        hbList[name] = createHeartbeat();
      }
      return hbList[name];
    },
  };
})();
