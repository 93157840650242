import { iText } from './util';
import { isWebinar } from './service/meeting-types';
// fix circular src\global\easy-store\easy-store-config.js -> src\global\easy-store\gcm-method.js -> src\global\crypto\index.js -> src\global\crypto\main.js -> src\global\resource.js -> src\global\service\index.js
export const UNKNOWN_ERROR = iText('Unknown Error.', 'apac.wc_unknown_error');
export const UNKNOWN_ERROR_BTN = iText('Refresh', 'apac.wc_unknown_error_btn');
export const UNKNOWN_ERROR_ACTION = iText(
  'An unknown error has occurred, please try to refresh this page to rejoin the meeting.',
  'apac.wc_unknown_error_action',
);

export const EXIT_FULL_SCREEN = iText(
  'Exit Fullscreen',
  'apac.wc_sharing_exit_full_screen',
);
export const ENTER_FULL_SCREEN = iText(
  'Fullscreen',
  'apac.wc_sharing_full_screen',
);

export const RENAME = iText('Rename', 'apac.dialog.rename');
export const DELETE = iText('Delete', 'apac.common.delete');
export const CANCEL = iText('Cancel', 'apac.common.cancel');
export const DISABLE = iText('Disable', 'apac.common.disable');
export const START = iText('Start', 'apac.common.start');

export const RETRY = iText('Retry', 'apac.common.retry');
export const YES = iText('Yes', 'apac.common.yes');
export const NO = iText('No', 'apac.common.no');
export const REQUEST_COMMON_TEXT = iText('Request', 'apac.common.request');
export const ENABLE_COMMON_TEXT = iText('Enable', 'apac.common.enable');
export const CLOSE_COMMON_TEXT = iText('Close', 'apac.common.close');

export const LOWER_HAND_TITLE = iText('Lower Hand', 'apac.wc_lower_hand');
export const RAISE_HAND_TITLE = iText('Raise Hand', 'apac.wc_raise_hand');

export const MEETING_DISCONNECTED = iText(
  'Meeting Disconnected',
  'apac.common.disconnected',
);
export const TRY_RECONNECT = iText(
  'Trying to reconnect',
  'apac.common.try_reconnect',
);
export const CONNECTING_TEXT = iText('Connecting', 'apac.common.connecting');
export const REJOINING_TEXT = iText('Rejoining', 'apac.common.rejoining');
export const LEARN_MORE = iText('Learn more', 'apac.wc_learn_more');
export const DECRYPT_FAILED_TEXT = iText(
  'This is an encrypted message',
  'apac.common.decrypt_fail',
);
export const GUEST = iText('Guest', 'apac.wc_guest');
export const SEARCH = iText('Search', 'apac.wc_search');
export const YOU = iText('You', 'apac.common.you');
export const VIEW = iText('View', 'apac.common.view');
export const SPEAK_VIEW = iText('Speaker', 'apac.common.speaker_view');
export const GALLERY_VIEW = iText('Gallery', 'apac.common.gallery_view');
export const MULTI_SPEAKER_VIEW = iText(
  'Multi-speaker View',
  'apac.common.multi_speaker_view',
);
export const HOST_ACCOUNT = iText(
  "Host's account",
  'apac.app_signal.hosts_account',
);

export const STANDARD = iText('Standard', 'apac.common.standard');
export const SIDE_BY_SIDE_SPEAKER = iText(
  'Side-by-side: Speaker',
  'apac.common.side_by_side_speaker',
);
export const SIDE_BY_SIDE_GALLERY = iText(
  'Side-by-side: Gallery',
  'apac.common.side_by_side_gallery',
);
export const SIDE_BY_SIDE_MULTI_SPEAKER = iText(
  'Side-by-side: Multi-speaker',
  'apac.common.side_by_side_multi_speaker',
);

export const SHOW_SELF_VIEW = iText(
  'Show Self View',
  'apac.common.show_self_view',
);
export const SHOW_NO_VIDEO_PARTICIPANTS = iText(
  'Show Non-Video Participants',
  'apac.common.show_non_video_participants',
);
export const REMOVE_ALL_PINS = iText(
  'Remove All Pins',
  'apac.video.remove_all_pins',
);

export const REMOVE_ALL_SPOTLIGHTS = iText(
  'Remove All Spotlights',
  'apac.video.remove_all_spotlights',
);

export const RELEASE_VIDEO_ORDER = iText(
  'Release Video Order',
  'apac.video.release_video_order',
);

export const FOLLOW_HOST_VIDEO_ORDER = iText(
  "Follow Host's Video Order",
  'apac.video.follow_host_video_order',
);
export const DECLINE = iText('Decline', 'apac.wc_decline');
export const SUBMIT = iText('Submit', 'apac.common.submit');

export const UPDATE_BROWSER = iText(
  'Update browser to latest version of Chrome to experience Speaker and Gallery View',
  'apac.wc_update_browser',
);

export const MY_VIEW = iText('My View', 'apac.common.my_view');
export const ATTENDEE_VIEW = iText(
  'Attendee View',
  'apac.common.attendee_view',
);

export const FOLLOW_HOST_VIEW = iText(
  "Follow Host's View",
  'apac.common.follow_host_view',
);

export const SWAP_VIDEO_AND_SHARED_SCREEN = iText(
  'Swap Video and Shared Screen',
  'apac.common.swap_video_and_shared_screen',
);
export const UPDATE_BROWSER_TO_USE_MULTIVIEW = iText(
  'Update your browser to the latest version of Chrome to use Speaker/Gallery View',
  'apac.wc_update_browser_to_use_multiview',
);
export const UPDATE_BROWSER_TO_SHARE = iText(
  'Update your browser to the latest version of Chrome to use Share Screen',
  'apac.wc_update_browser_to_share',
);
export const UPDATE_BROWSER_TO_SHARE_MULTIVIEW = iText(
  'Update your browser to the latest version of Chrome to use Share Screen and Speaker/Gallery View',
  'apac.wc_update_browser_to_share_multiview',
);
const POLL_BUTTON_1 = iText(
  'Who can see your responses?',
  'apac.disclaimer.poll_button_1',
);
const POLL_CONTENT_1 = iText(
  "The account owner and host will be able to see who you are, your contact information (if you're logged in or you provided it), and your responses — and can share them with apps and others.",
  'apac.disclaimer.poll_content_noAnonymous',
);
const POLL_CONTENT_2 = iText(
  'This is an anonymous poll, so the account owner and host will not be able to see who submitted these responses.',
  'apac.disclaimer.poll_content_bAnonymous',
);
const QA_BUTTON_1 = iText(
  'Who can see your questions?',
  'apac.disclaimer.QA_button_1',
);

const QA_CONTENT_4 = iText(
  'This Q&A is public, so everyone can see your questions.',
  'apac.disclaimer.QA_content_4',
);

export const qaContent1 = () => {
  let newName = isWebinar() ? 'panelists' : 'cohost';
  return iText(
    `The account owner, host, and ${newName} will be able to see who you are, your contact information (if you’re logged in or you provided it), and your question — and can share them with apps and others.`,
    'apac.disclaimer.QA_content_1_1_1',
    [newName],
  );
};

export const qaContent2 = () => {
  let newName = isWebinar() ? 'attendees' : 'participants';
  return iText(
    `If your question is selected, it will be visible to all ${newName}.`,
    'apac.disclaimer.QA_content_1_1_2',
    [newName],
  );
};

export const qaContent3 = () => {
  let newName = isWebinar() ? 'panelists' : 'cohost';
  return iText(
    `Unless you submit anonymously, the account owner, host, and ${newName} will be able to see who you are, your contact information (if you’re logged in or you provided it), and your question — and can share them with apps and others.`,
    'apac.disclaimer.QA_content_1_1_3',
    [newName],
  );
};

const QA_CONTENT_5 = iText(
  'If you submit your questions anonymously, nobody can see who submitted your questions.',
  'apac.disclaimer.QA_content_5',
);
const CHAT_BUTTON_1 = iText(
  'Who can see your messages?',
  'apac.disclaimer.chat_button_normal',
);
const CHAT_BUTTON_REC = iText(
  'Who can see your messages? Recording On',
  'apac.disclaimer.chat_button_onRecording',
);
const CHAT_BUTTON_ARCHIVE = iText(
  'Who can see your messages? Archiving on',
  'apac.disclaimer.chat_button_onArchiving',
);
const CHAT_BUTTON_DLP = iText(
  'Who can see your messages? DLP on',
  'apac.disclaimer.chat_button_dlp_on1',
);

const CHAT_BUTTON_DLPnRec = iText(
  'Who can see your messages? Recording & DLP On',
  'apac.disclaimer.chat_button_dlp_rec_on',
);

const CHAT_BUTTON_DLPnARC = iText(
  'Who can see your messages? Archiving & DLP On',
  'apac.disclaimer.chat_button_dlp_on2',
);

const CHAT_BUTTON_DLPnARCnRec = iText(
  'Who can see your messages? Recording, Archiving & DLP On',
  'apac.disclaimer.chat_button_dlp_arc_on',
);

const CHAT_CONTENT_DLP = iText(
  'One or more meeting participants is subject to data loss prevention (DLP). Their account owner(s) can see and save your messages - including direct messages with these participants - and share them with apps and others. Any messages that conflict with policies defined by the account owner will be deleted',
  'apac.disclaimer.chat_content_dlp',
);
// case 9
const CHAT_BUTTON_AnR = iText(
  'Who can see your messages? Recording & Archiving On',
  'apac.disclaimer.chat_button_onArchivingRecording',
);
const CHAT_CONTENT_RECORDING_PREFIX = iText(
  'Recording is on.',
  'apac.disclaimer.chat_content_recording_on',
);
const CHAT_CONTENT_1 = iText(
  'Everyone in the meeting can see and save your messages with Everyone - and can share them with apps and others.',
  'apac.disclaimer.chat_content_1',
);

const CHAT_CONTENT_PMC = iText(
  'Everyone in the meeting can see and save your messages sent to Meeting Group Chat – and share them with apps and others. These messages will also be posted in the dedicated Meeting Group Chat in Team Chat, and everyone, including those not in the meeting, can see, save and share them.',
  'apac.disclaimer.chat_content_pmc2',
);

export const CHAT_CONTENT_2 = iText(
  'Only you and those you chat with can save your direct messages and share them with apps and others.',
  'apac.disclaimer.chat_content_2',
);
const CHAT_CONTENT_3 = iText(
  'Recording is on, so the account owner and everyone in the meeting can see and save messages sent to Everyone — and can share them with apps and others.',
  'apac.disclaimer.chat_content_3',
);
const CHAT_CONTENT_4 = iText(
  'Recording is on, so everyone in the meeting can see and save messages sent to Everyone — and can share them with apps and others.',
  'apac.disclaimer.chat_content_4',
);
// archiving without direct message
const CHAT_CONTENT_ARC_NO_PRIVATE_CHAT = iText(
  'One or more meeting participants is subject to archiving. Their account owner(s) can see and save your messages - not including direct messages with these participants - and share them with apps and others.',
  'apac.disclaimer.chat_content_archive_no_chat',
);
const CHAT_CONTENT_ARC_CHAT = iText(
  'One or more meeting participants is subject to archiving. Their account owner(s) can see and save your messages - including direct messages with these participants - and share them with apps and others.',
  'apac.disclaimer.chat_content_archive_chat',
);

const CHAT_CONTENT_ARC_DLP_NO_PRIVATE_CHAT = iText(
  'One or more meeting participants is subject to archiving. The host account has also enabled data loss prevention (DLP). These account owner(s) can see and save your messages - not including direct messages with these participants - and share them with apps and others. Any messages that conflict with DLP policies defined by the host account owner will be deleted.',
  'apac.disclaimer.chat_content_dlp_arc_no_private_chat',
);

const CHAT_CONTENT_ARC_DLP_PRIVATE_CHAT = iText(
  'One or more meeting participants is subject to archiving. The host account has also enabled data loss prevention (DLP). The account owner(s) can see and save your messages - including direct messages with these participants - and share them with apps and others. Any messages that conflict with DLP policies defined by the host account owner will be deleted.',
  'apac.disclaimer.chat_content_dlp_arc_private_chat',
);

const CHAT_CONTENT_NO_OWNER = iText(
  'Everyone in the meeting can see and save your messages with Everyone.',
  'apac.disclaimer.chat_content_no_owner',
);

const CHAT_CONTENT_NO_OWNER_RECORD = iText(
  'Recording is on, so everyone in the meeting can see and save messages sent to Everyone — and can share them with apps and others',
  'apac.disclaimer.chat_content_with_record',
);

const CHAT_CONTENT_OWNER_RECORD = iText(
  'Recording is on, so the account owner and everyone in the meeting can see and save messages sent to Everyone — and can share them with apps and others.',
  'apac.disclaimer.chat_content_with_cloudrecord',
);

const CHAT_CONTENT_WITH_OWNER = iText(
  'Everyone in the meeting and the account owner can see and save your messages with Everyone.',
  'apac.disclaimer.chat_content_with_owner',
);
// const CHAT_CONTENT_7 = iText(
//   'Archiving is on, so the account owner can see all your messages - not including your direct messages - and share them with apps and others.',
//   'apac.disclaimer.chat_content_7',
// );
const LT_BUTTON_1 = iText(
  'Who can see this transcript?',
  'apac.disclaimer.LT_button_1',
);
const LT_CONTENT_1 = iText(
  'The transcript from this meeting may be saved and shared with apps and others by participants with relevant permissions (base on the settings configured for this meeting).',
  'apac.disclaimer.LT_content_1_2',
);
const LT_CONTENT_2 = iText(
  'A participant has enabled Closed Captioning',
  'apac.disclaimer.LT_content_2_2',
);

const LT_CONTENT_3 = iText(
  'The host has disabled captions',
  'apac.disclaimer.LT_content_disabled',
);
export const WAITING_ROOM_CHAT_CONTENT_1 = iText(
  'Only the meeting host and co-hosts can see your messages while you are in the waiting room.',
  'apac.disclaimer.waiting_room_chat_content_1',
);

export const WAITING_ROOM_CHAT_CONTENT_2 = iText(
  'Only you and those you chat with can save your direct messages and share them with apps and others.',
  'apac.disclaimer.waiting_room_chat_content_2',
);

export const PMC_GETTY_CONTENT2 = iText(
  'Everyone in the meeting can see and save your messages sent to Meeting Group Chat – and can share them with apps and others. These messages will also be posted in the dedicated meeting group chat in Team Chat, and everyone there, including those not in the meeting, can see, save and share them.',
  'apac.disclaimer.chat_pmc_getty_content_2',
);

export const PMC_LOCAL_REC = iText(
  'Recording is on, so everyone in the meeting can see and save messages sent to Meeting Group Chat — and can share them with apps and others. These messages will also be posted in the dedicated meeting group chat in Team Chat, and everyone there, including those not in the meeting, can see, save and share them.',
  'apac.disclaimer.chat_content_pmc_local_rec',
);

export const PMC_CLOUD_REC = iText(
  'Recording is on, so the account owner and everyone in the meeting can see and save messages sent to Meeting Group Chat — and can share them with apps and others. These messages will also be posted in the dedicated meeting group chat in Team Chat, and everyone there, including those not in the meeting, can see, save and share them',
  'apac.disclaimer.chat_content_pmc_cloud_rec',
);

export const PMC_GETTY_OWNER_CONTENT2 = iText(
  'Everyone in the meeting and the account owner can see and save your messages sent to Meeting Group Chat – and can share them with apps and others. These messages will also be posted in the dedicated meeting group chat in Team Chat, and everyone there, including those not in the meeting, can see, save and share them.',
  'apac.disclaimer.chat_owner_content_2',
);

export const PMC_DLP_CONTENT = iText(
  'One or more meeting participants is subject to archiving. The host account has also enabled data loss prevention (DLP). The account owner(s) can see and save your messages - including direct messages with these participants - and share them with apps and others. Any messages that conflict with DLP policies defined by the host account owner will be deleted',
  'apac.disclaimer.chat_content_pmc_dlp',
);

export const disclaimerText = {
  chat: {
    buttonText: {
      normal: CHAT_BUTTON_1,
      onRecording: CHAT_BUTTON_REC,
      archivingNoChat: CHAT_BUTTON_1,
      archivingOn: CHAT_BUTTON_ARCHIVE,
      archivingRec: CHAT_BUTTON_AnR,
      DLP: CHAT_BUTTON_DLP,
      DLPRec: CHAT_BUTTON_DLPnRec,
      DLPnARC: CHAT_BUTTON_DLPnARC,
      DLPnARCnREC: CHAT_BUTTON_DLPnARCnRec,
    },
    content: {
      pmc: [CHAT_CONTENT_PMC, CHAT_CONTENT_2],
      normal: [CHAT_CONTENT_1, CHAT_CONTENT_2], // case1
      onRecording: [CHAT_CONTENT_3, CHAT_CONTENT_2], //case2
      localRecording: [CHAT_CONTENT_4, CHAT_CONTENT_2], //case3
      archivingNoChat: [CHAT_CONTENT_1, CHAT_CONTENT_2], // case4 same as 1
      // archivingNoChat with local record same as case2
      // archivingNoChat with cloud record same as case3
      archivingNoPrivateChat: [
        CHAT_CONTENT_ARC_NO_PRIVATE_CHAT,
        CHAT_CONTENT_NO_OWNER,
      ], // case6
      archivingNoPrivateChatLocalRecord: [
        [CHAT_CONTENT_RECORDING_PREFIX, CHAT_CONTENT_ARC_NO_PRIVATE_CHAT].join(
          ' ',
        ),
        CHAT_CONTENT_NO_OWNER,
      ], // case 9
      archivingNoPrivateChatCloudRecord: [
        [CHAT_CONTENT_RECORDING_PREFIX, CHAT_CONTENT_ARC_NO_PRIVATE_CHAT].join(
          ' ',
        ),
        CHAT_CONTENT_WITH_OWNER,
      ], // case 10
      archivingIncludePrivateChat: [
        CHAT_CONTENT_ARC_CHAT,
        CHAT_CONTENT_NO_OWNER,
      ], // case5
      archivingIncludePrivateChatLocalRec: [
        [CHAT_CONTENT_RECORDING_PREFIX, CHAT_CONTENT_ARC_CHAT].join(' '),
        CHAT_CONTENT_NO_OWNER,
      ], // case7
      archivingIncludePrivateChatCloudRec: [
        [CHAT_CONTENT_RECORDING_PREFIX, CHAT_CONTENT_ARC_CHAT].join(' '),
        CHAT_CONTENT_WITH_OWNER,
      ], // case8
      waitingRoomChat: [
        WAITING_ROOM_CHAT_CONTENT_1,
        WAITING_ROOM_CHAT_CONTENT_2,
      ],
      dlp: [CHAT_CONTENT_DLP, CHAT_CONTENT_NO_OWNER], // case 11
      dlpLocalRecord: [CHAT_CONTENT_NO_OWNER_RECORD, CHAT_CONTENT_DLP], // case12
      dlpCloudRecord: [CHAT_CONTENT_OWNER_RECORD, CHAT_CONTENT_DLP], // case13
      dlpArcNoPriChat: [
        CHAT_CONTENT_ARC_DLP_NO_PRIVATE_CHAT,
        CHAT_CONTENT_NO_OWNER,
      ], // case15
      dlpArcNoPriChatLocalRec: [
        [
          CHAT_CONTENT_RECORDING_PREFIX,
          CHAT_CONTENT_ARC_DLP_NO_PRIVATE_CHAT,
        ].join(' '),
        CHAT_CONTENT_NO_OWNER,
      ], // case18
      dlpArcNoPriChatCloudRec: [
        [
          CHAT_CONTENT_RECORDING_PREFIX,
          CHAT_CONTENT_ARC_DLP_NO_PRIVATE_CHAT,
        ].join(' '),
        CHAT_CONTENT_WITH_OWNER,
      ], // case 19
      // case 14
      dlpArcPriChat: [CHAT_CONTENT_ARC_DLP_PRIVATE_CHAT, CHAT_CONTENT_NO_OWNER],
      dlpArcPriChatLocalRec: [
        [CHAT_CONTENT_RECORDING_PREFIX, CHAT_CONTENT_ARC_DLP_PRIVATE_CHAT].join(
          ' ',
        ),
        CHAT_CONTENT_NO_OWNER,
      ], // case16
      dlpArcPriChatCloudRec: [
        [CHAT_CONTENT_RECORDING_PREFIX, CHAT_CONTENT_ARC_DLP_PRIVATE_CHAT].join(
          ' ',
        ),
        CHAT_CONTENT_WITH_OWNER,
      ], // case17
    },
  },
  LT: {
    besideContent: LT_CONTENT_2,
    buttonText: LT_BUTTON_1,
    content: LT_CONTENT_1,
    disableContent: LT_CONTENT_3,
  },
  poll: {
    buttonText: POLL_BUTTON_1,
    content: {
      noAnonymous: POLL_CONTENT_1,
      bAnonymous: POLL_CONTENT_2,
    },
  },
  QA: {
    buttonText: QA_BUTTON_1,
    content: {
      noAllNoAnonymous: [qaContent1(), qaContent2()],
      noAllBeAnonymous: [qaContent3(), qaContent2()],
      bAllNoAnonymous: [QA_CONTENT_4, qaContent1()],
      bAllBAnonymous: [QA_CONTENT_4, qaContent1(), QA_CONTENT_5],
    },
  },
};
export const SAME_AS_SYSTEM = iText('Same as System', 'apac.same_as_system');
export const SHARE_PRONOUN_MENU = iText(
  'Share My Pronouns',
  'apac.wc_share_pronoun_menu',
);
export const UNSHARE_PRONOUN_MENU = iText(
  'Unshare My Pronouns',
  'apac.wc_unshare_pronoun_menu',
);

export const AST_EXIST_TIP = iText(
  'This meeting is being controlled by an assistant',
  'apac.ast.tip',
);

export const AST_NO_EXIST_TIP = iText(
  'Assistant has stopped controlling the meeting',
  'apac.ast.leave_tip',
);

export const newText = iText('NEW', 'apac.wc_new');

export const NOT_SUPPORT_MULTI_VIEW = iText(
  'Speaker and Gallery View is not supported on this device',
  'apac.wc_not_support_multi_view',
);

export const NOT_OPEN_MULTI_VIEW_SWITCH = iText(
  'Speaker and Gallery View is disabled in Settings',
  'apac.wc_not_open_multi_view_switch',
);

export const externalText = iText(
  'external meetings',
  'apac.external_meetings',
);

export const meetingText = iText('meetings', 'apac.meetings');

export const restrictFeatureText = (feature, isSameAccount) => {
  const txt = isSameAccount ? `${meetingText}` : `${externalText}`;
  return iText(
    `Your Admin has disabled ${feature} in ${txt}`,
    'apac.wc_restrict_feature',
    [feature, txt],
  );
};

export const nameChangedText = (newName) => {
  return iText(`Your name was changed to ${newName}`, 'apac.name_change_tip', [
    newName,
  ]);
};

export const deviceChangedText = (deviceType, deviceName) => {
  return iText(
    `Your default ${deviceType} has changed to ${deviceName} and will now be used`,
    'apac.wc_device_change_tip',
    [deviceType, deviceName],
  );
};

export const renameAlertTitle = (newName) => {
  return iText(`Host renamed you to ${newName}`, 'apac.rename_alert_title', [
    newName,
  ]);
};

export const renameAlertDesc = (newName) => {
  return iText(
    `Participants will see you as ${newName}`,
    'apac.rename_alert_desc',
    [newName],
  );
};

export const renameHostTip = (oldName, newName) => {
  return iText(`You renamed ${oldName} to ${newName}`, 'apac.rename_host_tip', [
    oldName,
    newName,
  ]);
};

export const MINIMIZE_ON_HOLD_MSG = iText(
  'The host will let you in soon',
  'apac.wc_minimize_on_hold_msg',
);

export const MINIMIZE_FAIL_OVER_MSG = iText(
  'Connecting...',
  'apac.wc_minimize_fail_over_msg',
);

export const accountOwnerText = iText('account owner', 'apac.account_owner');

export const SETTINGS_PAGE_TEXT = iText(
  'settings page',
  'apac.notification.settings_page',
);

export const groupingText = (groupName) =>
  iText(`${groupName} grouping`, 'apac.grouping_text', [groupName]);

export const VIDEO_FORBIDDEN_TEXT = iText(
  'Enable camera access in your browser’s address bar and refresh the page.',
  'apac.wc_video_check_permission_new',
);

export const VIDEO_TAKEN_TEXT = iText(
  'Your camera is being used by other apps. Close those apps and try again.',
  'apac.wc_video.camera_is_taken_new',
);

export const JOIN_TEXT = iText('Join', 'apac.join_btn_join');

export const ON_HOLD_MSG = iText(
  'Please wait, the host will let you in soon',
  'apac.wc_put_on_hold_msg',
);

export const ON_HOLD_MSG2 = iText(
  'The host will let you in soon',
  'apac.wc_put_on_hold_msg2',
);

export const WAIT_FOR_HOST_TO_JOIN = iText(
  'The host left the meeting. Waiting for a host to join',
  'apac.wait_for_host_to_join',
);

export const WAIT_FOR_ADMIT2 = iText(
  'The host will admit you when they’re ready',
  'apac.wait_for_admit2',
);

export const WAIT_FOR_ADMIT3 = iText(
  "Host has joined. We've let them know you're here",
  'apac.wait_for_admit3',
);

export const WR_ABOUT_JOIN = iText(
  'You are about to join the meeting',
  'apac.wr_about_join',
);

export const WR_ABOUT_JOIN_WEBINAR = iText(
  'You are about to join the webinar',
  'apac.wr_about_join_webinar',
);

export const WAIT_FOR_HOST_MEETING = iText(
  'Waiting for host to start the meeting',
  'apac.wait_for_host',
);

export const WAIT_FOR_HOST_WEBINAR = iText(
  'Waiting for host to start the webinar',
  'apac.wait_for_host_webinar',
);

export const WAIT_FOR_WEBINAR_BEGIN = iText(
  'Please wait for the webinar to begin',
  'apac.wait_for_webinar_begin',
);

export const HOST_LOST_TEXT = iText(
  'Please wait, the host lost connection',
  'apac.waiting_room.host_lost',
);
export const VIDEO_LOAD_ERROR = iText(
  'Unable to load the video',
  'apac.video.load.error',
);
export const RELOAD_VIDEO_TEXT = iText('Retry', 'apac.video.load.retry');
export const AUDIO_FORBIDDEN_TEXT = iText(
  'Audio is unavailable for use at this time. Please refresh the page.',
  'wc_join_media_preview_warning_denied_mic_mobile',
);
export const CHECKED = iText('checked', 'apac.common.checked');
export const NOT_CHECKED = iText('not checked', 'apac.common.not_checked');
export const BACK = iText('Back', 'apac.common.back');
export const SERVICE_TERMS = iText(
  'Terms of Service',
  'apac.common.service_terms',
);
export const PRIVACY_POLICY = iText(
  'Privacy Statement',
  'apac.common.privacy_policy',
);
export const broadcastingTitleText = iText(
  'Closed Caption - Broadcasting',
  'apac.common.broadcasting_title_text',
);

export const NEXT_TEXT = iText('Next', 'apac.common.next');

export const MAXIMIZE_TEXT = iText('Maximize', 'apac.common.maximize');

export const UPGRADE_BROWSER_FOR_BETTER_EXPERIENCE_TEXT = iText(
  'Please upgrade your browser for the best experience.',
  'apac.common.browse_upgrade_for_better_experience',
);

export const GRAPHIC_ACCELERATION_OFF_TEXT = iText(
  'For a better meeting experience, enable the option ‘Use graphics/hardware acceleration’ under browser system settings. ',
  'apac.common.graphic_acceleration_off',
);
