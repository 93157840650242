import { iText } from '../../global/util';

export const SKIN_TONE = iText('Skin tone:', 'apac.reaction.skin_tone');

export const REACTION_UNKNOWN = iText('Unknown', 'apac.reaction.unknown');
export const REACTION_CLAP = iText('Clap', 'apac.reaction.clap');
export const REACTION_THUMBS_UP = iText('Thumbs Up', 'apac.reaction.thumbs_up');
export const REACTION_JOY = iText('Joy', 'apac.reaction.joy');
export const REACTION_OPEN_MOUTH = iText(
  'Open Mouth',
  'apac.reaction.open_mouth',
);
export const REACTION_HEART = iText('Heart', 'apac.reaction.heart');
export const REACTION_TADA = iText('Tada', 'apac.reaction.tada');
export const REACTION_MORE = iText('More', 'apac.reaction.more');

export const NON_VERBAL_YES = iText('Yes', 'apac.non_verbal.yes');
export const NON_VERBAL_NO = iText('No', 'apac.non_verbal.no');
export const NON_VERBAL_SPEED_UP = iText(
  'Speed up',
  'apac.non_verbal.speed_up',
);
export const NON_VERBAL_SLOW_DOWN = iText(
  'Slow down',
  'apac.non_verbal.slow_down',
);

export const REACTION_CATEGORY_LABEL_PEOPLE = iText(
  'Smileys & People',
  'apac.reaction.category_label_people',
);

export const REACTION_CATEGORY_LABEL_NATURE = iText(
  'Animals & Nature',
  'apac.reaction.category_label_nature',
);
export const REACTION_CATEGORY_LABEL_FOOD = iText(
  'Food & Drink',
  'apac.reaction.category_label_food',
);
export const REACTION_CATEGORY_LABEL_ACTIVITY = iText(
  'Activity',
  'apac.reaction.category_label_activity',
);
export const REACTION_CATEGORY_LABEL_TRAVEL = iText(
  'Travel & Places',
  'apac.reaction.category_label_travel',
);
export const REACTION_CATEGORY_LABEL_OBJECTS = iText(
  'Objects',
  'apac.reaction.category_label_objects',
);
export const REACTION_CATEGORY_LABEL_SYMBOLS = iText(
  'Symbols',
  'apac.reaction.category_label_symbols',
);
export const REACTION_CATEGORY_LABEL_FLAGS = iText(
  'Flags',
  'apac.reaction.category_label_flags',
);

export const REACTION_SEARCH_RESULTS = iText(
  'Search Results',
  'apac.reaction.search_results',
);
export const REACTION_FREQUENTLY_USED = iText(
  'Frequently used',
  'apac.reaction.frequently_used',
);

export const REACTION_ANIMATED_REACTIONS = iText(
  'Animated Reactions',
  'apac.reaction.reaction_animated',
);

export const REACTION_SEARCH = iText('Search', 'apac.reaction.search');

export const getReactionDownloadingProgress = (allEmojiDownloadProgress) =>
  iText(
    `Downloading emojis (${allEmojiDownloadProgress}%)`,
    'apac.reaction.download_progress',
    [allEmojiDownloadProgress],
  );
export const REACTION_PROCESSING_EMOJIS = iText(
  'Processing emojis',
  'apac.reaction.processing_emojis',
);
export const REACTION_ALL_EMOJI_RESOURCE_DOWNLOAD_FAILED_WARNING = iText(
  'The emoji library cannot be downloaded',
  'apac.reaction.emojis_download_failed',
);
export const NON_VERBAL_I_AM_AWAY = iText(
  "I'm away",
  'apac.non_verbal.i_am_away',
);
export const NON_VERBAL_I_AM_BACK = iText(
  "I'm back",
  'apac.non_verbal.i_am_back',
);

export const ENABLE_REACTIONS = iText(
  'Enable Reactions',
  'apac.reaction.enable_reactions',
);

export const REACTIONS_INTENSITY = iText(
  'Reactions Intensity',
  'apac.reaction.reactions_intensity',
);

export const REACTIONS_INTENSITY_ARIA_LABEL = iText(
  'Reactions Intensity grouping, Full',
  'apac.reaction.reactions_intensity_aria_label',
);
export const FULL = iText('Full', 'apac.reaction.full');

export const MEDIUM = iText('Medium', 'apac.reaction.medium');

export const HIDDEN = iText('Hidden', 'apac.reaction.hidden');

export const CHECKED = iText('checked', 'apac.reaction.checked');
export const NOT_CHECKED = iText('not checked', 'apac.reaction.not_checked');

export const REACTION_ENABLES_TITLE = iText(
  'Reactions are enabled for this webinar',
  'apac.reaction.reaction_enables_title',
);

export const REACTION_ENABLES_CONTENT = iText(
  'Users of client version 5.17.0 or later will be able to use reactions in this webinar. To enable reactions for all your future webinars, visit Settings in the web portal. ',
  'apac.reaction.reaction_enables_content',
);

export const GO_TO_WEB_SEETING = iText(
  'Go to Web Settings',
  'apac.reaction.go_to_web_setting',
);
