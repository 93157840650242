import * as userTypes from '../../constants/UserRoles';
import { isWebinar } from './meeting-types';

export function isViewOnly(role) {
  return (role & userTypes.USER_ROLE_VIEWONLY) === userTypes.USER_ROLE_VIEWONLY;
}

export function isAllowOnlyCanTalk(role) {
  return (
    (role & userTypes.USER_ROLE_VIEWONLY_CANTALK) ===
    userTypes.USER_ROLE_VIEWONLY_CANTALK
  );
}

export function isHost(role) {
  return (role & userTypes.USER_ROLE_HOST) === userTypes.USER_ROLE_HOST;
}

export function isPanelist(role) {
  return !isHost(role) && !isViewOnly(role) && isWebinar();
}

/**
 *
 * @param {object} participant type1:cmd websocket participant object
 *  {displayName: "user1",...,isHost:false,bCoHost:false,userRole: 0}
 * @param {object} participant type2:type=x websocket participant object
 * {bRaiseHand: false,clientCap: 3,,jid:"wu_xxx.us",name: "attendee",node: 16795648,role: "30"}
 */
export const checkRole = (participant) => {
  const isHost = participant.isHost;
  const isCoHost = participant.bCoHost;
  const isAttendee = (() => {
    if (isWebinar()) {
      if (typeof participant.userRole !== 'undefined') {
        return isViewOnly(participant.userRole);
      }
      if (typeof participant.role !== 'undefined') {
        return participant.role === userTypes.WEBINAR_ATTENDEES;
      }
    } else {
      return !isHost && !isCoHost;
    }
    return true;
  })();
  const isPanelist = !isAttendee && !isHost && !isCoHost && isWebinar();
  return {
    isHost,
    isCoHost,
    isAttendee,
    isPanelist,
    isWebinarAttendee: isAttendee && isWebinar(),
  };
};
