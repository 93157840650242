import { easyStore, storeType } from './global/easy-store';
import { SAME_AS_SYSTEM } from './global/resource';
import { DeviceManager } from '@zoom/common-utils';

const instance = new DeviceManager({
  labelForDefaultDevice: SAME_AS_SYSTEM,
  getCache: (key) => {
    return easyStore.easyGet(key);
  },
  setCache: (key, value) => {
    easyStore.easySet(key, value, storeType.localStorage);
  },
});
export default instance;
