import { makeLogger, reportToGlobalTracing } from '../logger/index.js';

const ResourceTimingTag = 'ResourceTiming';
const logger = makeLogger(['performance', ResourceTimingTag]);

const blackedList = [
  'audio',
  'fetch',
  // 'xmlhttprequest', // for .wasm load
  // 'script',
  // 'css',
];
const includeFileNames = [
  // prefetch target files:
  'externals.min.js',
  'vendors.webclient.min.js',
  'webclient.min.js',
  'common.webclient.min.js',
  'preview.webclient.min.js',
  'main-client.webclient.min.js',
  'styles.wc_meeting.min.css',
  'audio.simd.wasm',
  'video.mtsimd.wasm',
  'video_share_mtsimd.min.js',
  'audio_simd.min.js',
];

function getFileNameFromURL(url) {
  return url?.match(/[^\\/]+(?!.*\/)/)?.[0];
}

const getResourceTiming = () => {
  const resources = performance
    .getEntriesByType('resource')
    .filter(({ initiatorType }) => !blackedList.includes(initiatorType));
  return resources.map(
    ({
      name,
      // redirectEnd,
      // redirectStart,
      // domainLookupEnd,
      // domainLookupStart,
      // connectEnd,
      // connectStart,
      // secureConnectionStart,
      responseEnd,
      responseStart,
      // fetchStart,
      // requestStart,
      startTime,
      duration,
    }) => ({
      name,
      startTime: parseInt(startTime),
      endTime: parseInt(responseEnd - responseStart),
      // Redirect: redirectEnd - redirectStart,
      // DNS: domainLookupEnd - domainLookupStart,
      // ['TCP handshake']: connectEnd - connectStart,
      // ['Secure connection']:
      //   secureConnectionStart > 0 ? connectEnd - secureConnectionStart : '0',
      // ['Fetch until response end']:
      //   fetchStart > 0 ? responseEnd - fetchStart : '0',
      // ['Request start until response end']: responseEnd - requestStart,
      // ['Start until response end']:
      //   startTime > 0 ? responseEnd - startTime : '0',
      duration: parseInt(duration),
    }),
  );
};

function reportHitCache(allResources) {
  if (!allResources || !allResources?.length) {
    return;
  }
  const resources = allResources?.filter(({ name }) => {
    return includeFileNames.includes(getFileNameFromURL(name));
  });
  let cacheStatus = '';
  let total = resources?.length;
  let hitCacheNum = 0;
  resources.forEach((resource) => {
    const isHitCache = resource.duration < 50;
    if (isHitCache) {
      hitCacheNum += 1;
    }
    cacheStatus += `${getFileNameFromURL(resource.name)}: ${
      isHitCache ? 'YES' : 'NO'
    },`;
  });
  logger
    .log({
      cacheStatus,
      // use startTime and endTime fixed key to store number for Grafana
      startTime: total - hitCacheNum,
      endTime: hitCacheNum,
    })
    ?.then(() => {
      reportToGlobalTracing({ filter: [ResourceTimingTag] });
    });
}

const sampleRate = 0.005; // expected 4000-30000 log per day

export function reportResourceTiming() {
  if (Math.random() > sampleRate) {
    return;
  }
  const allResources = getResourceTiming();
  reportHitCache(allResources);
}
