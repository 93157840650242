import { createRoot } from 'react-dom/client';
import React from 'react';
import { easyStore, storeType } from '../../../global/easy-store';
import { generateUUID } from '../../../global/service/generate-uuid';
import { createFullResourceUrl, getBaseUrl } from './common-utils';
import LoadResourceErrorPage from './LoadResourceErrorPage';
import meetingConfig from 'meetingConfig';
import { makeLogger } from '../../../global/logger';

const logger = makeLogger(['load resource']);

const getFileName = (path) => {
  const splitIndex = path.lastIndexOf('/') + 1;
  return path.substring(splitIndex);
};

const renderLoadErrorPage = () => {
  const root = createRoot(document.getElementById('root'));
  root.render(<LoadResourceErrorPage />);
  return () => {
    root.unmount();
  };
};

const isSupportRetry =
  meetingConfig.commonWCFeatureConfig?.shouldRetryResourceLoad;

const LoadJsWithRetry = /** @class */ (function () {
  const maxRetries = 3;
  const retryDelay = 2000;
  const countMap = {};
  const addChunkParams = {};
  const chunksData = {};
  const showErrorPageResources = [
    'preview.webclient.min.js',
    'main-client.webclient.min.js',
    'js_media.min.js',
  ];

  const shouldShowErrorPage = (url) => {
    return showErrorPageResources.some((chunkName) => url.includes(chunkName));
  };
  function LoadJs(config) {
    this.value = null;
    this.browserAgent = this.getAgent();
    this.config = config;
    this.body =
      document.getElementsByTagName('body')[0] || document.documentElement;
    this.defaultBaseUrl = getBaseUrl();
  }
  LoadJs.prototype.getAgent = function () {
    return 'other';
  };
  LoadJs.start = function (config) {
    return Promise.resolve(new LoadJs(config));
  };
  LoadJs.prototype.add = function (...paths) {
    let url = '';
    if (paths.length === 1) {
      url = createFullResourceUrl(this.defaultBaseUrl, ...paths);
    } else {
      url = createFullResourceUrl(...paths);
    }
    if (!addChunkParams[url]) {
      addChunkParams[url] = paths;
    }

    if (chunksData[url] === 'done') {
      return;
    } else if (chunksData[url] instanceof Promise) {
      return chunksData[url];
    }
    return this.load(url);
  };
  LoadJs.prototype.asyncAdd = function () {};
  LoadJs.prototype.load = function (url, isSync = true) {
    // create script for adding into the body
    const fileName = getFileName(url);
    const isCSS = fileName.indexOf('.css') > -1;
    const cacheUUID = easyStore.easyGet('cacheUUID');
    const resourceUrl = cacheUUID ? `${url}?cacheUUID=${cacheUUID}` : url;
    let ele;
    if (isCSS) {
      ele = document.createElement('link');
      ele.rel = 'stylesheet';
      ele.href = resourceUrl;
    } else {
      ele = document.createElement('script');
      ele.charset = 'utf-8';
      ele.type = 'text/javascript';
      ele.id = resourceUrl;
      ele.src = resourceUrl;
      if (!isSync) {
        ele.async = true;
      }
    }
    if (ele && ele.src) {
      // fix zoomdev.us env cache not work after Chrome bug with header Cross-Origin-Embedder-Policy
      ele.crossOrigin = 'anonymous';
    }
    if (this.config?.ondemandAssetsSRI[fileName]) {
      // ele.integrity = this.config?.ondemandAssetsSRI[fileName];
      ele.crossOrigin = 'anonymous';
    }
    const that = this;
    const promise = new Promise(function (resolve, reject) {
      let done = false;
      const loadFunction = function () {
        if (
          !done &&
          (!ele.readyState ||
            ele.readyState === 'loaded' ||
            ele.readyState === 'complete')
        ) {
          done = true;
          // Handle memory leak in IE
          ele.onload = null;
          ele.onreadystatechange = null;
          chunksData[url] = 'done';
          resolve(that);
        }
      };
      const errorFunction = function (error) {
        logger.error(`Load ${url} failed`);
        const currentRetries = countMap[url] ?? 0;
        if (currentRetries < maxRetries) {
          if (ele.parentNode) {
            ele.parentNode.removeChild(ele);
          }
          const cacheUUID = generateUUID();
          easyStore.easySet('cacheUUID', cacheUUID, storeType.localStorage);
          countMap[url] = currentRetries + 1;
          chunksData[url] = undefined;
          ele.onerror = null;
          setTimeout(() => {
            that
              .add(...addChunkParams[url])
              .then(resolve)
              .catch(reject);
          }, retryDelay);
        } else {
          logger.error(`Load ${url} failed after 3 times`);
          if (shouldShowErrorPage(url)) {
            renderLoadErrorPage();
          } else {
            reject(error);
          }
        }
      };
      ele.onload = loadFunction;
      ele.onreadystatechange = loadFunction;
      ele.onerror = errorFunction;
      that.body.appendChild(ele);
    });
    chunksData[url] = promise;
    return promise;
  };
  return LoadJs;
})();

const LoadJsWithoutRetry = /** @class */ (function () {
  function LoadJs(config) {
    this.value = null;
    this.browserAgent = this.getAgent();
    this.config = config;
    this.body =
      document.getElementsByTagName('body')[0] || document.documentElement;
    this.defaultBaseUrl = getBaseUrl();
  }
  LoadJs.prototype.getAgent = function () {
    return 'other';
  };
  LoadJs.start = function (config) {
    return Promise.resolve(new LoadJs(config));
  };
  LoadJs.prototype.add = function (...paths) {
    let url = '';
    if (paths.length === 1) {
      url = createFullResourceUrl(this.defaultBaseUrl, ...paths);
    } else {
      url = createFullResourceUrl(...paths);
    }
    return this.load(url);
  };
  LoadJs.prototype.asyncAdd = function () {};
  LoadJs.prototype.load = function (url, isSync = true) {
    // create script for adding into the body
    const fileName = getFileName(url);
    const isCSS = fileName.indexOf('.css') > -1;
    let ele;
    if (isCSS) {
      ele = document.createElement('link');
      ele.rel = 'stylesheet';
      ele.href = url;
    } else {
      ele = document.createElement('script');
      ele.charset = 'utf-8';
      ele.type = 'text/javascript';
      ele.id = url;
      ele.src = url;
      if (!isSync) {
        ele.async = true;
      }
    }
    if (ele && ele.src) {
      // fix zoomdev.us env cache not work after Chrome bug with header Cross-Origin-Embedder-Policy
      ele.crossOrigin = 'anonymous';
    }
    if (this.config?.ondemandAssetsSRI[fileName]) {
      // ele.integrity = this.config?.ondemandAssetsSRI[fileName];
      ele.crossOrigin = 'anonymous';
    }
    const that = this;
    return new Promise(function (resolve, reject) {
      let done = false;
      const loadFunction = function () {
        if (
          !done &&
          (!ele.readyState ||
            ele.readyState === 'loaded' ||
            ele.readyState === 'complete')
        ) {
          done = true;
          // Handle memory leak in IE
          ele.onload = null;
          ele.onreadystatechange = null;
          resolve(that);
        }
      };
      ele.onload = loadFunction;
      ele.onreadystatechange = loadFunction;
      ele.onerror = reject;
      that.body.appendChild(ele);
    });
  };
  return LoadJs;
})();

export const LoadJs = isSupportRetry ? LoadJsWithRetry : LoadJsWithoutRetry;
