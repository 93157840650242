import { IResourceManager } from '@zoom/resource-manager';
import { FILE_SERVER_TYPE, GET_START_REG, GET_END_REG } from './enum';
import { splitFileName } from '../../global/util';
import { isSupportAudioDenoise } from '../../global/service';
import { easyStore, storeType } from '../../global/easy-store';
import { getBaseUrl } from '../../tasks/global/task-utils/common-utils';
import {
  enable_denoise_tip,
  enable_denoise_tip2,
  enable_original_sound_tip,
  enable_original_sound_tip2,
} from './resource';
import { AUDIO_PROFILE_TYPE } from './constant';
import { isEnableOriginalSound } from '../../global/op-feature-option';
import meetingConfig from 'meetingConfig';

// download image from CDN or get image from indexdb
export const loadCDNImage = ({ resourceManager, imageObject, folderName }) => {
  const { fileName } = imageObject;
  if (!fileName) {
    return {
      previewSmallStatus: 'success',
      displayURL: '',
      previewSmallObjectURL: '',
      msg: '',
    };
  }
  const imgUrl = `${getBaseUrl()}/image/${fileName}`;

  const options = {};

  if (!resourceManager.store.indexedDBStore.isEnable()) {
    options.forceSpecifyStore = 'memoryStore';
  }

  const task = resourceManager.getFile(
    `${folderName}/${fileName}`,
    {
      saveRootPath: `${folderName}/${fileName}`,
      downloadOptions: {
        xhrParams: {
          url: imgUrl,
          encoding: 1,
          method: 'GET',
          body: {
            type: 'blob',
            fileName,
          },
          timeout: 600000,
        },
      },
    },
    options,
  );

  const downloadTask = new Promise((resolve) => {
    task
      .getResult()
      .then((res) => {
        const objectURL = URL.createObjectURL(res.data);
        resolve({
          previewSmallStatus: 'success',
          displayURL: objectURL,
          previewSmallObjectURL: objectURL,
          msg: '',
        });
      })
      .catch((e) => {
        resolve({
          previewSmallStatus: 'fail',
          displayURL: '',
          previewSmallObjectURL: '',
          msg: e,
        });
      });
  });

  return downloadTask;
};

// init cloud image list
export const formatCloudImageList = (list) => {
  const preViewImageList = list.map((item) => ({
    id: item.fileId || item.name,
    displayName: splitFileName(item.name),
    fType: item.fType, //  0 default  1 custom 2: video default
    fsType: item.fsType, //  1 cdn  0 file server
    isAdminUpload: item.isAdminUpload,
    isDef: item.isDef,
    isDefForce: item.isDefForce,
    previewSmall:
      item.fsType === FILE_SERVER_TYPE.FILE_SERVER
        ? `${item.zUrl}/attach/preview/112x63`
        : item.sUrl,
    previewMedium: item.sUrl,
    previewBig: item.bUrl,
    previewOrigin: item.zUrl,
    displayURL: '',
    previewSmallStatus: 'init',
    previewSmallObjectURL: '',
    previewBigStatus: 'init',
    previewBigObjectURL: '',
    isLocal: false,
    isHover: false,
  }));

  return preViewImageList;
};

// init local image list
export const formatLocalImageList = (list) => {
  if (!list || !list.length) {
    return [];
  }

  const idMap = {};

  list
    .filter((item) => item.path)
    .forEach((item, idx) => {
      const id = item.path.match(GET_START_REG)[1];
      if (!idMap[id]) {
        idMap[id] = {
          id,
          order: idx,
        };
      }
    });

  list
    .filter((item) => item.path)
    .forEach((item) => {
      const id = item.path.match(GET_START_REG)[1];
      const type = item.path.match(GET_END_REG)[1];
      if (type === 'small') {
        idMap[id].previewSmall = item.path;
      }

      if (type === 'big') {
        idMap[id].previewBig = item.path;
      }
    });

  const preViewImageList = Object.keys(idMap)
    .map((item) => idMap[item])
    .sort((a, b) => a.idx - b.idx)
    .map((item) => {
      return {
        id: item.id,
        name: item.id,
        displayName: splitFileName(item.id),
        displayURL: '',
        previewSmall: item.previewSmall,
        previewSmallStatus: 'init',
        previewBig: item.previewBig,
        previewSmallObjectURL: '',
        previewBigObjectURL: '',
        isLocal: true,
        isHover: false,
        isLocalThird: /zoomapp/i.test(item.id),
      };
    });
  return preViewImageList;
};

// init cdn image list
export const formatCDNImageList = (list) => {
  const preViewImageList = (list || []).map((item) => {
    return {
      id: item.id,
      name: item.id,
      displayName: item.displayName,
      displayURL: '',
      previewSmall: `${getBaseUrl()}/image/${item.fileName}`,
      previewSmallStatus: 'init',
      previewBig: `${getBaseUrl()}/image/${item.fileName}`,
      previewSmallObjectURL: '',
      fsType: 1,
      isLocal: false,
      isHover: false,
      fromSelf: 1,
    };
  });

  return preViewImageList;
};

// clip image
export const clipImage = (bigImageBlob, quality = 0.5, width) => {
  const objectURL = URL.createObjectURL(bigImageBlob);

  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const im = new Image();
    im.src = objectURL;
    im.onload = () => {
      let sx = 0;
      let sy = 0;
      let sw = 0;
      let sh = 0;
      const destRate = 16 / 9;
      const srcWidth = im.width;
      const srcHeight = im.height;
      const dw = destRate * srcHeight;

      const w = width;
      const h = width / destRate;

      if (dw >= srcWidth) {
        canvas.width = w;
        canvas.height = h;
        sw = srcWidth;
        sh = srcWidth / destRate;
        sx = 0;
        sy = (srcHeight - sh) / 2;

        ctx.drawImage(im, sx, sy, sw, sh, 0, 0, w, h);
      } else {
        canvas.width = w;
        canvas.height = h;
        sw = srcHeight * destRate;
        sh = srcHeight;
        sx = (srcWidth - sw) / 2;
        sy = 0;
        ctx.drawImage(im, sx, sy, sw, sh, 0, 0, w, h);
      }

      canvas.toBlob(
        (blob) => {
          resolve({
            smallImageBlob: blob,
            bigImageBlob,
          });
        },
        'image/jpg',
        quality,
      );
    };
  });
};

// init resource manager
export const initResourceManager = (nameSpaceId) => {
  const resourceManagerList =
    easyStore.easyGet('resourceManagerList', storeType.memory) || [];

  const instance = resourceManagerList.find(
    (item) => item.key === (nameSpaceId || 'default'),
  );

  if (instance) {
    return Promise.resolve(instance.instance);
  }

  let resourceManager;
  if (nameSpaceId) {
    resourceManager = new IResourceManager({
      storeOptions: {
        nameSpaceId,
      },
    });
  } else {
    resourceManager = new IResourceManager();
  }
  return new Promise((resolve) => {
    resourceManager.on('ready', () => {
      resourceManagerList.push({
        key: nameSpaceId || 'default',
        instance: resourceManager,
      });
      easyStore.easySet(
        'resourceManagerList',
        resourceManagerList,
        storeType.memory,
      );
      resolve(resourceManager);
    });
  });
};

// download image from cloud or get image from indexdb
export const downLoadBackground = ({
  resourceManager,
  imageObject,
  zak,
  fileReadZak,
}) => {
  let zakToken = '';

  if (imageObject.previewSmallStatus !== 'loading') {
    return imageObject;
  }

  if (imageObject.fsType === FILE_SERVER_TYPE.FILE_SERVER) {
    zakToken =
      imageObject.isAdminUpload || imageObject.isDef ? fileReadZak : zak;
  }

  const headers = {};

  if (zakToken) {
    headers.zak = zakToken;
  }

  const options = {};

  if (!resourceManager.store.indexedDBStore.isEnable()) {
    options.forceSpecifyStore = 'memoryStore';
  }

  let fileName = imageObject.previewSmall;

  if (imageObject.fromSelf) {
    fileName = imageObject.displayName;
  }
  /*
  if (imageObject.isLocalThird) {
    fileName = imageObject.id;
  }
*/
  const task = resourceManager.getFile(
    fileName,
    {
      saveRootPath: fileName,
      downloadOptions: {
        xhrParams: {
          headers,
          url: imageObject.previewSmall,
          encoding: 1,
          method: 'GET',
          timeout: 600000,
        },
      },
    },
    options,
  );

  const downloadTask = new Promise((resolve) => {
    task
      .getResult()
      .then((res) => {
        const objectURL = URL.createObjectURL(res.data);

        resolve({
          ...imageObject,
          displayURL: objectURL,
          previewSmallObjectURL: objectURL,
          previewSmallStatus: 'success',
        });
      })
      .catch(() => {
        resolve({
          ...imageObject,
          previewSmallStatus: 'fail',
        });
      });
  });

  return downloadTask;
};

// init login user background cloud/local
export const initBackgroundPreviewList = ({
  preViewImageList,
  zak,
  fileReadZak,
  loginUserUid,
}) => {
  return Promise.all([initResourceManager(), initResourceManager(loginUserUid)])
    .then((res) => {
      const [resourceManagerCloud, resourceManagerLocal] = res;

      const downLoadList = preViewImageList.map((item) => {
        return downLoadBackground({
          resourceManager: item.isLocal
            ? resourceManagerLocal
            : resourceManagerCloud,
          imageObject: item,
          zak,
          fileReadZak,
        });
      });
      return Promise.all(downLoadList);
    })
    .then((res) => {
      return res;
    });
};

// preload cdn image from webclient
export const downloadAndPreloadImage = (folderName, imageList) => {
  return initResourceManager()
    .then((resourceManager) => {
      const downLoadList = imageList.map((item) =>
        loadCDNImage({
          resourceManager,
          imageObject: item,
          folderName,
        }),
      );

      return Promise.all(downLoadList);
    })
    .then((res) => {
      const list = res.map((item, idx) => {
        return {
          previewSmallStatus: item.previewSmallStatus,
          displayURL: item.displayURL,
          displayName: imageList[idx].displayName,
          previewSmallObjectURL: item.previewSmallObjectURL,
          id: item.displayURL ? `${imageList[idx].id}` : '',
        };
      });

      return Promise.resolve(list);
    });
};

// download image  get image from indexdb
export const loadLocalImage = ({ resourceManager, imageObject }) => {
  const task = resourceManager.getFile(imageObject.previewBig, {
    saveRootPath: imageObject.previewBig,
    downloadOptions: {
      xhrParams: {
        url: imageObject.previewBig,
        encoding: 1,
        method: 'GET',
        timeout: 600000,
      },
    },
  });

  const downloadTask = new Promise((resolve) => {
    task
      .getResult()
      .then((res) => {
        const objectURL = URL.createObjectURL(res.data);

        resolve({
          ...imageObject,
          displayURL: objectURL,
          previewSmallObjectURL: objectURL,
          previewSmallStatus: 'success',
        });
      })
      .catch(() => {
        resolve({
          ...imageObject,
          previewSmallStatus: 'fail',
        });
      });
  });

  return downloadTask;
};
// preload local upload image for unlogin user
export const downloadAndPreloadLocalImage = (nameSpaceId, preViewImageList) => {
  return initResourceManager(nameSpaceId)
    .then((resourceManager) => {
      const downLoadList = preViewImageList.map((item) => {
        return loadLocalImage({
          resourceManager,
          imageObject: item,
        });
      });
      return Promise.all(downLoadList);
    })
    .then((res) => {
      return res;
    });
};

export const readFileAsArrayBuffer = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      resolve(e);
    };

    reader.onerror = (e) => {
      reject(e);
    };
  });
};

export const readFileAsChunk = (file, start, end) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file.slice(start, end));
    reader.onload = (e) => {
      resolve(e);
    };

    reader.onerror = (e) => {
      reject(e);
    };
  });
};

export const getAllLocalImage = (nameSpaceId) => {
  return initResourceManager(nameSpaceId).then((resourceManager) => {
    return resourceManager.list();
  });
};

export const checkImage = (file, size = 15728640) => {
  return new Promise((resolve, reject) => {
    if (file.size > size) {
      reject(new Error('size_error'));
    }
    if (
      file.type !== 'image/jpg' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png'
    ) {
      reject(new Error('format_error'));
    }
    resolve(file);
  });
};

export const checkLocalImageCount = (loginUserUid, len = 10) => {
  return new Promise((resolve, reject) => {
    getAllLocalImage(loginUserUid).then((res) => {
      const list = res.filter((item) => !/zoomapp/i.test(item.path));
      if (list.length < len) {
        resolve(true);
      } else {
        reject(new Error('count_error'));
      }
    });
  });
};

export const isHasBackgroundRecord = (backgroundIdx) => {
  if (backgroundIdx && backgroundIdx !== -1) {
    return true;
  }
  return false;
};

function getBackgroundKey() {
  const { uid } = meetingConfig;
  return `background${uid ? uid : 'LocalDefault'}`;
}
export const hasBackground = () => {
  const record = easyStore.easyGet(getBackgroundKey());
  if (!record) {
    return false;
  }
  if (record && record.backgroundIdx === -1) {
    return false;
  }
  return true;
};

export const clearBackground = () => {
  easyStore.easyRemove(getBackgroundKey());
};

export const isSupportGuestCustomUpload = () => {
  if (!Intl) {
    return false;
  }

  const timeZoneStr = new Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone?.toLowerCase();

  const UNSUPPORT_REGION = [
    'asia/beijing',
    'asia/shanghai',
    'asia/chongqing',
    'asia/urumqi',
  ];

  if (UNSUPPORT_REGION.indexOf(timeZoneStr) > -1) {
    return false;
  }

  return true;
};

// for zoomSdk encodeBase64
export const decodeBase64 = (base64) => {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  // Use a lookup table to find the index.
  const lookup = typeof Uint8Array === 'undefined' ? [] : new Uint8Array(256);
  for (let i = 0; i < chars.length; i++) {
    lookup[chars.charCodeAt(i)] = i;
  }

  let bufferLength = base64.length * 0.75,
    len = base64.length,
    i,
    p = 0,
    encoded1,
    encoded2,
    encoded3,
    encoded4;

  if (base64[base64.length - 1] === '=') {
    // eslint-disable-next-line no-plusplus
    bufferLength--;
    if (base64[base64.length - 2] === '=') {
      // eslint-disable-next-line no-plusplus
      bufferLength--;
    }
  }

  const bytes = new Uint8ClampedArray(bufferLength);

  for (i = 0; i < len; i += 4) {
    encoded1 = lookup[base64.charCodeAt(i)];
    encoded2 = lookup[base64.charCodeAt(i + 1)];
    encoded3 = lookup[base64.charCodeAt(i + 2)];
    encoded4 = lookup[base64.charCodeAt(i + 3)];

    // eslint-disable-next-line no-plusplus
    bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
    // eslint-disable-next-line no-plusplus
    bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
    // eslint-disable-next-line no-plusplus
    bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
  }

  return bytes;
};

export const typeArrToImageDataToBlob = function (typeArr, width) {
  let w = width;
  let h = typeArr.length / w / 4;
  let canvas = document.createElement('canvas');
  canvas.width = w;
  canvas.height = h;
  let ctx = canvas.getContext('2d');

  let imageData = new ImageData(typeArr, w, h);
  ctx.putImageData(imageData, 0, 0);

  return new Promise((resolve) => {
    canvas.toBlob(resolve);
  });
};

export const getEnableTip = (isStereoEnabled, audioProfileType) => {
  if (isStereoEnabled) {
    return {
      [AUDIO_PROFILE_TYPE.DENOISE]: enable_denoise_tip2,
      [AUDIO_PROFILE_TYPE.ORIGINAL_SOUND]: enable_original_sound_tip2,
    }[audioProfileType];
  } else {
    return {
      [AUDIO_PROFILE_TYPE.DENOISE]: enable_denoise_tip,
      [AUDIO_PROFILE_TYPE.ORIGINAL_SOUND]: enable_original_sound_tip,
    }[audioProfileType];
  }
};

export const isShowNoiseSuppression = () => {
  return isSupportAudioDenoise();
};

export const isShowOriginalSound = () => {
  return isEnableOriginalSound();
};

export const isShowAudioProfile = () => {
  return isShowNoiseSuppression() || isShowOriginalSound();
};

export const isSupportStereoAudio = () => {
  return JsMediaSDK_Instance.util.isBrowserSupportStereo?.() ?? false;
};
