import meetingConfig from 'meetingConfig';
import { encodeBase64 } from '../util';
import { storeType } from './easy-store';

export function initStorageKey() {
  /* eslint-disable-next-line @babel/no-invalid-this */
  Object.assign(this.memory, {
    sessionStorageKey: encodeBase64(`easy-${meetingConfig.mid}`),
    localStorageKey: encodeBase64('easy-local-storage'),
  });
}

export const setSpecialSessionDataToLocal = (object) => {
  if (typeof object !== 'object') {
    return null;
  }
  easyStore.easySet(
    `specialSessionInLocal-${meetingConfig.mid}`,
    (v) => {
      const previous = v ?? {};
      return { ...previous, ...object };
    },
    storeType.localStorage,
  );
};

export const getSpecialSessionDateFromLocal = (key) => {
  const data = easyStore.easyGet(`specialSessionInLocal-${meetingConfig.mid}`);
  return data && data[key];
};

export const removeSpecialSessionDataFromLocal = () => {
  const specialKey = Object.keys(easyStore.localStorageData).find((v) =>
    v.includes('specialSessionInLocal'),
  );
  if (!specialKey) {
    return;
  }
  const [, preMid] = specialKey.split('-');
  if (preMid !== meetingConfig.mid) {
    easyStore.easyRemove(specialKey);
  }
};
