/**
 * created by johnson on 2016/5/27.
 */

/* Not use it to check whether user role is attendee
 * default role is attendee always,
 * Webinar user role is among host, viewonly and panelist
 */
//
// indicates the binding voip user left the conf with pstn working
// export const USER_ROLE_BINDING_DELEGATOR = 2;
// default role is attendee always, it is for ZM user is either host or attendee
export const USER_ROLE_ATTENDEE = 0;
export const USER_ROLE_HOST = 1; // indicates the user in conf session is host
export const USER_ROLE_OWNER = 4; // indicates the owner of the meeting
export const USER_ROLE_VIEWONLY = 8; // indicates the viewonly user. it is for webinar
export const UESR_ROLE_OWNERHOST = 5; // role=5 owner&&host
export const USER_ROLE_VIEWONLY_CANTALK = 24;

// user type
export const USER_TYPE_CONF = 9;
export const USER_TYPE_H323 = 15;

// webinar user type
export const WEBINAR_HOST = '10';
export const WEBINAR_PANELISTS = '20';
export const WEBINAR_ATTENDEES = '30';

// webinar attendee type
export const WEBINAR_ATTENDEES_WEB = 'WEBINAR_ATTENDEES_WEB';
export const WEBINAR_ATTENDEES_PHONE = 'WEBINAR_ATTENDEES_PHONE';

// RSGW
export const USER_TYPE_RSG = 54;
