import _ from 'lodash';

const meetingField = _.assign({}, window.MeetingField);

export const setField = (key, value) => {
  if (!key) {
    return;
  }
  meetingField[key] = value;
};

export const updateMeetingField = (config) => {
  _.extend(meetingField, config);
};

export default meetingField;
