import { get, set, unset } from 'lodash';
import { decodeBase64, encodeBase64 } from '../util';

const NOTHING = encodeBase64('{}');

export const storeType = {
  localStorage: 'localStorage',
  sessionStorage: 'sessionStorage',
  memory: 'memory',
};

export class EasyStore {
  constructor(config) {
    this.memory = { initialed: false };
    this.bindMethod(config);
    this.initStoreData(); // called by user
  }

  bindMethod = (config) => {
    const {
      firstBeforeReadStorage = {},
      firstAfterReadStorage = {},
      failOverBeforeReadStorage = {},
      failOverAfterReadStorage = {},
      customer = {},
    } = config;
    this.memory.lifecycle = {
      firstBeforeReadStorage: Object.keys(firstBeforeReadStorage),
      firstAfterReadStorage: Object.keys(firstAfterReadStorage),
      failOverBeforeReadStorage: Object.keys(failOverBeforeReadStorage),
      failOverAfterReadStorage: Object.keys(failOverAfterReadStorage),
    };
    const allMethod = {
      ...firstBeforeReadStorage,
      ...firstAfterReadStorage,
      ...failOverBeforeReadStorage,
      ...failOverAfterReadStorage,
      ...customer,
    };
    Object.keys(allMethod).forEach((key) => {
      this[key] = allMethod[key];
    });
  };

  initStoreData = (props = {}) => {
    if (this.memory.initialed) {
      return;
    }
    this.memory.initialed = true;
    const { lifecycle } = this.memory;
    this.lifecycleTask(lifecycle.firstBeforeReadStorage, props);
    this.readSessionStorage();
    this.readLocalStorage();
    this.lifecycleTask(lifecycle.firstAfterReadStorage, props);
    this.saveSessionStorage();
    this.saveLocalStorage();
  };

  failOver = (props = {}) => {
    const { lifecycle } = this.memory;
    this.lifecycleTask(lifecycle.failOverBeforeReadStorage, props);
    this.readSessionStorage();
    this.readLocalStorage();
    this.lifecycleTask(lifecycle.failOverAfterReadStorage, props);
    this.saveSessionStorage();
    this.saveLocalStorage();
  };

  lifecycleTask = (taskNames, props) => {
    taskNames.forEach((taskName) => {
      this[taskName](props);
    });
  };

  readSessionStorage = () => {
    if (!this.memory.sessionStorageKey) {
      return;
    }
    this.sessionStorageData = JSON.parse(
      decodeBase64(
        sessionStorage.getItem(this.memory.sessionStorageKey) || NOTHING,
      ),
    );
  };

  saveSessionStorage = () => {
    if (!this.memory.sessionStorageKey) {
      return;
    }
    sessionStorage.setItem(
      this.memory.sessionStorageKey,
      encodeBase64(JSON.stringify(this.sessionStorageData)),
    );
  };

  removeSessionStorage = () => {
    if (!this.memory.sessionStorageKey) {
      return;
    }
    this.sessionStorageData = {};
    sessionStorage.removeItem(this.memory.sessionStorageKey);
  };

  readLocalStorage = () => {
    if (!this.memory.localStorageKey) {
      return;
    }
    this.localStorageData = JSON.parse(
      decodeBase64(
        localStorage.getItem(this.memory.localStorageKey) || NOTHING,
      ),
    );
  };

  saveLocalStorage = () => {
    if (!this.memory.localStorageKey) {
      return;
    }
    localStorage.setItem(
      this.memory.localStorageKey,
      encodeBase64(JSON.stringify(this.localStorageData)),
    );
  };

  removeLocalStorage = () => {
    if (!this.memory.localStorageKey) {
      return;
    }
    localStorage.removeItem(this.memory.localStorageKey);
  };

  findValue = (key) => {
    let i = 0;
    let value = null;
    let tmpValue = null;
    let type = null;
    tmpValue = get(this.sessionStorageData, key);
    if (tmpValue !== undefined) {
      i += 1;
      value = tmpValue;
      type = storeType.sessionStorage;
    }
    tmpValue = get(this.localStorageData, key);
    if (tmpValue !== undefined) {
      i += 1;
      value = tmpValue;
      type = storeType.localStorage;
    }
    tmpValue = get(this.memory, key);
    if (tmpValue !== undefined) {
      i += 1;
      value = tmpValue;
      type = storeType.memory;
    }

    if (i > 1) {
      throw new Error('there are same name value in different type of storage');
    }

    return { value, type };
  };

  easyGet = (key) => {
    return this.findValue(key).value;
  };

  easyRemove = (key) => {
    const { type } = this.findValue(key);
    switch (type) {
      case storeType.sessionStorage: {
        unset(this.sessionStorageData, key);
        this.saveSessionStorage();
        break;
      }
      case storeType.memory: {
        unset(this.memory, key);
        break;
      }
      case storeType.localStorage: {
        unset(this.localStorageData, key);
        this.saveLocalStorage();
        break;
      }
      default:
        break;
    }
  };

  easySet = (key, value, type) => {
    const { value: oldValue, type: thisType } = this.findValue(key);
    if (thisType && type !== thisType) {
      throw new Error(
        `set ${type}->${key} error: there is a value with the same key in type ${thisType}`,
      );
    }

    let newValue = value;
    if (typeof value === 'function') {
      newValue = value(oldValue);
    }

    if (newValue === oldValue) {
      return newValue;
    }

    switch (type) {
      case storeType.sessionStorage: {
        set(this.sessionStorageData, key, newValue);
        this.saveSessionStorage();
        return newValue;
      }
      case storeType.memory: {
        set(this.memory, key, newValue);
        return newValue;
      }
      case storeType.localStorage: {
        set(this.localStorageData, key, newValue);
        this.saveLocalStorage();
        return newValue;
      }
      default:
        return null;
    }
  };
}
