import { initLaplace } from '../../global/logger/laplace';
import { isLaplaceEnabled } from '../../global/logger/is-laplace-enabled';

function initLogManager() {
  if (!isLaplaceEnabled()) {
    return Promise.resolve();
  }

  return initLaplace();
}

export const taskInitLogManager = [initLogManager];
