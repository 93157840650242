import meetingConfig from 'meetingConfig';
import { easyStore, storeType } from '../easy-store';

const { simulivePlayInfo } = meetingConfig;
const defaultSimulivePlayInfo = {
  videoUrl: simulivePlayInfo && simulivePlayInfo.videoUrl,
  status: simulivePlayInfo && simulivePlayInfo.status,
  offset: simulivePlayInfo && simulivePlayInfo.offset,
  autoreplyEnabled: simulivePlayInfo && simulivePlayInfo.autoreplyEnabled,
  autoreplyMsg: simulivePlayInfo && simulivePlayInfo.qaAutoReplyText,
};
export function setSimulivePlayInfo(simulivePlayInfo = {}) {
  const val = {
    videoUrl: simulivePlayInfo.videoUrl,
    status: simulivePlayInfo.status,
    offset: simulivePlayInfo.offset,
    autoreplyEnabled: simulivePlayInfo.autoreplyEnabled,
    autoreplyMsg: simulivePlayInfo.qaAutoReplyText,
  };
  easyStore.easySet('simulivePlayInfo', val, storeType.memory);
}

export function getSimulivePlayInfo() {
  const valInStore = easyStore.easyGet('simulivePlayInfo');
  if (valInStore !== null) {
    return valInStore;
  }
  return defaultSimulivePlayInfo;
}
