import { directReport } from '../logger/log-service/direct-report';
import { getUA } from './ua';

export function reportUALog() {
  const ua = getUA();
  if (!ua?.browser?.name) return;

  const browserVersion = `${ua.browser.name}-${ua.browser.major}`;
  const osVersion = `${ua.os.name}-${ua.os.version}`;
  directReport(
    { strMsg: JSON.stringify(ua), name: browserVersion },
    {
      sampleRate: 0.001,
      logLevel: osVersion,
      tags: ['UA'],
    },
  );
}
