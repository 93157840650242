export function getBrowserVersion() {
  if (navigator.browserVersion) return navigator.browserVersion;
  navigator.browserVersion = (function () {
    const ua = navigator.userAgent;
    let tem;
    let M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
      ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return `IE ${tem[1] || ''}`;
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|edg?)\/(\d+)/);
      if (tem && tem.length) {
        return tem.slice(1).join('').replace('OPR', 'Opera');
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i))) M.splice(1, 1, tem[1]);
    return M.join('');
  })();
  return navigator.browserVersion;
}

/**
 * Determine whether it is not the expected browser and version
 * @param {String} name Required browser name
 * @param {Number} num Required browser version
 * @returns {boolean}
 */
export function checkBrowserVersionIs(name, num) {
  if (!navigator.browserVersion) {
    getBrowserVersion();
  }
  const bv = navigator.browserVersion || '';
  const browserName = bv.replace(/[^a-zA-Z]+/g, '');
  const browserVersion = bv.replace(/[^0-9]+/g, '');
  const result = browserName === name && Number(browserVersion) < num;
  return result;
}

export const getOSName = () => {
  const platform = navigator.platform;
  let os = 'Unknown';

  if (/Win/i.test(platform)) {
    os = 'windows';
  } else if (/Mac/i.test(platform)) {
    os = 'mac';
  } else if (/Linux/i.test(platform)) {
    os = 'linux';
  } else if (/iPhone/i.test(platform)) {
    os = 'iphone';
  } else if (/iPad/i.test(platform)) {
    os = 'ipad';
  } else if (/Android/i.test(platform)) {
    os = 'android';
  } else if (/CrOS/i.test(platform)) {
    os = 'chrome_os';
  }

  return os;
};

export const getOSVersion = () => {
  const userAgent = navigator.userAgent;
  let osVersion = 'Unknown';

  // Regular expressions to detect the OS version
  const windowsRegex = /Windows NT (\d+\.\d+)/;
  const macOSRegex = /Mac OS X (\d+([_.]\d+)?)/;
  const linuxRegex = /Linux/;
  const iOSRegex = /iPhone OS (\d+([_.]\d+)?)/;
  const androidRegex = /Android (\d+([_.]\d+)?)/;
  const chromeOSRegex = /CrOS (\d+([_.]\d+)?)/;

  if (windowsRegex.test(userAgent)) {
    osVersion = userAgent.match(windowsRegex)[1];
  } else if (macOSRegex.test(userAgent)) {
    osVersion = userAgent.match(macOSRegex)[1].replace(/_/g, '.');
  } else if (iOSRegex.test(userAgent)) {
    osVersion = userAgent.match(iOSRegex)[1].replace(/_/g, '.');
  } else if (androidRegex.test(userAgent)) {
    osVersion = userAgent.match(androidRegex)[1];
  } else if (chromeOSRegex.test(userAgent)) {
    osVersion = userAgent.match(chromeOSRegex)[1];
  } else if (linuxRegex.test(userAgent)) {
    osVersion = 'Linux';
  }

  return osVersion;
};
