import meetingConfig from 'meetingConfig';
import qs from 'qs';
import axios from 'axios';
import { storeType, easyStore } from '../../global/easy-store';
import _ from 'lodash';
import { MEMORYSTORAGE_KEYS } from '../constant';
import { isEnableUseZMKFromConfJ } from '../op-feature-option';

const defaultMeetingTokens = {
  zak: meetingConfig.zak,
  fileReadZak: meetingConfig.fileReadZak,
  zmk: meetingConfig.zmk,
  auth: meetingConfig.auth,
  trackAuth: meetingConfig.trackAuth,
  tid: meetingConfig.tid,
  ts: meetingConfig.ts,
  encryptedRWC: meetingConfig.encryptedRWC,
  encryptedGEORWC: meetingConfig.encryptedGEORWC,
};

export function getMeetingTokens() {
  const valInStore = easyStore.easyGet(
    MEMORYSTORAGE_KEYS.webclient_meeting_tokens,
  );
  if (valInStore == null) {
    return defaultMeetingTokens;
  } else {
    return valInStore;
  }
}

export function setMeetingTokens(newTokens) {
  const { encryptedRWC, encryptedGEORWC } = newTokens;
  const val = {
    zak: newTokens.zak,
    fileReadZak: newTokens.fileReadZak,
    auth: newTokens.auth,
    trackAuth: newTokens.trackAuth,
    tid: newTokens.tid,
    ts: newTokens.ts,
    encryptedRWC:
      typeof encryptedRWC === 'string'
        ? JSON.parse(encryptedRWC)
        : encryptedRWC,
    encryptedGEORWC:
      typeof encryptedGEORWC === 'string'
        ? JSON.parse(encryptedGEORWC)
        : encryptedGEORWC,
  };
  if (!isEnableUseZMKFromConfJ()) {
    val.zmk = newTokens.zmk;
  }
  easyStore.easySet(
    MEMORYSTORAGE_KEYS.webclient_meeting_tokens,
    val,
    storeType.memory,
  );
}

function refreshTokens() {
  const { baseUrl } = meetingConfig;
  const url = `${baseUrl}/wc/refresh_tokens`;
  const tokens = getMeetingTokens();
  const { auth, ts, zak, zmk, fileReadZak, tid } = tokens;
  const { meetingNumber, rwcDefault, rwcBackup } = meetingConfig;
  const body = {
    ts,
    tid,
    auth,
    meetingNumber,
    rwcDefault,
    rwcBackup,
  };
  if (zak !== '') {
    body.zak = zak;
  }
  if (!isEnableUseZMKFromConfJ() && zmk !== '') {
    body.zmk = zmk;
  }
  if (fileReadZak !== '') {
    body.fileReadZak = fileReadZak;
  }

  return axios
    .post(url, qs.stringify(body))
    .then(({ data }) => {
      if (data.status && !_.isEmpty(data.result)) {
        return JSON.parse(data.result);
      } else {
        throw new Error('refresh token result is empty');
      }
    })
    .then((v) => {
      setMeetingTokens(v);
      return v;
    });
}

function easySleep(time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

function retryRequest(func, retryCount) {
  return func().catch(() => {
    if (retryCount <= 0) {
      throw new Error('retry request failed');
    }
    retryCount -= 1;
    return easySleep(Math.pow(2, 4 - retryCount) * 1000).then(() => {
      return retryRequest(func, retryCount);
    });
  });
}

export const fetchRWCToken = () => {
  return retryRequest(refreshTokens, 5);
};
