import { rmcAdminName } from './resource';

export const containerSize = {
  title: 37,
  meetingWaitingTitle: 42,
  webinarWaitingTitle: 42,
  participantItem: 34,
  panelistTab: 24,
  participantTitle: 42,
  searchBoxHeight: 30,
  bottomBarHeight: 50,
  nonverbalCoOrHostHeight: 80,
  nonverbalNormalHeight: 60,
};

export const RMC_ADMIN = {
  displayName: rmcAdminName,
  isAdmin: true,
  adminType: 'RMC',
  userId: 'rmc',
  isGuest: false,
};

export const PARTICIPANT_ICON_NAME = {
  VIDEO_ON: 'icons/participants-list/video-on',
  VIDEO_OFF: 'icons/participants-list/video-off',
  AUDIO_MUTED: 'icons/participants-list/audio-muted',
  AUDIO_UNMUTED: 'icons/participants-list/audio-unmuted-1',
  AUDIO_SPEAKING: 'icons/participants-list/audio-unmuted-animated',
  PHONE_MUTED: 'icons/participants-list/telephone-muted',
  PHONE_UNMUTED: 'icons/participants-list/telephone-unmuted-1',
  PHONE_SPEAKING: 'icons/participants-list/telephone-unmuted-animated',
  SHARING_SCREEN: 'icons/participants-list/sharing-screen',
};
